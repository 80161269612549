import React, { useState } from "react";
import Typography from "../../../../component/ui/typography/Typography"; // Assuming Typography is imported from a custom component
import Modal from "../../../../component/ui/modal/Modal"; // Assuming Typography is imported from a custom component
import Button from "../../../../component/ui/button/Button"; // Assuming Typography is imported from a custom component
import { useNavigate } from "react-router-dom";
import { estimateCancelApi } from "../../../../api/auth";

const AliveReBidModal = ({
  aliveReBidOpen,
  openAliveReBidModal,
  closeAliveReBidModal,
  detailData,
  pList,
  estimateDetailFnc2,
  bidNo,
}) => {
  const navigate = useNavigate();
  const [selectedRadio, setSelectedRadio] = useState(-1);

  const handleRadioChange = (index) => {
    setSelectedRadio(index);
  };

  const [reasonComment, setReasonComment] = useState("");
  const handleCommentChange = (e) => {
    const value = e.target.value;

    if (value.length <= 300) {
      setReasonComment(value);
    } else {
      const truncatedText = value.substring(0, 300);
      setReasonComment(truncatedText);
    }
  };

  // navigate 함수
  const navigateToBid = (path, state) => {
    navigate(path, { state });
  };

  // 이미지 포맷 변경
  const getBidImgList = (bidImgInfoList) => {
    return bidImgInfoList?.map((item) => ({
      fileIndex: item.fileNo,
      fileNo: item.fileNo,
      fileName: item.fileName,
      filePath: item.fileRoot,
    }));
  };

  const [alertOpen, setAlertOpen] = useState(false);
  const openAlertModal = () => setAlertOpen(true);
  const closeAlertModal = () => setAlertOpen(false);

  const validateAliveReBid = () => {
    if (selectedRadio === -1 || reasonComment === "") {
      return openAlertModal();
    }

    const commonState = {
      comment: detailData.bid?.comment, // 투찰시 사용자가 입력한 코멘트
      reBidComment: reasonComment, // 재견적할 때 쓴 코멘트
      bidProductList: pList,
      bidImgList: getBidImgList(detailData.bidImgInfoList),
      choiceOrderMethod:
        detailData.bid.type === "S" ? "일회성입찰공고" : "단가계약입찰공고",
      previousBidNo: bidNo, // rebid
    };

    switch (selectedRadio) {
      case 0: // 취소
        bidCancelFnc();
        break;
      case 1: // 재공고입찰
        navigateToBid("/management/bid/request_excel", {
          ...commonState,
          extraType: "ESTIMATE", // rebid
        });
        break;
      default:
        break;
    }
  };

  const aliveCancelOptions = [
    {
      id: 0,
      title: "취소",
      description:
        "본 입찰 공고를 취소시킵니다.\n반복적인 취소에 따라 패널티 발생 및 입찰률 저하가 발생할 수 있습니다.",
    },
    {
      id: 1,
      title: "재공고",
      description:
        "기존 공고는 취소되며, 기존 공고 내용을 바탕으로 새로운 공고를 작성합니다. \n재공고는 공고 당 1회만 가능합니다.",
    },
  ];

  const [bidCancelOpen, setBidCancelOpen] = useState(false);
  const openBidCancelModal = () => setBidCancelOpen(true);
  const closeBidCancelModal = () => setBidCancelOpen(false);

  // 입찰 취소
  const bidCancelFnc = async () => {
    try {
      await estimateCancelApi(bidNo, reasonComment);
      // 얼럿띄우기
      // 다시 api 실행
      openBidCancelModal();
      estimateDetailFnc2();
      // 모달 끄기
    } catch (error) {
      console.error(error);
    }
  };

  const closeFnc = async () => {
    closeAliveReBidModal();
    closeBidCancelModal();
  };

  return (
    <>
      <Modal
        size="small"
        type="warning"
        open={aliveReBidOpen}
        title={
          <Typography component="p" type="h4" color="var(--sub-yellow-10)">
            취소 및 재공고
          </Typography>
        }
        closeIcon={false}
        onOk={validateAliveReBid}
        onCancel={openAliveReBidModal}
        footer={[
          <Button type="danger" onClick={closeAliveReBidModal}>
            취소
          </Button>,
          <Button type="primary" onClick={validateAliveReBid}>
            확인
          </Button>,
        ]}
      >
        <div className="re_bid_modal_container">
          <div className="re_bid_modal_sub_title">
            <Typography component="p" type="h4">
              일방적인 공고 취소 및 중단에 따른 패널티가 발생할 수 있습니다.
            </Typography>
            <Typography component="p" type="h4">
              공고 취소 및 중단 시 상호간의 협의 후 진행해주세요.
            </Typography>
          </div>

          <div className="re_bid_modal_contents">
            <div>
              {aliveCancelOptions.map((option, i) => (
                <div className="re_bid_modal_elem" key={i}>
                  <input
                    type="radio"
                    id={`radio${i}`}
                    checked={selectedRadio === i}
                    onChange={() => handleRadioChange(i)}
                  />
                  <label
                    htmlFor={`radio${i}`}
                    className={selectedRadio === i ? "active_elem" : ""}
                  >
                    <div className="re_bid_modal_elem_title">
                      <Typography component="p" type="h4">
                        {option.title}
                      </Typography>
                    </div>
                    <Typography component="p" type="c2">
                      {option.description.split("\n").map((line, idx) => (
                        <div key={idx}>
                          {line}
                          <br />
                        </div>
                      ))}
                    </Typography>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="re_bid_modal_text_container">
          <Typography component="p" type="h5">
            사유입력
          </Typography>

          <div
            className="re_bid_modal_textarea_container textarea_div"
            spellCheck="false"
          >
            <textarea
              placeholder="추가 설명이 있으시다면 작성해주세요."
              onChange={handleCommentChange}
              value={reasonComment}
            ></textarea>
            <div className="add_text_alert_container">
              <span className="add_alert_desc">
                * 300자 이내로 작성해 주세요.
              </span>
              <span className="add_alert_typing">
                {reasonComment.length} / 300
              </span>
            </div>
          </div>
        </div>
      </Modal>
      {alertOpen && (
        <Modal
          size="xsmall"
          type="warning"
          open={alertOpen}
          closeIcon={false}
          title={
            <Typography component="p" type="h4" color="var(--sub-yellow-10)">
              취소 및 재공고 방법과 사유를 입력해주세요.
            </Typography>
          }
          onOk={closeAlertModal}
          onCancel={closeAlertModal}
          footer={[
            <Button type="primary" onClick={closeAlertModal}>
              확인
            </Button>,
          ]}
        >
          <Typography component="p" type="c2" color="var(--sub-gray-09)">
            취소, 재공고 중 한가지를 선택하신 후, 사유를 입력해주시기 바랍니다.
          </Typography>
        </Modal>
      )}

      {bidCancelOpen && (
        <Modal
          size="xsmall"
          type="success"
          open={bidCancelOpen}
          closeIcon={false}
          title={
            <Typography component="p" type="h2" color="var(--sub-blue-05)">
              취소 완료
            </Typography>
          }
          onOk={closeFnc}
          onCancel={closeBidCancelModal}
          footer={[
            <Button type="primary" onClick={closeFnc}>
              확인
            </Button>,
          ]}
        >
          <Typography component="p" type="h4" color="var(--sub-gray-90)">
            본 입찰 건이 아래 사유로 취소되었습니다.
          </Typography>
          <br />
          <Typography component="p" type="c2" color="var(--sub-gray-09)">
            {reasonComment}
          </Typography>
        </Modal>
      )}
    </>
  );
};

export default AliveReBidModal;
