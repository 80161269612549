import React, { useState } from "react";
import Typography from "../../../../component/ui/typography/Typography"; // Assuming Typography is imported from a custom component
import Modal from "../../../../component/ui/modal/Modal"; // Assuming Typography is imported from a custom component
import Button from "../../../../component/ui/button/Button"; // Assuming Typography is imported from a custom component
// import Button from "../../../../component/ui/button/Button"; // Assuming Typography is imported from a custom component
import { useNavigate } from "react-router-dom";
import { bidFailApi } from "../../../../api/auth";

const ReBidModal = ({
  reBidOpen,
  openReBidModal,
  closeReBidModal,
  detailData,
  pList,
  estimateDetailFnc2,
  bidNo,
}) => {
  const navigate = useNavigate();
  const [selectedRadio, setSelectedRadio] = useState(-1);

  const [alertReBidOpen, setAlertReBidOpen] = useState(false);
  const openAlertReBidModal = () => setAlertReBidOpen(true);
  const closeAlertReBidModal = () => setAlertReBidOpen(false);
  const handleRadioChange = (index) => {
    setSelectedRadio(index);
  };

  const [reasonComment, setReasonComment] = useState("");
  const handleCommentChange = (e) => {
    const value = e.target.value;

    if (value.length <= 300) {
      setReasonComment(value);
    } else {
      const truncatedText = value.substring(0, 300);
      setReasonComment(truncatedText);
    }
  };

  const navigateToBid = (path, state) => {
    navigate(path, { state });
  };

  const getBidImgList = (bidImgInfoList) => {
    return bidImgInfoList?.map((item) => ({
      fileIndex: item.fileNo,
      fileNo: item.fileNo,
      fileName: item.fileName,
      filePath: item.fileRoot,
    }));
  };

  const validateReBidModal = () => {
    // 아무것도 선택하지 않거나, 사유 입력하지 않았을 때
    if (selectedRadio === -1 || reasonComment === "") {
      return openAlertReBidModal();
    }

    // 재입찰이나 재공고입찰시 필요한 데이터 담기
    const commonState = {
      comment: detailData.bid?.comment, // 투찰시 사용자가 입력한 코멘트
      reBidComment: reasonComment, // 재견적할 때 쓴 코멘트
      bidProductList: pList,
      bidImgList: getBidImgList(detailData.bidImgInfoList),
      choiceOrderMethod:
        detailData.bid.type === "S" ? "일회성입찰공고" : "단가계약입찰공고",
      previousBidNo: bidNo, // rebid
    };

    console.log(detailData.bid.type);
    switch (selectedRadio) {
      case 0: // 유찰
        bidFail(detailData?.bid?.no, reasonComment);
        break;
      case 1: // 재입찰
        navigateToBid("/management/bid/request_input_info", {
          ...commonState,
          extraType: "BID", // rebid
        });
        break;
      case 2: // 재공고입찰
        navigateToBid("/management/bid/request_excel", {
          ...commonState,
          extraType: "ESTIMATE", // rebid
        });
        break;
      default:
        break;
    }
  };

  const reBidOptions = [
    {
      id: 0,
      title: "유찰",
      description:
        "본 입찰 공고를 유찰 시킵니다.\n특정 사유로 구매 의사가 없어진 경우 진행할 수 있습니다.",
    },
    {
      id: 1,
      title: "재입찰",
      description:
        "기존 공고는 유찰되며, 품목의 변동 없이 새로운 입찰을 다시 진행합니다.\n납품 기한의 변동으로 동일한 조건에서 다시 입찰을 받고 싶은 경우 선택합니다.",
    },
    {
      id: 2,
      title: "재공고 입찰",
      description:
        "품목 및 조건을 변경하여 입찰을 다시 진행하는 방식입니다.\n품목 상의 변동나 납품 조건 등 모든 내용의 변동이 필요한 경우 선택합니다.",
    },
  ];

  const [bidFailOpen, setBidFailOpen] = useState(false);
  const openBidFailModal = () => setBidFailOpen(true);
  const closeBidFailModal = () => setBidFailOpen(false);
  // 입찰 유찰
  const bidFail = async (bidNo, reason) => {
    try {
      await bidFailApi(bidNo, reason);
      // 얼럿띄우기
      openBidFailModal();
      // 다시 api 실행
      estimateDetailFnc2();
      // 모달 끄기
      closeReBidModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        size="small"
        type="success"
        open={reBidOpen}
        title={
          <Typography component="p" type="h4" color="var(--sub-blue-05)">
            유찰 및 재입찰
          </Typography>
        }
        closeIcon={false}
        onOk={validateReBidModal}
        onCancel={openReBidModal}
        footer={[
          <Button type="danger" onClick={closeReBidModal}>
            취소
          </Button>,
          <Button type="primary" onClick={validateReBidModal}>
            확인
          </Button>,
        ]}
      >
        <div className="re_bid_modal_container">
          <div className="re_bid_modal_sub_title">
            <Typography component="p" type="h4">
              유찰 및 재입찰 방법을 선택 후, 사유를 입력해주세요.
            </Typography>
          </div>

          <div className="re_bid_modal_contents">
            <div>
              {reBidOptions.map((option, i) => (
                <div className="re_bid_modal_elem" key={i}>
                  <input
                    type="radio"
                    id={`radio${i}`}
                    checked={selectedRadio === i}
                    onChange={() => handleRadioChange(i)}
                  />
                  <label
                    htmlFor={`radio${i}`}
                    className={selectedRadio === i ? "active_elem" : ""}
                  >
                    <div className="re_bid_modal_elem_title">
                      <Typography component="p" type="h4">
                        {option.title}
                      </Typography>
                    </div>
                    <Typography component="p" type="c2">
                      {option.description.split("\n").map((line, idx) => (
                        <div key={idx}>
                          {line}
                          <br />
                        </div>
                      ))}
                    </Typography>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="re_bid_modal_text_container">
          <Typography component="p" type="h5">
            사유입력
          </Typography>

          <div
            className="re_bid_modal_textarea_container textarea_div"
            spellCheck="false"
          >
            <textarea
              placeholder="추가 설명이 있으시다면 작성해주세요."
              onChange={handleCommentChange}
              value={reasonComment}
            ></textarea>
            <div className="add_text_alert_container">
              <span className="add_alert_desc">
                * 300자 이내로 작성해 주세요.
              </span>
              <span className="add_alert_typing">
                {reasonComment.length} / 300
              </span>
            </div>
          </div>
        </div>
      </Modal>
      {alertReBidOpen && (
        <Modal
          size="xsmall"
          type="warning"
          open={alertReBidOpen}
          closeIcon={false}
          title={
            <Typography component="p" type="h4" color="var(--sub-yellow-10)">
              유찰 및 재입찰 방법과 사유를 입력해주세요.
            </Typography>
          }
          onOk={closeAlertReBidModal}
          onCancel={closeAlertReBidModal}
          footer={[
            <Button type="primary" onClick={closeAlertReBidModal}>
              확인
            </Button>,
          ]}
        >
          <Typography component="p" type="c2" color="var(--sub-gray-09)">
            유찰, 재입찰, 재공고 입찰 중 한가지를 선택하신 후, 사유를
            입력해주시기 바랍니다.
          </Typography>
        </Modal>
      )}
      {bidFailOpen && (
        <Modal
          size="xsmall"
          type="success"
          open={bidFailOpen}
          closeIcon={false}
          title={
            <Typography component="p" type="h2" color="var(--sub-blue-05)">
              유찰 완료
            </Typography>
          }
          onOk={closeBidFailModal}
          onCancel={closeBidFailModal}
          footer={[
            <Button type="primary" onClick={closeBidFailModal}>
              확인
            </Button>,
          ]}
        >
          <Typography component="p" type="h4" color="var(--sub-gray-90)">
            본 입찰 건이 아래 사유로 유찰되었습니다.
          </Typography>
          <br />
          <Typography component="p" type="c2" color="var(--sub-gray-09)">
            {reasonComment}
          </Typography>
        </Modal>
      )}
    </>
  );
};

export default ReBidModal;
