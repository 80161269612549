import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { signupSchema } from "../../pages/user/signup/schema/schema";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { actions } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import useCheckEmail from "../useQuery/useCheckEmail";
import useCheckPhone from "../useQuery/useCheckPhone";
import useCheckCode from "../useQuery/useCheckCode";
import useFetchOrgInfo from "../useQuery/useFetchOrgInfo";
import useFetchSiteInfo from "../useQuery/useFetchSiteInfo";
import useSignup from "../useMutation/useSignup";
import useLogin from "../useMutation/useLogin";
import useTimer from "../useTimer";
import { fetchSiteInfoApi } from "../../api/auth";

const useSignupHandler = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { code: inviteCode } = useParams();
  const { time, start, end, isActive } = useTimer(180);
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    watch,
  } = useForm({
    resolver: zodResolver(signupSchema),
    mode: "all",
    defaultValues: {
      email: "",
      password: "",
      password_check: "",
      name: "",
      org_name: "",
      dept: "",
      select_rank: "",
      input_rank: "",
      phone: "",
      code: "",
    },
  });

  const email = watch("email");
  const password = watch("password");
  const phone = watch("phone");
  const code = watch("code");

  const [phoneInputDisable, setPhoneInputDisable] = useState(false);
  const [phoneBtnDisable, setPhoneBtnDisable] = useState(false);
  const [codeInputDisable, setCodeInputDisable] = useState(true);
  const [codeBtnDisable, setCodeBtnDisable] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [agreementCheckModal, setAgreementCheckModal] = useState(false);
  const [orgSearchModal, setOrgSearchModal] = useState(false);
  const [lastOrgNo, setLastOrgNo] = useState(null);

  const { refetch: checkEmail } = useCheckEmail({ email });
  const { refetch: checkPhone } = useCheckPhone({
    userType: "S",
    phone,
    code,
  });
  const { refetch: checkCode } = useCheckCode({ userType: "S", phone, code });
  const { data: siteInfo } = useFetchSiteInfo(lastOrgNo);

  const handleCheckEmail = useCallback(async () => {
    if (email) {
      try {
        const { data: response } = await checkEmail();
        if (response.status === "fail") {
          setError("email", {
            type: "manual",
            message: "이미 사용 중인 이메일입니다.",
          });
        }
      } catch (error) {
        console.error("이메일 중복 검사 중 오류 발생:", error);
      }
    }
  }, [email, checkEmail, setError]);

  const handleCheckPhone = useCallback(async () => {
    if (phone) {
      try {
        const { data: response } = await checkPhone();
        if (response.status === "success") {
          start();
          clearErrors("phone");
          setPhoneInputDisable(true);
          setPhoneBtnDisable(true);
          setCodeInputDisable(false);
          setCodeBtnDisable(false);
        } else if (response.status === "fail") {
          setError("phone", {
            type: "manual",
            message: response.message,
          });
        }
      } catch (error) {
        console.error("휴대폰 인증 요청 중 오류 발생:", error);
      }
    }
  }, [phone, checkPhone, start, clearErrors, setError]);

  const handleCheckCode = useCallback(async () => {
    if (code) {
      try {
        const { data: response } = await checkCode();
        if (response.status === "success") {
          end();
          clearErrors("code");
          setPhoneInputDisable(true);
          setPhoneBtnDisable(true);
          setCodeInputDisable(true);
          setCodeBtnDisable(true);
        } else if (response.status === "fail") {
          setError("code", {
            type: "manual",
            message: response.message,
          });
        }
      } catch (error) {
        console.error("휴대폰 인증 요청 중 오류 발생:", error);
      }
    }
  }, [code, checkCode, end, clearErrors, setError]);

  const onLoginSuccessCb = async ({ status, data }) => {
    if (status === "success") {
      dispatch(actions.setToken(data.token));

      const response = await fetchSiteInfoApi(data.lastOrganizationNo);

      const userData = {
        u_no: data.userNo,
        u_name: data.name,
        u_phone: data.phone,
        u_rank: data.rank,
        u_type: data.userType,
        identityVerification: data.identityVerification,
        lastOrganizationNo: data.lastOrganizationNo,
        organizationState: data.organizationState,
        showEsg: data.showEsg,

        con_num: response?.data?.[0]?.constructionNo,
        rep_con: response?.data?.[0]?.constructionName,
        constructionType: response?.data?.[0]?.constructionType,
        site_num: response?.data?.[0]?.site?.no,
        rep_site: response?.data?.[0]?.site?.name,
        isDemo: response?.data?.[0]?.site?.isDemo,
        isAuthenticated: true,
      };

      dispatch(actions.setUser(userData));

      navigate("/", { state: { isFromSignupPage: true } });
    }
  };

  const onLoginErrorCb = (error) => {
    console.error("Login failed: ", error);
  };

  const onSignupSuccessCb = ({ status }) => {
    if (status === "success") {
      login({ email, password });
    }
  };

  const onSignupErrorCb = (error) => {
    console.error("Signup failed: ", error);
  };

  const { mutate: login } = useLogin(onLoginSuccessCb, onLoginErrorCb);
  const { mutate: signup } = useSignup(onSignupSuccessCb, onSignupErrorCb);
  const { data: orgInfo } = useFetchOrgInfo({ code: inviteCode });
  const orgNameFromInviteCode = orgInfo?.data?.organizationName;
  const emailFromInviteCode = orgInfo?.data?.email;

  const handleSignup = useCallback(
    (data) => {
      if (checkedList?.length !== 4) {
        setAgreementCheckModal(true);
        return;
      }

      signup({
        email: data.email,
        password: data.password,
        name: data.name,
        organizationType: "Customer",
        organizationName: data.org_name,
        department: data.dept,
        rank:
          data.select_rank === "직접 입력" ? data.input_rank : data.select_rank,
        phone: data.phone,
        inviteCode: orgInfo?.status === "success" ? inviteCode : "",
      });
    },
    [checkedList?.length, inviteCode, orgInfo?.status, signup]
  );

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const firstErrorKey = Object.keys(errors)[0];
      document.querySelector(`input[name="${firstErrorKey}"]`)?.focus();
    }
  }, [errors]);

  useEffect(() => {
    if (orgInfo) {
      setValue("org_name", orgNameFromInviteCode);
      setValue("email", emailFromInviteCode);
    }
  }, [emailFromInviteCode, orgInfo, orgNameFromInviteCode, setValue]);

  useEffect(() => {
    if (time === "00:00") {
      setPhoneInputDisable(false);
      setPhoneBtnDisable(false);
      setError("phone", {
        type: "manual",
        message: "3분이 경과했습니다. 다시 인증번호를 받아주세요.",
      });
    }
  }, [setError, time]);

  useEffect(() => {
    if (token && user.isAuthenticated) {
      navigate("/", { replace: true });
    }
  }, [navigate, token, user.isAuthenticated]);

  return {
    handleSubmit,
    control,
    errors,
    setValue,
    setError,
    clearErrors,
    phone,
    code,
    phoneInputDisable,
    phoneBtnDisable,
    codeInputDisable,
    codeBtnDisable,
    checkedList,
    setCheckedList,
    agreementCheckModal,
    setAgreementCheckModal,
    orgSearchModal,
    setOrgSearchModal,
    handleCheckEmail,
    handleCheckPhone,
    handleCheckCode,
    handleSignup,
    time,
    isActive,
    orgNameFromInviteCode,
  };
};

export default useSignupHandler;
