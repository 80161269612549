import Chip from "../../../../../component/ui/chip/Chip";

const typeConfig = {
  REBID: { color: "green", label: "재입찰" },
  REESTIMATE: { color: "yellow", label: "재공고입찰" },
};

// estimate type chip
export default function ExtraTypeChip({ type }) {
  const chipConfig = typeConfig[type];

  return chipConfig ? (
    <Chip size="medium" type="border" color={chipConfig.color}>
      {chipConfig.label}
    </Chip>
  ) : null;
}
