import Chip from "../../../../../component/ui/chip/Chip";

const typeConfig = {
  S: { color: "green", label: "일회성" },
  C: { color: "yellow", label: "단가계약" },
};

// estimate type chip
export default function TypeChip({ type }) {
  const chipConfig = typeConfig[type];

  return chipConfig ? (
    <Chip size="medium" type="border" color={chipConfig.color}>
      {chipConfig.label}
    </Chip>
  ) : null;
}
