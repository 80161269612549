import React from "react";
import { useMediaQuery } from "react-responsive";
import Button from "../../../../../../component/ui/button/Button";

const SiteLeaveButton = ({ setSiteLeaveModal }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 1080px)" });
  const onClickSiteLeaveButton = () => {
    setSiteLeaveModal(true);
  };

  return (
    <Button
      type="danger"
      size="medium"
      style={{ width: isMobile && "46.8rem", minWidth: isMobile && "46.8rem" }}
      onClick={onClickSiteLeaveButton}
    >
      현장 나가기
    </Button>
  );
};

export default SiteLeaveButton;
