import React, { useState, useEffect } from "react";
//etc
import i_close from "../../../asset/images/w-cancel-icon.png";
import i_star from "../../../asset/images/w-star-icon.png";
import i_star_empty from "../../../asset/images/m-big-star-icon-n.svg";

export default function CompanyPop({ setDetailPop, comDetail }) {
  let RatingArray = [1, 2, 3, 4, 5];

  const hyphen = (phoneNum) => {
    if (phoneNum != undefined)
      return phoneNum.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
    return phoneNum;
  };

  return (
    <div className="company_info_pop">
      <div className="pop_dim" onClick={() => setDetailPop(false)}></div>
      <div className="company_info_pop_box">
        <div className="look_close" onClick={() => setDetailPop(false)}>
          <img src={i_close} alt="" />
        </div>
        <h3>업체정보</h3>
        <div className="look_company">
          <div className="look_company_img">
            <img src={comDetail?.f_root} style={{ width: "100%" }} />
          </div>
          <div className="look_company_info">
            <div className="look_company_name">
              <p>{comDetail?.c_name}</p>
              <span>
                총 평점 : {comDetail?.totalAvg ? comDetail?.totalAvg : 0} / 5
              </span>
            </div>
            <p>공급사분류 : {comDetail?.c_type} </p>
            <p>
              {comDetail?.c_div === "P" ? "개인" : "법인"}사업자 :{" "}
              {comDetail?.c_num}
            </p>
            <p>대표번호 : {hyphen(comDetail.c_phone)}</p>
            <p>
              {comDetail?.c_addr1},{comDetail?.c_addr2}, {comDetail?.c_zip}{" "}
            </p>
          </div>
        </div>
        <ul className="look_company_rv">
          <li>
            <p>납품 일정을 잘 지켰나요?</p>
            <div className="rv_star">
              <span className="rv_star_img">
                {RatingArray.map((a, index) => {
                  return (
                    <img src={index < comDetail.qAvg ? i_star : i_star_empty} />
                  );
                })}
              </span>
            </div>
          </li>
          <li>
            <p>주문한 상품이 제대로 왔나요?</p>
            <div className="rv_star">
              <span className="rv_star_img">
                {RatingArray.map((a, index) => {
                  return (
                    <img src={index < comDetail.pAvg ? i_star : i_star_empty} />
                  );
                })}
              </span>
            </div>
          </li>
          <li>
            <p>질의 대응이 빨랐나요?</p>
            <div className="rv_star">
              <span className="rv_star_img">
                {/* {[...Array(comDetail?.rv_qscore)].map(()=><img src={i_star}/>)}
                {arr3.map(()=><img src={i_star_empty}/>)} */}
                {RatingArray.map((a, index) => {
                  return (
                    <img src={index < comDetail.qAvg ? i_star : i_star_empty} />
                  );
                })}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
