import React, { useState } from "react";
//library
import { Link, useNavigate } from "react-router-dom";

import { useMediaQuery } from "react-responsive";

//etc
import "../../asset/css/common.css";
import m_cancle from "../../asset/images/m-cancel-icon.png";
import m_down from "../../asset/images/m-side-down-icon.png";
import m_up from "../../asset/images/m-side-up-icon.png";

//img
import alarm_icon_white from "../../asset/images/icon_alarm_white.svg";
import site_icon_white from "../../asset/images/icon_select_site_white.png";
import w_history_icon from "../../asset/images/w-history-icon.png";

//pop
import AlarmHeaderPop from "./Pop/AlarmHeaderPop";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import SiteSwitchModal from "../../pages/myPage/my-info/components/modal/site-switch/SiteSwitchModal";

export default function Mnavi({
  naviSwitch,
  setNaviSwitch,
  comeList,
  setComeList,
  userName,
  selectedSite,
  logoutFnc,
}) {
  const navigate = useNavigate();

  const [alarmPop, setAlarmPop] = useState(false);
  const [countAlarm, setCountAlarm] = useState(0);
  const userData = useSelector((state) => state.user);
  const descLink =
    "https://drive.google.com/file/d/1PevWufMCnWRpoqPxFUo1DxCZJ9zkSxA8/view?usp=sharing";

  const appDownloadFnc = () => {
    swal({
      content: {
        element: "dd",
        attributes: {
          innerHTML: `
          <dd style="font-size: 4.5rem; font-weight:700; margin-bottom: 5rem;">모바일 환경에서는 다음 방법을 사용해주세요.</dd>
          <dd style="font-size:3.5rem; line-height:1.3">
          모바일에서는 아래 가이드 파일의 안내에 따라 진행해주세요. <br/>
          <a href=${descLink} target="_blank" style="text-decoration: underline;">가이드파일링크</a>
          </dd>
        `,
        },
      },
      icon: "info",
    });
  };

  const isDemo = userData?.isDemo;

  const [siteSwitchModal, setSiteSwitchModal] = useState(false);
  const onClickSiteSwitch = () => {
    setSiteSwitchModal(true);
  };

  return (
    <div className={naviSwitch ? "m_nav_wrap" : "m_nav_wrap_off"}>
      <div className="m_nav_container">
        <div className="m_nav_top">
          <div className="m_nav_top1">
            <div className="m_cancle_btn">
              <img
                src={m_cancle}
                onClick={() => {
                  setNaviSwitch(false);
                }}
                alt={"nav_switch"}
              />
            </div>
            <div className="m_top_info">
              <div className="m_info_left">
                {selectedSite && <p>{selectedSite}</p>}

                <p>{userName}</p>
              </div>
              <p onClick={logoutFnc}>로그아웃</p>
            </div>
          </div>

          <div className="m_nav_top2">
            <div className="button_wrap">
              {/* <div
                className="m_nav_order_btn"
                onClick={() => {
                  navigate("/management/request_order");
                }}
              >
                {userData.constructionType === "SUBCONTRACTOR"
                  ? "PB 주문하기"
                  : "주문하기"}
              </div> */}
              <div
                className="m_nav_estimate_btn"
                onClick={() => {
                  navigate("/management/bid/request_choice");
                }}
              >
                입찰/주문하기
              </div>
            </div>

            <div className="m_nav_icon_inner">
              <div
                className="m_nav_icon"
                onClick={() => {
                  navigate("/my_page/history");
                }}
              >
                <img src={w_history_icon} className="header_my_page_icon" />
                <p>히스토리</p>
              </div>
              {/* <div
                className="m_nav_icon"
                onClick={() => {
                  navigate("/management/product_share");
                }}
              >
                <img src={w_share_icon} className="header_my_page_icon" />
                <p>품목보관함</p>
              </div> */}

              <div
                className="m_nav_icon"
                onClick={() => {
                  setAlarmPop(!alarmPop);
                }}
              >
                <img src={alarm_icon_white} className="header_my_page_icon" />
                <p>알림</p>
                {countAlarm !== 0 && (
                  <span className="count_alarm">{countAlarm}</span>
                )}
                {alarmPop && (
                  <AlarmHeaderPop
                    setAlarmPop={setAlarmPop}
                    alarmPop={alarmPop}
                    // move2Success = {_move2Success}
                    // myInfoData={myInfoData}
                  />
                )}
              </div>
              {!isDemo && (
                <div
                  className="m_nav_icon"
                  onClick={(e) => {
                    setAlarmPop(false);
                    onClickSiteSwitch();
                  }}
                >
                  <img src={site_icon_white} className="header_site_icon" />
                  <p>현장선택</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="m_nav_btm">
          <ul className="m_main_nav">
            {userData.constructionType === "SUBCONTRACTOR" && (
              <li className="m_main_li">
                <div className="m_main_li_p">
                  <p onClick={() => navigate("/management/pb/order/1")}>
                    PB상품 주문관리
                  </p>
                </div>
              </li>
            )}
            {comeList.map((data, i) => {
              if (!userData.showEsg && data.mainNav === "ESG 관리") {
                return null;
              }
              if (data.subNav) {
                return (
                  <li className="m_main_li" key={data.mainNav}>
                    <div
                      className={
                        data.active ? "m_main_li_p sub_ok" : "m_main_li_p"
                      }
                      onClick={() => {
                        const CurrentData = comeList.map((item, index) =>
                          index === i && item.active === false
                            ? { ...item, active: true }
                            : { ...item, active: false }
                        );
                        setComeList(CurrentData);
                      }}
                    >
                      <p>{data.mainNav}</p>
                      <p className="sub_down">
                        <img src={data.active ? m_up : m_down} />
                      </p>
                    </div>
                    <ul className={data.active ? "m_sub_nav" : "m_sub_nav_off"}>
                      {data.subNav.map((sub, i) => {
                        return (
                          <li
                            onClick={() => {
                              if (sub.to === "/download/app") {
                                appDownloadFnc();
                                return;
                              }
                              navigate(sub.to);
                              setNaviSwitch(false);
                            }}
                            key={sub.title}
                            className="m_sub_li_p"
                          >
                            <span>{sub.title}</span>

                            {userData.constructionType === "SUBCONTRACTOR" && (
                              <span className="m_side_preview">건설사용</span>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              } else {
                return (
                  <li className="m_main_li">
                    <Link to={data.uri} className="m_main_li_p">
                      <p>{data.mainNav}</p>

                      {userData.constructionType === "SUBCONTRACTOR" && (
                        <p className="m_side_preview">건설사용</p>
                      )}
                    </Link>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
      {siteSwitchModal && (
        <SiteSwitchModal
          siteSwitchModal={siteSwitchModal}
          setSiteSwitchModal={setSiteSwitchModal}
        />
      )}
    </div>
  );
}
