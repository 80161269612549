import React, { useState, useEffect } from "react";
//library
import { useLocation, useNavigate, useParams } from "react-router-dom";

//component
import Mheader from "../../../component/common/Mheader";
import EstimateCancelPop from "../../../component/common/Pop/EstimateCancelPop";
//css
import * as S from "../../../asset/css/estimate.styles.js";
import "../../../asset/css/estimate.css";
//etc
import { estimateListApi } from "../../../api/auth";

//redux
import { useSelector } from "react-redux";

//sweet alert
import DashBoardTabs from "../../../component/etc/DashBoardTabs";
import { useMediaQuery } from "react-responsive";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { ko } from "date-fns/esm/locale";
import Pagination from "../../../component/ui/pagination/Pagination.js";

export default function Estimate() {
  const { page } = useParams();
  const location = useLocation();
  const fromPage = location?.state?.fromPage;
  const url = "/management/estimate/";

  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 1080px)" });
  const userData = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [maxNum, setMaxNum] = useState(0);
  const [firstData, setFirstData] = useState({
    nowSort: fromPage || "ALL",
    s_no: userData.site_num,
    startDate: "2021-01-01",
    endDate: moment().format("yyyy-MM-DD"),
  });
  const [estimateData, setEstimateData] = useState([]);
  const [estimateState, setEstimateState] = useState({});
  const [currentPage, setCurrentPage] = useState(Number(page) || 1);

  const estimateDataFnc = async () => {
    const response = await estimateListApi(
      currentPage,
      firstData.nowSort,
      firstData.s_no,
      firstData.startDate,
      firstData.endDate
    );
    if (response.status === "success") {
      setEstimateData(response.data.bidList);
      setMaxNum(response.data.maxNum);
      setEstimateState(response.data.state);
    }
  };

  useEffect(() => {
    estimateDataFnc();
  }, [firstData, currentPage]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectValue, setSelectValue] = useState("전체");

  const onChangeSelectValue = (e) => {
    setSelectValue(e.currentTarget.value);
    setCurrentPage(1);
  };

  const setDate = (subtract, unit) => {
    setStartDate(
      new Date(moment().subtract(subtract, unit).format("yyyy-MM-DD"))
    );
    setEndDate(new Date(moment().format("yyyy-MM-DD")));
    setFirstData({
      ...firstData,
      startDate: moment().subtract(subtract, unit).format("yyyy-MM-DD"),
      endDate: moment().format("yyyy-MM-DD"),
    });
  };

  useEffect(() => {
    if (selectValue === "최근 1개월") setDate(1, "M");
    if (selectValue === "최근 3개월") setDate(3, "M");
    if (selectValue === "최근 6개월") setDate(6, "M");
    if (selectValue === "최근 1년") setDate(1, "y");
    if (selectValue === "전체") {
      setStartDate(new Date("2021-01-01"));
      setEndDate(new Date(moment().format("yyyy-MM-DD")));
      setFirstData({
        ...firstData,
        startDate: "2021-01-01",
        endDate: moment().format("yyyy-MM-DD"),
      });
    }
  }, [selectValue]);

  useEffect(() => {
    estimateDataFnc();
  }, [page]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(`${url}${page}`);
  };

  return (
    <S.Wrapper>
      <Mheader title="입찰관리" />
      <S.DashboardContainer>
        {isDesktop && <DashBoardTabs />}
        <S.DashBoardContent>
          <S.ContentHeader>
            <S.TitleWrap>
              <S.Title>나의 입찰 현황</S.Title>
              {isDesktop && (
                <>
                  <div>|</div>
                  <S.SelectedDate>
                    {firstData?.startDate} ~ {firstData?.endDate}
                  </S.SelectedDate>
                </>
              )}
            </S.TitleWrap>

            {isDesktop && (
              <S.AlignWrap>
                <S.DateRangeSelect
                  onChange={onChangeSelectValue}
                  value={selectValue}
                >
                  <option>최근 1개월</option>
                  <option>최근 3개월</option>
                  <option>최근 6개월</option>
                  <option>최근 1년</option>
                  <option>전체</option>
                </S.DateRangeSelect>

                <S.DateWrap>
                  <ReactDatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setFirstData({
                        ...firstData,
                        startDate: moment(date).format("yyyy-MM-DD"),
                      });
                      navigate(url + "1");
                      setCurrentPage(1);
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="yyyy-MM-dd"
                    locale={ko}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </S.DateWrap>

                <S.DateWrap>
                  <ReactDatePicker
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                      setFirstData({
                        ...firstData,
                        endDate: moment(date).format("yyyy-MM-DD"),
                      });
                      navigate(url + "1");
                      setCurrentPage(1);
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="yyyy-MM-dd"
                    locale={ko}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </S.DateWrap>
              </S.AlignWrap>
            )}
          </S.ContentHeader>

          <S.ContentBody>
            <S.EstimateStateWrap>
              <S.EstimateState>입찰진행</S.EstimateState>
              <S.EstimateAliveBtn
                firstData={firstData}
                onClick={() => {
                  setFirstData({
                    ...firstData,
                    nowSort: "ALIVE",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {estimateState.live}건
              </S.EstimateAliveBtn>
            </S.EstimateStateWrap>

            <S.EstimateStateWrap>
              <S.EstimateState>투찰마감</S.EstimateState>
              <S.EstimateChooseBtn
                firstData={firstData}
                onClick={() => {
                  setFirstData({
                    ...firstData,
                    nowSort: "CHOOSE",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {estimateState.choose}건
              </S.EstimateChooseBtn>
            </S.EstimateStateWrap>

            <S.EstimateStateWrap>
              <S.EstimateState>선정완료</S.EstimateState>
              <S.EstimateEndBtn
                firstData={firstData}
                onClick={() => {
                  setFirstData({
                    ...firstData,
                    nowSort: "END",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {estimateState.complete}건
              </S.EstimateEndBtn>
            </S.EstimateStateWrap>

            {isDesktop && <S.DivideLine></S.DivideLine>}

            <S.EstimateStateWrap>
              <S.EstimateState>전체보기</S.EstimateState>
              <S.EstimateAllBtn
                firstData={firstData}
                onClick={() => {
                  setFirstData({
                    ...firstData,
                    nowSort: "ALL",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {Object.values(estimateState).reduce(
                  (acc, cur) => +acc + +cur,
                  0
                )}
                건
              </S.EstimateAllBtn>
            </S.EstimateStateWrap>
          </S.ContentBody>

          <S.ContentFooter>
            <S.CancelNumBtn
              firstData={firstData}
              onClick={() => {
                setFirstData({
                  ...firstData,
                  nowSort: "CANCEL",
                });
                navigate(url + "1");
                setCurrentPage(1);
              }}
            >
              취소 <span>{estimateState.cancel}</span>건
            </S.CancelNumBtn>
            <S.FailNumBtn
              firstData={firstData}
              onClick={() => {
                setFirstData({
                  ...firstData,
                  nowSort: "FAIL",
                });
                navigate(url + "1");
                setCurrentPage(1);
              }}
            >
              유찰 <span>{estimateState.fail}</span>건
            </S.FailNumBtn>
          </S.ContentFooter>
        </S.DashBoardContent>

        {isDesktop && (
          <>
            <S.SearchResultWrap>
              <S.SearchResult>
                총 <span>{maxNum}</span>건이 검색되었습니다.
              </S.SearchResult>

              <S.OrderCreateBtnWrapper
                onClick={() => {
                  navigate("/management/bid/request_excel");
                }}
              >
                <S.OrderCreateBtn>입찰 공고 작성</S.OrderCreateBtn>
                <S.OrderCreateImg src="/Alarm Play.png" />
              </S.OrderCreateBtnWrapper>
            </S.SearchResultWrap>

            {estimateData?.map((el, idx) => (
              <S.EstimateListWrapper key={idx}>
                <S.ListHeader>
                  <S.EstimateNo>입찰번호 : {el.bid_code}</S.EstimateNo>
                  <S.SiteName>현장명 : {el.s_name}</S.SiteName>
                  <S.EstimateStep el={el}>
                    <S.Step>
                      <p>입찰진행</p>
                    </S.Step>
                    <S.Step>
                      <p>투찰마감</p>
                    </S.Step>
                    <S.Step>
                      <p>선정완료</p>
                    </S.Step>
                  </S.EstimateStep>
                </S.ListHeader>

                <S.ListBody>
                  <S.ListInfo>
                    <S.Badge el={el}>
                      {el.bid_state === "ALIVE" && "입찰진행중"}
                      {el.bid_state === "CHOOSE" && "투찰마감"}
                      {el.bid_state === "END" && "선정완료"}
                      {el.bid_state === "CANCEL" && "입찰취소"}
                      {el.bid_state === "FAIL" && "유찰"}
                    </S.Badge>
                    {el?.bid_extra_type !== null && (
                      <S.ReBadge el={el}>
                        {el.bid_extra_type === "REBID" && "재입찰"}
                        {el.bid_extra_type === "REESTIMATE" && "재공고입찰"}
                      </S.ReBadge>
                    )}
                    <S.NameWrapper>
                      <S.CompanyName>
                        {el.bid_state === "ALIVE" ||
                        el.bid_state === "FAIL" ||
                        el.bid_state === "CANCEL" ||
                        el.bid_state === "CHOOSE"
                          ? "공급사 미선정"
                          : el.c_name}
                      </S.CompanyName>
                      <div>|</div>
                      <S.ProductName>{el.product_desc}</S.ProductName>
                    </S.NameWrapper>
                    <S.DetailInfo>
                      <S.BidType>
                        공고형태 :
                        {(el.bid_type === "C" || el.bid_type === "T") &&
                          ` 단가 계약 입찰 (${el.bid_term}개월)`}
                        {el.bid_type === "S" && ` 일회성 입찰`}
                      </S.BidType>
                      <S.BidDate>
                        입찰마감시간 :{" "}
                        {moment(el.bid_endate).format("YYYY-MM-DD HH:mm")}
                      </S.BidDate>
                      <S.BidCompany>
                        입찰참여업체 :
                        {el.bid_offer_count !== "0"
                          ? ` ${el.bid_offer_count}개사`
                          : " 미입찰"}
                      </S.BidCompany>
                    </S.DetailInfo>
                  </S.ListInfo>
                  <S.BtnWrapper>
                    <S.EstimateContentBtn
                      onClick={() => {
                        navigate(`/management/estimate/document/${el.bid_no}`, {
                          state: {
                            bidNo: el.bid_no,
                          },
                        });
                      }}
                    >
                      입찰 내역 확인 +
                    </S.EstimateContentBtn>
                  </S.BtnWrapper>
                </S.ListBody>
              </S.EstimateListWrapper>
            ))}
            <div className="estimate_pagination_container">
              <Pagination
                current={currentPage}
                total={maxNum}
                onChange={handlePageChange}
                pageSize={10}
              />
            </div>
          </>
        )}
      </S.DashboardContainer>

      {isMobile && (
        <div className="estimate_wrap">
          <S.AlignWrap>
            <S.DateWrap>
              <ReactDatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  setFirstData({
                    ...firstData,
                    startDate: moment(date).format("yyyy-MM-DD"),
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy-MM-dd"
                locale={ko}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </S.DateWrap>

            <S.DateWrap>
              <ReactDatePicker
                selected={endDate}
                onChange={(date) => {
                  setEndDate(date);
                  setFirstData({
                    ...firstData,
                    endDate: moment(date).format("yyyy-MM-DD"),
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="yyyy-MM-dd"
                locale={ko}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </S.DateWrap>
          </S.AlignWrap>

          <S.SearchResultWrap>
            <S.SearchResult>
              검색결과 : <span>{maxNum}</span>건
            </S.SearchResult>
          </S.SearchResultWrap>

          <div className="estimate_container">
            <div className="contract_list">
              <div className="contract_list_top">
                <p
                  onClick={() => {
                    navigate("/management/bid/request_excel");
                  }}
                >
                  <span>입찰 공고 작성</span>
                  <span></span>
                </p>
              </div>
              <div className="contract_list_btm">
                {estimateData.map((el, i) => {
                  return (
                    <div className="contract_content" key={el.bid_no}>
                      <div className="content_top">
                        <span>입찰 번호 :&nbsp;</span>
                        <span className="public_code">{el.bid_code}</span>
                        <span className="num_line">|</span>
                        <span>{el.bid_regdate}</span>
                      </div>
                      <div className="content_btm">
                        <div className="content_box">
                          <p className="company_img">
                            <img src={el.f_root} alt="" />
                          </p>
                          <div className="contract_info">
                            <div className="icon_box">
                              <S.Badge el={el}>
                                {el.bid_state === "ALIVE" && "입찰진행중"}
                                {el.bid_state === "CHOOSE" && "투찰마감"}
                                {el.bid_state === "END" && "선정완료"}
                                {el.bid_state === "CANCEL" && "입찰취소"}
                                {el.bid_state === "FAIL" && "유찰"}
                              </S.Badge>
                              {el.bid_extra_type !== null && (
                                <S.ReBadge el={el}>
                                  {el.bid_extra_type === "REBID" && "재입찰"}
                                  {el.bid_extra_type === "REESTIMATE" &&
                                    "재공고입찰"}
                                </S.ReBadge>
                              )}
                            </div>
                            <p className="contractor">
                              <span>
                                {"[" + el.con_name + "] " + el.s_name}
                              </span>
                              <span>
                                {el.bid_type === "C"
                                  ? "단가 계약 입찰 (" + el.bid_term + "개월)"
                                  : "일회성 입찰"}
                              </span>
                            </p>
                            <div className="contract_text">
                              <p>입찰참여업체 : {el.offerCount}개사</p>
                              {el.bid_state === "ALIVE" && (
                                <p className="bidding_time">
                                  투찰 마감일시 : {el.bidTime}{" "}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="contract_more">
                          <p
                            onClick={() => {
                              navigate(
                                `/management/estimate/document/${el.bid_no}`,
                                {
                                  state: {
                                    data: el,
                                  },
                                }
                              );
                            }}
                          >
                            입찰 내역 확인 +
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="estimate_pagination_container">
              <Pagination
                current={currentPage}
                total={maxNum}
                onChange={handlePageChange}
                pageSize={10}
              />
            </div>
          </div>
        </div>
      )}
    </S.Wrapper>
  );
}
