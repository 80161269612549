import useLeaveSiteHandler from "../../../../../../hooks/handler/useLeaveSiteHandler";
import classNames from "classnames";
import Button from "../../../../../../component/ui/button/Button";
import Modal from "../../../../../../component/ui/modal/Modal";
import Spin from "../../../../../../component/ui/spin/Spin";
import Typography from "../../../../../../component/ui/typography/Typography";
import SiteSearchInput from "../site-attend/components/elements/input/SiteSearchInput";
import SiteLeaveConfirmModal from "../site-leave-confirm/SiteLeaveConfirmModal";
import { COLOR } from "../../../../../../constant/Palette";
import s from "./siteLeaveModal.module.css";
import Chip from "../../../../../../component/ui/chip/Chip";

const SiteLeaveModal = ({ siteNo, siteLeaveModal, setSiteLeaveModal }) => {
  const {
    keyword,
    setKeyword,
    searchKeyword,
    selectedSite,
    setSelectedSite,
    isLoading,
    siteList,
    handleClickSite,
    handleSearch,
    handleAttendSite,
    siteLeaveConfirmModal,
    setSiteLeaveConfirmModal,
  } = useLeaveSiteHandler();

  const onClickCancel = () => {
    setSiteLeaveModal(false);
  };

  const onClickOk = () => {
    setSiteLeaveConfirmModal(true);
  };

  return (
    <Modal
      size="xsmall"
      open={siteLeaveModal}
      title={
        <Typography component="p" type="h4" color={COLOR.GRAY.L09}>
          현장 나가기
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={[
        <Button type="danger" size="small" onClick={onClickCancel}>
          취소
        </Button>,
        <Button
          type="primary"
          size="small"
          onClick={onClickOk}
          disabled={!selectedSite}
        >
          확인
        </Button>,
      ]}
    >
      <SiteSearchInput
        keyword={keyword}
        setKeyword={setKeyword}
        handleSearch={handleSearch}
        setSelectedSite={setSelectedSite}
      />
      <div className={s.site_container}>
        {isLoading && <Spin />}
        {siteList?.map(({ site, isUserPending }) => (
          <div
            className={classNames(s.site, {
              [s.selected]: selectedSite?.no === site.no,
              [s.disabled]:
                siteNo === site.no || site.state === "PENDING" || isUserPending,
            })}
            key={site.no}
            onClick={() => handleClickSite(site)}
            title={site.name}
          >
            <div className={s.typo_container}>
              <Typography
                component="p"
                type={selectedSite?.no === site.no ? "h5" : "c1"}
                color={COLOR.GRAY.L09}
              >
                {site.name}
              </Typography>
              <Typography
                component="p"
                type={selectedSite?.no === site.no ? "c3" : "c4"}
                color={COLOR.GRAY.L05}
              >
                {site.addr1}, {site.addr2}
              </Typography>
            </div>
            {(site.state === "PENDING" || isUserPending) && (
              <div className={s.chip_container}>
                <Chip size="medium" type="default" color="yellow">
                  승인대기
                </Chip>
              </div>
            )}
          </div>
        ))}
        {siteList?.length === 0 && (
          <div className={s.empty_container}>
            <div>
              <Typography component="span" type="c1" color={COLOR.GRAY.L09}>
                <Typography
                  component="span"
                  type="c2"
                  color={COLOR.SUB.BLUE.L05}
                >
                  "{searchKeyword}"
                </Typography>
                가 존재하지 않습니다.
              </Typography>
              <Typography component="p" type="c1" color={COLOR.GRAY.L09}>
                정확한 현장명을 입력해주세요.
              </Typography>
            </div>
          </div>
        )}
      </div>
      {siteLeaveConfirmModal && (
        <SiteLeaveConfirmModal
          siteLeaveConfirmModal={siteLeaveConfirmModal}
          setSiteLeaveConfirmModal={setSiteLeaveConfirmModal}
          setSiteLeaveModal={setSiteLeaveModal}
          selectedSite={selectedSite}
          handleAttendSite={handleAttendSite}
        />
      )}
    </Modal>
  );
};

export default SiteLeaveModal;
