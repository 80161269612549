import swal from "sweetalert";

const MAX_FILE_SIZE = 1024 * 1024 * 10;
const VALID_EXTENSIONS = [
  "png",
  "jpg",
  "jpeg",
  "gif",
  "bmp",
  "heic",
  "txt",
  "doc",
  "docx",
  "hwp",
  "hwpx",
  "ppt",
  "pptx",
  "pdf",
  "xls",
  "xlsx",
  "xlsm",
];

const VALID_EXCEL_EXTENSIONS = ["xls", "xlsx", "xlsm"];

export function checkValidationFile(file) {
  const extension = file?.name.split(".").pop();

  if (!VALID_EXTENSIONS.includes(extension)) {
    swal(
      "파일 확장자가 올바르지 않습니다.",
      `jpg(jpeg), png, gif, bmp, heic, txt, doc(docx), hwp(hwpx), pdf, ppt(pptx), xls(xlsx, xlsm)만 가능`,
      //   `(${VALID_EXTENSIONS.join(", ")}만 가능)`,
      "error"
    );
    return false;
  }
  if (file?.size > MAX_FILE_SIZE) {
    swal("파일 용량이 너무 큽니다. (10MB 제한)", "", "error");
    return false;
  }

  return file;
}

export function checkValidationExcel(file) {
  const extension = file?.name.split(".").pop();

  if (!VALID_EXCEL_EXTENSIONS.includes(extension)) {
    swal("파일 확장자가 올바르지 않습니다.", `xls(xlsx, xlsm)만 가능`, "error");
    return false;
  }

  if (file?.size > MAX_FILE_SIZE) {
    swal("파일 용량이 너무 큽니다. (10MB 제한)", "", "error");
    return false;
  }

  return file;
}
