export const QUERY_KEYS = {
  LOGIN: "login",
  SIGNUP: "signup",
  WITHDRAW: "withdraw",
  SWITCH_TYPE: "switch_type",
  SWITCH_SITE: "switch_site",
  CHECK_EMAIL: "check_email",
  SEARCH_ORG: "search_org",
  SEARCH_SITE: "search_site",
  SEARCH_USER: "search_user",
  CHECK_PHONE: "check_phone",
  CHECK_CODE: "check_code",
  FETCH_ORG_INFO: "fetch_org_info",
  FETCH_SITE_INFO: "fetch_site_info",
  FETCH_MY_PAGE_INFO: "fetch_my_page_info",
  FETCH_ORG_STATE: "fetch_org_state",
  UPDATE_RANK: "update_rank",
  UPDATE_PASSWORD: "update_password",
  CREATE_CONSTRUCTION: "create_construction",
  UPDATE_SITE: "update_site",
  CREATE_SITE: "create_site",
  ATTEND_SITE: "attend_site",
  ATTEND_SITE_APPROVE: "attend_site_approve",
  LEAVE_SITE: "leave_site",
  INVITE_SITE: "invite_site",
  INVITE_MAIL: "invite_mail",
  FILE_UPLOAD: "file_upload",
};
