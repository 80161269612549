import request from "./request";

//redux
import { store } from "../redux/store/store";
import axios from "axios";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

let signToken = false;
const selectSignToken = (state) => {
  return state.token;
};
export const signTokenListener = () => {
  const token = selectSignToken(store.getState());
  signToken = token;
};
store.subscribe(signTokenListener);

//토큰값 얻기 1
// export const getToken = () => {
//   return Cookies.get('user_site');
// };

//회원가입2

export const joinApi = async (
  id,
  password,
  name,
  dept,
  rank,
  auth,
  type,
  authCode
) => {
  return request(
    "api/v1/user",
    "post",
    {},
    {
      u_id: id,
      u_pw: password,
      u_name: name,
      department: dept,
      u_rank: rank,
      u_auth: auth,
      u_type: type,
      u_auth_code: authCode,
    }
  );
};

//회원가입 - 이메일 중복체크3

export const emailCheckApi = async (u_id) => {
  return request(
    "api/v1/check-id",
    "post",
    {},
    {
      u_id: u_id,
    }
  );
};

//회원가입 - 건설사 검색4

export const conSearchApi = async (con_name) => {
  return request(
    "api/v1/construction/",
    "get",
    {},
    {
      con_name: encodeURIComponent(con_name),
    }
  );
};

//회원가입 - 건설사 등록5

export const conRegisterApi = async (data) => {
  return request(
    "api/v1/construction",
    "post",
    {},
    {
      ...data,
    }
  );
};

//회원가입 - 현장 공정리스트 받아오기6

export const processListApi = async () => {
  return request("api/v1/process-list", "get", {}, null);
};

//회원가입 - 신규현장 등록7

export const newSiteRegisterApi = async (data) => {
  return request(
    "api/v1/new-site",
    "post",
    {},
    {
      ...data,
    }
  );
};

//회원가입 - 기존현장 주소 검색하기8
//con_no = 2스탭에서 선택한 회사

export const existSiteSearchApi = async (lat, lng, con_no) => {
  return request(
    "api/v1/exist-site/search",
    "get",
    { Authorization: signToken },
    {
      s_lat: lat,
      s_lng: lng,
      s_con_no: con_no,
    }
  );
};

//회원가입 - 기존현장 현장 상세보기9

export const existSiteDetailApi = async (s_no) => {
  return request(
    "api/v1/exist-site/detail",
    "get",
    {},
    {
      s_no: s_no,
    }
  );
};

//회원가입 - 기존현장 현장 등록하기10

export const existSiteRegisterApi = async (u_no, s_no, c_no) => {
  return request(
    "api/v1/exist-site",
    "post",
    {},
    {
      userNo: u_no,
      siteNo: s_no,
      constructionNo: c_no,
    }
  );
};

export const existenceSiteRegisterApi = async (u_no, s_code) => {
  return request(
    "api/v1/site/add-existence-site",
    "post",
    { Authorization: signToken },
    {
      userNo: u_no,
      s_code: s_code,
    }
  );
};

//이미지 전송11

export const imgUpApi = async (formData) => {
  return request("api/v1/img-up", "post", {}, formData);
};

//로그인12

export const loginApi = async (id, password, pushToken) => {
  return request(
    "api/v1/sign-in",
    "post",
    {},
    {
      u_id: id,
      u_pw: password,
      pushType: pushToken.type ? pushToken.type : "",
      pushValue: pushToken.value ? pushToken.value : "",
    }
  );
};

//메인페이지 - 헤더 정보 불러오기13

export const headerInfoApi = async () => {
  return request(
    "api/v1/site/main/h-info",
    "get",
    { Authorization: signToken },
    null
  );
};

//메인페이지 - 현장 정보 불러오기14

export const siteInfoApi = async (u_no) => {
  return request(
    "api/v1/site/main/board-site/" + u_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//인근업체찾기 - 유저 정보 불러오기15

export const findUserRoleApi = async () => {
  return request(
    "api/v1/site/pay/role",
    "get",
    { Authorization: signToken },
    null
  );
};

//인근업체찾기 - look16

export const silverFindApi = async (siteNum, km) => {
  return request(
    "api/v1/gold/nearby/companys/" + siteNum + "/" + km,
    "get",
    { Authorization: signToken },
    null
  );
};

//인근업체찾기 - finddetailpop17

export const companyDetailApi = async (comNum) => {
  return request(
    // "api/v1/gold/nearby/company-product/" + comNum,
    "v2/company/map/info/" + comNum,
    "get",
    { Authorization: signToken },
    null
  );
};

//인근업체찾기 - companyinfoPop18

export const companyInfoPopApi = async (comNum) => {
  return request(
    // "api/v1/gold/nearby/company-review/" + comNum,
    "v2/company/map/review/" + comNum,
    "get",
    { Authorization: signToken },
    null
  );
};

//인근업체찾기 - 결제 정보 검증19

export const paySendingApi = async (merchant_uid, amount, service_no) => {
  return request(
    "api/v1/site/pay/before-prepare",
    "post",
    { Authorization: signToken },
    {
      merchant_uid: merchant_uid,
      amount: amount,
      service_no: service_no,
    }
  );
};

//인근업체찾기 - 결제 완료20

export const peyCompleteApi = async (data) => {
  return request(
    "api/v1/site/pay/payment-complete",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

///견적요청 - 지정기간단가계약(견적기본정보 - 진행공정 list)21

export const estimateSelectApi = async () => {
  return request(
    "api/v1/site/process",
    "get",
    { Authorization: signToken },
    null
  );
};

///견적요청 - 지정기간단가계약(견적기본정보)22

export const estimateShortApi = async (
  bid_pro_no,
  bid_budget,
  bid_endate,
  bid_fee,
  bid_drop,
  bid_priority,
  bid_refund,
  bid_payday,
  bid_term,
  bid_addr,
  bid_addr_detail,
  bid_s_no,
  bid_past_no
) => {
  if (bid_past_no) {
    return request(
      "api/v1/site/bid/term",
      "post",
      { Authorization: signToken },
      {
        bid_pro_no: bid_pro_no,
        bid_budget: bid_budget,
        bid_endate: bid_endate,
        bid_fee: bid_fee,
        bid_drop: bid_drop,
        bid_priority: bid_priority,
        bid_refund: bid_refund,
        bid_term: bid_term,
        bid_payday: bid_payday,
        bid_addr1: bid_addr, //back코드와 이름 맞춤
        bid_addr2: bid_addr_detail,
        bid_s_no: bid_s_no,
        bid_past_no: bid_past_no,
      }
    );
  } else {
    return request(
      "api/v1/site/bid/term",
      "post",
      { Authorization: signToken },
      {
        bid_pro_no: bid_pro_no,
        bid_budget: bid_budget,
        bid_endate: bid_endate,
        bid_fee: bid_fee,
        bid_drop: bid_drop,
        bid_priority: bid_priority,
        bid_refund: bid_refund,
        bid_term: bid_term,
        bid_payday: bid_payday,
        bid_addr1: bid_addr,
        bid_addr2: bid_addr_detail,
        bid_s_no: bid_s_no,
        bid_past_no: "",
      }
    );
  }
};

///견적요청 - 딘일건 견적(견적기본정보)23

export const estimateShort2Api = async (
  bid_pro_no,
  bid_budget,
  bid_endate,
  bid_deldate,
  bid_fee,
  bid_drop,
  bid_priority,
  bid_refund,
  bid_addr,
  bid_addr_detail,
  bid_s_no,
  bid_past_no
) => {
  if (bid_past_no) {
    return request(
      "api/v1/site/bid/single",
      "post",
      { Authorization: signToken },
      {
        bid_pro_no: bid_pro_no,
        bid_budget: bid_budget,
        bid_endate: bid_endate,
        bid_deldate: bid_deldate,
        bid_fee: bid_fee,
        bid_drop: bid_drop,
        bid_priority: bid_priority,
        bid_refund: bid_refund,
        bid_addr1: bid_addr,
        bid_addr2: bid_addr_detail,
        bid_s_no: bid_s_no,
        bid_past_no: bid_past_no,
      }
    );
  } else {
    return request(
      "api/v1/site/bid/single",
      "post",
      { Authorization: signToken },
      {
        bid_pro_no: bid_pro_no,
        bid_budget: bid_budget,
        bid_endate: bid_endate,
        bid_deldate: bid_deldate,
        bid_fee: bid_fee,
        bid_drop: bid_drop,
        bid_priority: bid_priority,
        bid_refund: bid_refund,
        bid_addr1: bid_addr,
        bid_addr2: bid_addr_detail,
        bid_s_no: bid_s_no,
        bid_past_no: "",
      }
    );
  }
};

//견적요청 - 현장기본주소24

export const estimateRequestApi = async (s_no) => {
  return request(
    "api/v1/site/bid/addr/" + s_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//견적요청 - 견적서 간편입력 (완료)25

export const estimateEasyApi = async (bidProductList) => {
  return request(
    "v2/bid/validate",
    "post",
    { Authorization: signToken },
    { bidProductList: bidProductList }
  );
};

//견적요청 - 품목 직접 선택(분류 리스트)26

export const estimateSortListApi = async () => {
  return request(
    "api/v1/site/bid/process",
    "get",
    { Authorization: signToken },
    null
  );
};

//견적요청 - 품목 직접 선택(검색)27

// export const estimateDirectApi = async (pro_no, lc_no, mc_no, sc_no, pNum, s_key) => {
export const estimateDirectApi = async (data) => {
  return request(
    "api/v1/site/bid/pick/search-post",
    "get",
    { Authorization: signToken },
    {
      ...data,
      s_key: encodeURIComponent(data.s_key),
    }
  );
};

export const productFindApi = async (data) => {
  return request(
    "v2/product/find",
    "post",
    { Authorization: signToken },
    {
      ...data,
    }
  );
};

//견적요청 - 품목 직접 선택 (완료)28

export const estimateClearApi = async (
  finalData,
  bid_comment,
  bid_no,
  siteNum
) => {
  return request(
    "api/v1/site/bid/pick",
    "post",
    { Authorization: signToken },
    {
      pList: finalData,
      bid_comment: bid_comment,
      bid_no: bid_no,
      s_no: siteNum,
    }
  );
};

//견적요청 - 견적요청 다시하기29

export const estimateAgainApi = async (bid_no) => {
  return request(
    "api/v1/site/bid/again",
    "post",
    { Authorization: signToken },
    {
      bid_no: bid_no,
    }
  );
};

//견적 리스트 불러오기30

export const estimateListApi = async (
  pNum,
  bid_state,
  s_no,
  startDate,
  endDate
) => {
  return request(
    "api/v1/site/bid/" +
      s_no +
      "/" +
      pNum +
      "/" +
      bid_state +
      "/" +
      startDate +
      "/" +
      endDate,
    "get",
    { Authorization: signToken },
    null
  );
};

//견적내역 확인31

export const estimateDetailApi = async (bid_no) => {
  return request(
    "api/v1/site/bid/detail/" + bid_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//견적내역 확인 업체정보 불러오기32

export const estimateComInfoApi = async (c_no) => {
  return request(
    "api/v1/site/bid/company-info/" + c_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//견적내역 확인 투찰시간 연장하기33

export const estimateBidExtendApi = async (bid_no, bid_endate) => {
  return request(
    "api/v1/site/bid/bid-extend",
    "put",
    { Authorization: signToken },
    {
      bid_no: bid_no,
      bid_endate: bid_endate,
    }
  );
};

//공고 취소하기34

export const estimateCancelApi = async (bid_no, bid_cancel) => {
  return request(
    "api/v1/site/bid/cancel",
    "put",
    { Authorization: signToken },
    {
      bid_no: bid_no,
      bid_cancel: bid_cancel,
    }
  );
};

//견적내역 확인 계약하기35

// export const estimateContractApi = async (ct_no, bid_no, ct_o_no) => {
//   return request(
//     'api/v1/site/bid/contract',
//     'post',
//     { Authorization: signToken },
//     {
//       ct_no: ct_no,
//       bid_no: bid_no,
//       ct_o_no: ct_o_no,
//     }
//   );
// };

//계약관리 - 계약 리스트 불러오기36

export const contractListApi = async (
  s_no,
  pNum,
  searchData,
  startDate,
  endDate
) => {
  if (startDate !== "" && endDate !== "") {
    // return request('api/v1/site/contract/' + s_no + '/" + pNum + "/" + searchData + "/" +startDate + "/" + lastDate , 'get' , {Authorization : token}, null);
    return request(
      `api/v1/site/contract/contractList?s_no=${s_no}&pNum=${pNum}&searchData=${searchData}&startDate=${startDate}&endDate=${endDate}`,
      "get",
      { Authorization: signToken },
      null
    );
  } else if (startDate === "") {
    return request(
      `api/v1/site/contract/contractList?s_no=${s_no}&pNum=${pNum}&searchData=${searchData}`,
      "get",
      { Authorization: signToken },
      null
    );
  }
};

//계약관리 - 공급계약 내역 확인(세부)37

export const contractDetailApi = async (ct_no, ct_parent, ct_count) => {
  if (ct_parent === "" || ct_parent === undefined) {
    return request(
      "api/v1/site/contract/detail/" + ct_no,
      "get",
      { Authorization: signToken },
      null
    );
  } else {
    return request(
      "api/v1/site/contract/detail/" + ct_no + "/" + ct_parent + "/" + ct_count,
      "get",
      { Authorization: signToken },
      null
    );
  }
};

//계약관리 - 사업자정보 보기38

export const businessInfoApi = async (con_no) => {
  return request(
    "api/v1/site/contract/business/" + con_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//계약관리 - 계약 취소하기39

export const contractCancelApi = async (ct_no, ct_cancel) => {
  return request(
    "api/v1/site/contract/contract-cancel",
    "post",
    { Authorization: signToken },
    {
      ct_no: ct_no,
      ct_cancel: ct_cancel,
    }
  );
};

//계약관리 - 계약 중지 요청40

export const contractPauseApi = async (ct_no, ct_cancel) => {
  return request(
    "api/v1/site/contract/pause",
    "post",
    { Authorization: signToken },
    {
      ct_no: ct_no,
      ct_cancel,
      ct_cancel,
    }
  );
};

//계약관리 - 계약 중지 요청 취소41

export const contractPauseCancelApi = async (ct_no) => {
  return request(
    "api/v1/site/contract/pause-cancel",
    "post",
    { Authorization: signToken },
    {
      ct_no: ct_no,
    }
  );
};

//계약관리 - 주문서 발행42

export const contractOrderListApi = async (ct_no) => {
  return request(
    "api/v1/site/contract/order-sheet/" + ct_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//계약관리 - 주문서 발행(품목 검색 추가)43

export const contractProductAddApi = async (pList) => {
  return request(
    "api/v1/site/contract/add-product",
    "post",
    { Authorization: signToken },
    {
      pList: pList,
    }
  );
};

//계약관리 - 주문서 발행(추가 품목 삭제하기)44

export const contractAddProductDelApi = async (bp_no) => {
  return request(
    "api/v1/site/contract/delete-product",
    "put",
    { Authorization: signToken },
    {
      bp_no: bp_no,
    }
  );
};

//계약관리 - 주문서 발행(주문서 발행 완료하기)45

export const contractOrderApi = async (pList, orderInfo, bid_no, ct_no) => {
  return request(
    "api/v1/site/contract/complete",
    "post",
    { Authorization: signToken },
    {
      pList: pList,
      orderInfo: orderInfo,
      bid_no: bid_no,
      ct_no: ct_no,
    }
  );
};

//주문관리 - 주문리스트 불러오기46

export const orderListApi = async (
  s_no,
  pNum,
  searchData,
  startDate,
  endDate,
  companyNo,
  keyword
) => {
  if (startDate !== "" && endDate !== "") {
    return request(
      "api/v1/site/order/" +
        s_no +
        "/" +
        pNum +
        "/" +
        searchData +
        "/" +
        startDate +
        "/" +
        endDate +
        "/" +
        companyNo +
        "/" +
        keyword,
      "get",
      { Authorization: signToken },
      null
    );
  } else if (startDate === "") {
    return request(
      "api/v1/site/order/" + s_no + "/" + pNum + "/" + searchData,
      "get",
      { Authorization: signToken },
      null
    );
  }
};

//주문관리 - 주문서 발행 내역(상세)47

export const orderDetailApi = async (or_no) => {
  return request(
    "api/v1/site/order/detail/" + or_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//주문관리 - 주문서 발행 취소48

export const orderCancelApi = async (ordersNo, reason) => {
  return request(
    "v2/orders/cancel",
    "post",
    { Authorization: signToken },
    {
      ordersNo: ordersNo,
      reason: reason,
    }
  );
};

//주문관리 - 배송완료
export const deliveryDoneApi = async (ordersNo, endDate) => {
  return request(
    "api/v1/company/order-tab/order-done/",
    "POST",
    { Authorization: signToken },
    {
      ordersNo: ordersNo,
      endDate: endDate,
      userType: "S",
    }
  );
};

//주문관리 - 검수완료49

export const orderCheckApi = async (or_no) => {
  return request(
    "api/v1/site/order/check",
    "post",
    { Authorization: signToken },
    {
      or_no: or_no,
    }
  );
};

// 주문관리 - 반려
export const orderRejectApi = async (ordersNo, rejectReason) => {
  return request(
    "v2/orders/reject",
    "post",
    { Authorization: signToken },
    {
      ordersNo: ordersNo,
      rejectReason: rejectReason,
    }
  );
};

//퀵주문 - 퀵주문 리스트 불러오기50

export const quickOrderListApi = async (
  s_no,
  pNum,
  or_state,
  startDate,
  endDate
) => {
  return request(
    "api/v1/site/quick/" +
      s_no +
      "/" +
      pNum +
      "/" +
      or_state +
      "/" +
      startDate +
      "/" +
      endDate,
    "get",
    { Authorization: signToken },
    null
  );
};

//퀵주문 - 퀵주문 발행내역51

export const quickOrderDetailApi = async (or_no) => {
  return request(
    "api/v1/site/quick/" + or_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//퀵주문 - 공급사 매칭 확인하기52

export const quickOrderMatchingApi = async (s_no) => {
  return request(
    "api/v1/site/quick/matching-check/" + s_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//퀵주문 - 공급사 검색하기53

export const quickOrderSearchApi = async (data) => {
  return request(
    "v2/quick/company/distance/list",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

//퀵주문 - 공급사 검색 후 상세보기54

export const quickOrderComDetailApi = async (c_no) => {
  return request(
    "api/v1/site/quick/company-detail/" + c_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//퀵주문 - 기본 정보 입력55

export const quickOrderRequestApi = async (data) => {
  return request(
    "api/v1/site/quick",
    "post",
    { Authorization: signToken },
    {
      ...data,
    }
  );
};

//퀵주문 - 견적서 간편 입력56

// export const quickOrderEasyApi = async (
//   pList,
//   fList,
//   comment,
//   or_no,
//   q_no,
//   c_no
// ) => {
//   return request(
//     'api/v1/site/quick-product',
//     'post',
//     { Authorization: signToken },
//     {
//       pList: pList,
//       fList: fList,
//       or_uniqueness: comment,
//       or_no: or_no,
//       q_no: q_no,
//       c_no: c_no,
//     }
//   );
// };

//퀵주문 - 퀵주문 취소57

export const quickOrderCancelApi = async (data) => {
  return request(
    "api/v1/site/quick/cancel",
    "put",
    { Authorization: signToken },
    {
      ...data,
    }
  );
};

//퀵주문 - 리뷰 작성하기58

export const reviewApi = async (data) => {
  return request(
    "api/v1/site/review",
    "post",
    { Authorization: signToken },
    {
      ...data,
    }
  );
};

//정산관리 - 정산 리스트 불러오기59

export const calculateListApi = async (s_no, pNum, sort) => {
  return request(
    "api/v1/site/delegated-calculation/" + s_no + "/" + pNum + "/" + sort,
    "get",
    { Authorization: signToken },
    null
  );
};

export const calculateListMonthApi = async (delegated_no, pNum) => {
  return request(
    "api/v1/site/calculation/" + delegated_no + "/" + pNum,
    "get",
    { Authorization: signToken },
    null
  );
};

// export const calculateListApi = async (s_no, pNum, sort) => {
//   return request(
//     'api/v1/site/calculate/' + s_no + '/' + pNum + '/' + sort,
//     'get',
//     { Authorization: signToken },
//     null
//   );
// };

//정산관리 - 상세내역 불러오기60

export const calculateDetailApi = async (calc_no) => {
  return request(
    "api/v1/site/calculation/" + calc_no,
    "get",
    { Authorization: signToken },
    {
      calc_no: calc_no,
    }
  );
};

//정산관리 - 정산 완료하기61

export const calculateCompleteApi = async (delegated_no) => {
  return request(
    "api/v1/site/calculate/complete",
    "put",
    { Authorization: signToken },
    {
      delegated_no: delegated_no,
    }
  );
};

//정산관리 - 세금계산서 확인하기62

export const taxImgApi = async (or_no) => {
  return request(
    "api/v1/site/calculate/tax",
    "get",
    { Authorization: signToken },
    {
      or_no: or_no,
    }
  );
};

//마이페이지  - 정보 불러오기63

export const myPageInfoApi = async (s_no) => {
  return request(
    "api/v1/site/mypage/my-info/" + s_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//마이페이지 - 공지사항 불러오기64

export const noticeDataApi = async (pageNum) => {
  return request(
    "api/v1/site/board-list",
    "get",
    { Authorization: signToken },
    {
      pageNum: pageNum,
    }
  );
};

//마이페이지 - 공지사항 자세히 보기65

export const noticeDetailApi = async (bp_no) => {
  return request(
    "api/v1/site/board-info/" + bp_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//마이페이지 - 유료서비스 이용내역66

export const payHistortApi = async (u_no) => {
  return request(
    "api/v1/site/mypage/payment-history",
    "get",
    { Authorization: signToken },
    {
      u_no: u_no,
    }
  );
};

//마이페이지 - 내정보 변경67

export const rankChangeApi = async (u_rank, u_no) => {
  return request(
    "api/v1/site/mypage/my-rank",
    "put",
    { Authorization: signToken },
    {
      u_rank: u_rank,
      u_no: u_no,
    }
  );
};

//마이페이지 - 비밀번호 변경68

export const passwordChangeApi = async (u_pw, newPw, u_no) => {
  return request(
    "api/v1/site/mypage/my-pw",
    "put",
    { Authorization: signToken },
    {
      u_pw: u_pw,
      newPw: newPw,
      u_no: u_no,
    }
  );
};

//마이페이지 - 핸드폰 번호 변경69
export const phoneNumberChangeApi = async () => {
  return request("", "put", { Authorization: signToken }, {});
};

//마이페이지 - 현장 추가등록 (신규 현장)70

export const addNewSiteRegisterApi = async (data) => {
  return request(
    "api/v1/site/add-new-site",
    "post",
    { Authorization: signToken },
    {
      ...data,
    }
  );
};

export const updateSite = async (data) => {
  return request(
    "api/v1/site/update-site",
    "post",
    { Authorization: signToken },
    {
      ...data,
    }
  );
};

//마이페이지 - 현장 추가등록 (기존 현장)71

export const addExistSiteApi = async (data) => {
  return request(
    "api/v1/site/add-existence-site",
    "post",
    { Authorization: signToken },
    {
      ...data,
    }
  );
};

//마이페이지 - 기존 현장 검색72

// export const addExistSearchApi = async (data) => {
//   return request(
//     "api/v1/site/radius-search",
//     "get",
//     { Authorization: signToken },
//     {
//       ...data,
//     }
//   );
// };

//마이페이지 - 회원탈퇴73

export const withdrawalApi = async (u_no, u_pw) => {
  return request(
    "api/v1/site/mypage/withdrawal",
    "put",
    { Authorization: signToken },
    {
      u_no: u_no,
      u_pw: u_pw,
    }
  );
};

//마이페이지 - 초대문자 발송74

export const inviteCodeApi = async (data) => {
  return request(
    "api/v1/site/send-code",
    "post",
    { Authorization: signToken },
    {
      ...data,
    }
  );
};

//추가

//메인페이지 - 75
export const mainBoardInfoApi = async (s_no) => {
  return request(
    "api/v1/site/main/board-info/" + s_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//210126 Sangkyeol Kim
//상품 정보 조회 API
export const getServiceData = async (data) => {
  return request(
    "api/v1/site/pay/service-info",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

export const paymentFailed = async (data) => {
  return request(
    "api/v1/site/pay/payment-fail",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

export const getNaverInfoByToken = async (data) => {
  return request("api/v1/util/naver-info", "post", {}, { ...data });
};

export const checkSocialRegistered = async (data) => {
  return request("api/v1/social/checkRegistered", "post", {}, { ...data });
};

export const socialLogin = async (type, value, pushToken) => {
  return request(
    "api/v1/social-sign-in",
    "post",
    {},
    {
      type: type,
      value: value,
      pushType: pushToken.type ? pushToken.type : "",
      pushValue: pushToken.value ? pushToken.value : "",
    }
  );
};

export const socialJoin = async (
  id,
  name,
  dept,
  rank,
  auth,
  type,
  authCode,
  socialType,
  socialValue
) => {
  return request(
    "api/v1/socialUser",
    "post",
    {},
    {
      u_id: id,
      u_name: name,
      department: dept,
      u_rank: rank,
      u_auth: auth,
      u_type: type,
      u_auth_code: authCode,
      socialType: socialType,
      socialValue: socialValue,
    }
  );
};

export const uploadExcel = async (formData) => {
  return request("api/v1/util/customer/load-excel", "post", {}, formData);
};

export const uploadQuickExcel = async (formData) => {
  return request("api/v1/util/load-quick-excel", "post", {}, formData);
};

export const serverState = async () => {
  return request("api/v1/State/serverState", "get", {}, null);
};

/**
 *  chatting api
 * */

export const chatRoomList = async (data) => {
  return request(
    "v2/chat/room/list",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

export const chatMessageList = async (data) => {
  return request(
    "v2/chat/message/list",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

export const chatRoomInfo = async (roomNo) => {
  return request(
    "v2/chat/room/info/" + roomNo,
    "get",
    { Authorization: signToken },
    null
  );
};

export const chatUploadFile = async (formData) => {
  return request(
    "api/v1/site/chat/upload-file",
    "post",
    { Authorization: signToken },
    formData
  );
};

export const chatInvite = async (data) => {
  return request(
    "v2/chat/room/invite",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

export const chatInviteList = async (roomNo) => {
  return request(
    "v2/chat/room/invite/" + roomNo,
    "get",
    { Authorization: signToken },
    null
  );
};

//token verification
export const tokenVerification = async (token) => {
  return request(
    "api/v1/util/token-verification",
    "get",
    { Authorization: signToken },
    null
  );
};

//상품 직접 검색 필터링
export const bidSearchProcess = async () => {
  return request(
    "api/v1/site/bid/process-list",
    "get",
    {
      Authorization: signToken,
    },
    null
  );
};

export const bidSearchFirstCategory = async (value) => {
  return request(
    "api/v1/site/bid/first-category-list/" + value,
    "get",
    {
      Authorization: signToken,
    },
    null
  );
};

export const bidSearchSecondCategory = async (value) => {
  return request(
    "api/v1/site/bid/second-category-list/" + value,
    "get",
    {
      Authorization: signToken,
    },
    null
  );
};

export const bidSearchThirdCategory = async (value) => {
  return request(
    "api/v1/site/bid/third-category-list/" + value,
    "get",
    {
      Authorization: signToken,
    },
    null
  );
};

export const getTermsApi = async () => {
  return request(
    "api/v1/site/contract/terms",
    "get",
    { Authorization: signToken },
    null
  );
};

//아이디찾기
export const findAccount = async (data) => {
  return request("api/v1/find/customer", "post", {}, { ...data });
};

//번호 변경
export const changePhone = async (data) => {
  return request(
    "api/v1/site/mypage/change-phone",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

//phone check
export const checkPhoneDup = async (data) => {
  return request("api/v1/check-phone-duplicate", "post", {}, { ...data });
};

//generate contract
export const generateContract = async (
  ct_select,
  ct_sdate,
  ct_o_no,
  ct_comment,
  bid_no
) => {
  return request(
    "api/v1/site/bid/generate-contract",
    "post",
    { Authorization: signToken },
    {
      ct_select: ct_select,
      ct_sdate: ct_sdate,
      ct_o_no: ct_o_no,
      ct_comment: ct_comment,
      bid_no: bid_no,
    }
  );
};

//비교견적서 생성
export const generateCompareExcel = async (data) => {
  return request(
    "api/v1/site/bid/generateCompareExcel",
    "post",
    {
      Authorization: signToken,
    },
    { ...data }
  );
};

//현장번호로 현장가입 날짜 가져오기
export const siteRegDateApi = (s_no) => {
  return request(
    "api/v1/site/contract/site-regdate/" + s_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//로고 변경하고 업데이트하기
export const changeLogoApi = (fileNo) => {
  return request(
    "api/v1/site/mypage/change-logo",
    "post",
    { Authorization: signToken },
    { file_index: fileNo }
  );
};

//에러 로깅
export const logError = async (data) => {
  return request("api/v1/util/error-log", "post", {}, { ...data });
};

//검수 완료 확인서
export const generateCheckApi = async (or_no) => {
  return request(
    "api/v1/site/order/generateCheck/" + or_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//오더 상세보기
export const orDetailApi = async (or_no) => {
  return request(
    "api/v1/site/order/order-detail/" + or_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//정산 지급요청서보기
export const paymentRequestApi = async (delegated_no) => {
  return request(
    "api/v1/site/calculate/generatePaymentsReqExcel/" + delegated_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//채팅
export const testChatApi = async (data) => {
  return request(
    "api/v1/util/chat/sendPush",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

//정산 지급요청서보기
export const estimateTimeOverApi = async (bid_no) => {
  return request(
    "api/v1/site/bid/endDateCheck/" + bid_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//견적입력시 진행공정 띄워주기
export const getProNameApi = async (site_num) => {
  return request(
    "api/v1/site/bid/site-process/" + site_num,
    "get",
    { Authorization: signToken },
    null
  );
};

//변경계약 계약서 생성
export const changeContractAgreeApi = async (ct_no, ct_change) => {
  return request(
    "api/v1/site/contract/generate-change",
    "post",
    { Authorization: signToken },
    {
      ct_no: ct_no,
      ct_change: ct_change,
    }
  );
};

export const changeContractApi = async (ct_no, ct_change) => {
  return request(
    "api/v1/site/contract/change-contract",
    "post",
    { Authorization: signToken },
    {
      ct_no: ct_no,
      ct_change: ct_change,
    }
  );
};

export const contractDetailAllApi = async (ct_no) => {
  return request(
    "api/v1/site/contract/detail/" + ct_no,
    "get",
    { Authorization: signToken },
    null
  );
};

export const bidCreateApi = async (data) => {
  return request(
    "v2/bid/create",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

//각종 site정보 가져오기 api
export const contractAddrApi = async (s_no) => {
  return request("v2/site/" + s_no, "get", { Authorization: signToken }, null);
};

//
export const choiceCompanyApi = async (data) => {
  return request(
    "v2/offer/select",
    "post",
    { Authorization: signToken },
    {
      ...data,
    }
  );
};

//퀵주문 엑셀 validation api
export const quickOrderEasyApi = async (bidProductList) => {
  return request(
    "v2/quick/validate",
    "post",
    { Authorization: signToken },
    { ...bidProductList }
  );
};

//퀵주문 완료 api
export const quickBidCreateApi = async (data) => {
  return request(
    "v2/quick/create",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

export const termBidCompleteApi = async (data) => {
  return request(
    "v2/orders/create/term",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

export const estimateContractApi = async (
  contractNo,
  startDate,
  selectReason
) => {
  return request(
    "v2/contract/agree/customer",
    "post",
    { Authorization: signToken },
    {
      contractNo: contractNo,
      startDate: startDate,
      selectReason: selectReason,
    }
  );
};

export const aliveContractApi = async (siteNo, page) => {
  return request(
    "v2/contract/alive/" + siteNo + "/" + page,
    "get",
    { Authorization: signToken },
    null
  );
};

//메인페이지 - 현장 정보 불러오기14
export const siteInfoApi2 = async () => {
  return request(
    "v2/site/list/my",
    "get",
    { Authorization: signToken },
    // {u_no : u_no}
    null
  );
};

export const alarmListApi = async (siteNo) => {
  return request(
    "v2/alarm/customer/list/" + siteNo,
    "get",
    { Authorization: signToken },
    null
  );
};

export const alarmCountApi = async (siteNo) => {
  return request(
    "v2/alarm/customer/count/" + siteNo,
    "get",
    { Authorization: signToken },
    null
  );
};

export const ordersDetailApi = async (ordersNo) => {
  return request(
    "v2/orders/detail/" + ordersNo,
    "get",
    { Authorization: signToken },
    null
  );
};

export const ordersModifyHistoryGroupApi = async (ordersGroupNo) => {
  return request(
    "v2/orders/group/" + ordersGroupNo,
    "get",
    { Authorization: signToken },
    null
  );
};

export const weatherShortApi = async (siteNo) => {
  return request(
    "v2/weather/" + siteNo,
    "get",
    { Authorization: signToken },
    null
  );
};

export const recommendProductApi = async (tagId) => {
  return request(
    "v2/recommed/" + tagId,
    "get",
    { Authorization: signToken },
    null
  );
};

export const productDetailApi = async (productNo) => {
  return request(
    "v2/product/detail/" + productNo,
    "get",
    { Authorization: signToken },
    null
  );
};

export const productShareListApi = async (siteNo, page) => {
  return request(
    "v2/product/shared/list/" + siteNo + "/" + page,
    "get",
    { Authorization: signToken },
    null
  );
};

export const productShareDetailApi = async (sharedItemNo) => {
  return request(
    "v2/product/shared/item/" + sharedItemNo,
    "get",
    { Authorization: signToken },
    null
  );
};

export const productProjectRenameApi = async (productSharedNo, name) => {
  return request(
    "v2/product/shared/rename",
    "post",
    { Authorization: signToken },
    {
      productSharedNo: productSharedNo,
      name: name,
    }
  );
};

export const productShareSaveApi = async (data) => {
  return request(
    "v2/product/shared/save",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

export const productDeleteApi = async (sharedItemNo) => {
  return request(
    "v2/product/shared/remove",
    "post",
    { Authorization: signToken },
    {
      productSharedNo: sharedItemNo,
    }
  );
};

export const historyListApi = async (data) => {
  return request(
    "v2/history/customer/list",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// ===========
// INVOICE API
// ===========

export const invoiceCountApi = async (siteNo) => {
  return request(
    "v2/invoice/customer/count/" + siteNo,
    "get",
    { Authorization: signToken },
    null
  );
};

export const invoiceListApi = async (siteNo, state) => {
  return request(
    "v2/invoice/customer/list/" + siteNo + "/" + state,
    "get",
    { Authorization: signToken },
    null
  );
};

export const invoiceListItemApi = async (invoiceNo) => {
  return request(
    "v2/invoice/customer/" + invoiceNo,
    "get",
    { Authorization: signToken },
    null
  );
};

export const invoiceExcelDownloadApi = async (invoiceNo) => {
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/v2/invoice/customer/excel/${invoiceNo}`,
    responseType: "blob",
    headers: {
      Authorization: signToken,
      "Content-Type": "application/octet-stream",
    },
  });

  return response;
};

export const taxInvoiceDownloadApi = async (invoiceNo, code) => {
  return request(
    "v2/invoice/customer/taxinvoice/" + invoiceNo + "/" + code,
    "get",
    { Authorization: signToken },
    null
  );
};

export const paymentRequestDownloadApi = async (invoiceNo, code) => {
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/v2/invoice/customer/payment-request/${invoiceNo}/${code}`,
    responseType: "blob",
    headers: {
      Authorization: signToken,
      "Content-Type": "application/octet-stream",
    },
  });

  return response;
};

export const eProFormDownloadApi = async (invoiceNo, code) => {
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/v2/invoice/customer/item-detail/${invoiceNo}/${code}`,
    responseType: "blob",
    headers: {
      Authorization: signToken,
      "Content-Type": "application/octet-stream",
    },
  });

  return response;
};

export const invoiceRejectApi = async (invoiceNo, rejectComment) => {
  return request(
    "v2/invoice/customer/reject",
    "post",
    { Authorization: signToken },
    {
      invoiceNo: invoiceNo,
      rejectComment: rejectComment,
    },
    null
  );
};

export const taxInvoiceRejectApi = async (invoiceNo, rejectComment) => {
  return request(
    "v2/invoice/customer/reject/taxinvoice",
    "post",
    { Authorization: signToken },
    {
      invoiceNo: invoiceNo,
      rejectComment: rejectComment,
    },
    null
  );
};

export const invoiceApproveApi = async (invoiceNo) => {
  return request(
    "v2/invoice/customer/approve/" + invoiceNo,
    "get",
    { Authorization: signToken },
    null
  );
};

export const invoiceCompleteApi = async (invoiceNo) => {
  return request(
    "v2/invoice/customer/payment/" + invoiceNo,
    "get",
    { Authorization: signToken },
    null
  );
};

export const updateInvoiceCodeApi = async (orderlistNo, code) => {
  return request(
    "v2/invoice/customer/edit/code/",
    "post",
    { Authorization: signToken },
    {
      orderlistNo: orderlistNo,
      taxCodeName: code,
    },
    null
  );
};

export const addInvoiceCodeApi = async (code) => {
  return request(
    "v2/invoice/customer/taxcode/add",
    "post",
    { Authorization: signToken },
    { taxCodeName: code },
    null
  );
};

export const fetchInvoicePreparationListApi = async (siteNo) => {
  return request(
    `v2/invoice/customer/preparation-list/${siteNo}`,
    "get",
    { Authorization: signToken },
    null
  );
};

export const requestInvoiceApi = async (siteNo, orderNoList) => {
  return request(
    "v2/invoice/customer/create",
    "post",
    { Authorization: signToken },
    {
      siteNo: siteNo,
      ordersNoList: orderNoList,
    }
  );
};

// =========================
// 회원가입 - 현장정보입력(기존현장)
// =========================
export const fetchSiteListApi = async (c_no, keyword) => {
  return request(
    `v2/site/list/construction/${c_no}/${keyword}`,
    "get",
    { Authorization: signToken },
    null
  );
};

export const fetchPoscoSiteListApi = async (keyword) => {
  return request(
    `v2/site/list/posco/${keyword}`,
    "get",
    { Authorization: signToken },
    null
  );
};

// ==================
// 메인화면 - 자재공동구매
// ==================
export const fetchGroupBuyingListApi = async () => {
  return request(
    "v2/group-buying/list/",
    "get",
    { Authorization: signToken },
    null
  );
};

// ==================
// 견적상세 - 질의응답 참여
// ==================
export const attendChat = async (data) => {
  return request(
    "v2/chat/room/attend",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// ==================
// 견적상세 - 상세보기
// ==================
export const estimateDetail2Api = async (bidNo) => {
  return request(
    "v2/bid/detail/" + bidNo,
    "get",
    { Authorization: signToken },
    null
  );
};

// ==================
// 지정업체주문 - 완료
// ==================
export const quickCreateApi = async (data) => {
  return request(
    "v2/quick/create",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

//견적요청 - 견적서 간편입력 (완료)25

export const quickValidateApi = async (data) => {
  return request(
    "v2/quick/validate",
    "post",
    { Authorization: signToken },
    { bidProductList: data }
  );
};

//포스코 계정 연동
export const poscoLoginApi = async (code) => {
  return request(
    "v2/poscoenc/login",
    "post",
    {},
    {
      code: code,
    }
  );
};

// 아찾비찾 - 인증번호 보내기
export const sendCodeApi = async (phone) => {
  return request(
    "v2/auth/send/code",
    "post",
    {},
    {
      userType: "S",
      phone: phone,
    }
  );
};
// 아찾비찾 - 인증번호 확인
export const verifyCodeApi = async (phone, code) => {
  return request(
    "v2/auth/verify/code",
    "post",
    {},
    {
      userType: "S",
      phone: phone,
      code: code,
    }
  );
};
// 아이디 찾기 - return ID
export const findIdApi = async (userNo) => {
  return request(
    "v2/auth/find/id",
    "post",
    {},
    {
      userNo: userNo,
    }
  );
};

//비밀번호 변경
export const changePwApi = async (userNo, password) => {
  return request(
    "v2/auth/change/password",
    "post",
    {},
    {
      userNo: userNo,
      password: password,
    }
  );
};

// 메세지 전송
export const messageSendApi = async (data) => {
  return request(
    "v2/chat/message/send",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// 샘플데이터 매핑
export const productRecommendListApi = async (no) => {
  return request(
    "v2/product/recommend/list/" + no,
    "get",
    { Authorization: signToken },
    null
  );
};

//
export const showOrderSheetApi = async (ordersNo) => {
  return request(
    "v2/orders/pdf/sheet/" + ordersNo,
    "get",
    { Authorization: signToken },
    null
  );
};

export const allHistoryOrderApi = async (data) => {
  return request(
    "v2/history/orderitem",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

export const historyPackageApi = async (keyword) => {
  return request(
    "v2/history/package/" + keyword,
    "get",
    { Authorization: signToken },
    null
  );
};

// 견적상세 > 투찰현황확인 > 리스트
export const fetchBidStatusListApi = async (bidNo) => {
  return request(
    `v2/bid-view/list/${bidNo}`,
    "get",
    { Authorization: signToken },
    null
  );
};

// 견적상세 > 투찰현황확인 > 투찰요청 알림발송
export const sendBidRequestMessageApi = async (data) => {
  return request(
    "v2/bid-view/send/message",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// 견적상세 > 유찰
export const bidFailApi = async (bidNo, reason) => {
  return request(
    "v2/bid/update/fail",
    "post",
    { Authorization: signToken },
    { bidNo: bidNo, reason: reason }
  );
};

// 견적상세 > 재공고 입찰
export const reBidApi = async (data) => {
  return request(
    "v2/bid/rebid",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// 주문관리 > 예비 정산내역 엑셀 다운로드
export const preInvoiceExcelDownApi = async (
  siteOrCompanyNo,
  state,
  startDate,
  endDate,
  filterSiteOrCompanyNo,
  keyword
) => {
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/v2/orders/detail/excel/${siteOrCompanyNo}/${state}/${startDate}/${endDate}/${filterSiteOrCompanyNo}/${keyword}`,
    responseType: "blob",
    headers: {
      Authorization: signToken,
      "Content-Type": "application/octet-stream",
    },
  });

  return response;
};

// 메인 > 통합 대시보드
// 견적상세 > 재공고 입찰
export const fetchDashboardInfoApi = async (data) => {
  return request(
    "v2/dashboard/info",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// 회원가입(협력사)
export const joinSubcontractorApi = async (data) => {
  return request(
    "api/v1/subcontractor/site",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

export const gunnyListApi = async () => {
  return request(
    "v2/pb/product/list",
    "get",
    { Authorization: signToken },
    null
  );
};

export const createPbOrder = async (data) => {
  return request(
    "v2/pb/create/orders",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

export const pbOrdersDetailApi = async (pbOrdersNo) => {
  return request(
    "v2/pb/orders/detail/" + pbOrdersNo,
    "get",
    { Authorization: signToken },
    null
  );
};

export const pbOrdersPdfApi = async (pbOrdersNo) => {
  return request(
    "v2/pb/pdf/sheet/" + pbOrdersNo,
    "get",
    { Authorization: signToken },
    null
  );
};

// PB 주문관리 리스트
export const fetchPbOrderListApi = async (data) => {
  return request(
    "v2/pb/orders/list",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

//PB 주문인 정보 가져오기
export const orderUserInfoApi = async (siteNo) => {
  return request(
    "v2/user/info/" + siteNo,
    "get",
    { Authorization: signToken },
    null
  );
};

//품목 자동완성 API
export const productSuggestApi = async (data) => {
  return request(
    "v2/product/suggest",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// 전체 검색 API
export const searchListApi = async (data) => {
  return request(
    "v2/search/",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// 잡자재 기준 안내 모달 검색
export const materialTableSearchApi = async (data) => {
  return request(
    "v2/material-category/list",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// 주문 상세 > 재주문 상태체크
export const checkReorderStateApi = async (data) => {
  return request(
    "v2/orders/check/reorder-state",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// [POST] 로그인
export const LoginApi = async (data) => {
  return request(
    "v2/auth/signin",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// [POST] 이메일 중복 검사
export const checkEmailApi = async (data) => {
  return request(
    "v2/auth/verification/email",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// [GET] 건설사 검색
export const searchConstructionApi = async (keyword) => {
  return request(
    `v2/construction/search/${keyword}`,
    "get",
    { Authorization: signToken },
    {}
  );
};

// [POST] 핸드폰 인증 요청
export const checkPhoneApi = async (data) => {
  return request(
    "v2/auth/verification/phone/send",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// [POST] 핸드폰 인증 확인
export const checkCodeApi = async (data) => {
  return request(
    "v2/auth/verification/phone/check",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// [POST] 회원가입
export const signupApi = async (data) => {
  return request(
    "v2/auth/signup",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// [POST] 건설사 정보
export const fetchOrgInfoApi = async (data) => {
  return request(
    "v2/auth/invite/code/info",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// [GET] 현장 정보
export const fetchSiteInfoApi = async (orgNo) => {
  return request(
    `v2/site/list/my/${orgNo}`,
    "get",
    { Authorization: signToken },
    {}
  );
};

// [GET] 마이페이지 정보
export const fetchMyPageInfoApi = async () => {
  return request(`v2/user/my-page`, "get", { Authorization: signToken }, {});
};

// [POST] 마이페이지 > 회원탈퇴
export const withdrawApi = async (data) => {
  return request(
    "v2/user/withdraw",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// [POST] 마이페이지 > 직급변경
export const updateRankApi = async (data) => {
  return request(
    "v2/user/update/rank",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// [POST] 마이페이지 > 비밀번호 변경
export const updatePasswordApi = async (data) => {
  return request(
    "v2/user/update/password",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// [GET] 마이페이지 > 환경전환
export const switchTypeApi = async (isDemo) => {
  return request(
    `v2/site/switch/demo/${isDemo}`,
    "get",
    { Authorization: signToken },
    {}
  );
};

// [POST] 건설사 등록
export const createConstructionApi = async (data) => {
  return request(
    "v2/construction/create",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// [POST] 파일 업로드
export const fileUploadApi = async (formData) => {
  return request(
    "v2/file/upload",
    "post",
    { Authorization: signToken },
    formData
  );
};

export const esgDashboardApi = async (year, scope) => {
  return request(
    `v2/esg/dashboard/${year}/${scope}`,
    "get",
    { Authorization: signToken },
    null
  );
};

export const esgGreenHouseTableApi = async (year) => {
  return request(
    `v2/esg/table/greenhouse-gas/${year}`,
    "get",
    { Authorization: signToken },
    null
  );
};

export const esgWasteTableApi = async (year) => {
  return request(
    `v2/esg/table/waste/${year}`,
    "get",
    { Authorization: signToken },
    null
  );
};

export const esgUploadSheet = async (formData) => {
  return request(
    `v2/esg/upload/sheet`,
    "post",
    { Authorization: signToken },
    formData
  );
};

// [POST] 현장 등록
export const createSiteApi = async (data) => {
  return request(
    "v2/site/create",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// [POST] 현장 수정
export const updateSiteApi = async (data) => {
  return request(
    "v2/site/update/request",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// [GET] 현장 검색
export const searchSiteApi = async (isEntered, keyword) => {
  return request(
    `v2/site/list/${isEntered}/${keyword}`,
    "get",
    { Authorization: signToken },
    {}
  );
};

// [POST] 현장 참여
export const attendSiteApi = async (data) => {
  return request(
    "v2/site/entry",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// [POST] 현장 참여 - 승인, 거절
export const attendSiteApproveApi = async (data) => {
  return request(
    "v2/site/user/approve",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// [GET] 참여자 검색
export const searchUserApi = async (keyword) => {
  return request(
    `v2/site/invite/user/list/${keyword}`,
    "get",
    { Authorization: signToken },
    {}
  );
};

// [POST] 현장 초대
export const InviteSiteApi = async (data) => {
  return request(
    `v2/site/invite/user`,
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// [POST] 현장 나가기
export const LeaveSiteApi = async (data) => {
  return request(
    `v2/site/leave`,
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// [POST] 현장 초대 - 메일
export const InviteMailApi = async (data) => {
  return request(
    `v2/site/invite/mail`,
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// [GET] organization state 받기
export const fetchOrgStateApi = async () => {
  return request(
    `v2/auth/organization/state`,
    "get",
    { Authorization: signToken },
    {}
  );
};

// [POST] 본인인증 auth code 전송
export const verifyAuthCode = async (data) => {
  return request(
    `v2/auth/verification/identity`,
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// [POST] 현장 전환
export const switchSiteApi = async (data) => {
  return request(
    `v2/site/change`,
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

export const esgDownloadSheet = async (year) => {
  return request(
    `v2/esg/download/sheet/${year}`,
    "get",
    { Authorization: signToken },
    null
  );
};

export const esgEmptyDownloadSheet = async () => {
  return request(
    `v2/esg/download/sheet/empty`,
    "get",
    { Authorization: signToken },
    ""
  );
};
