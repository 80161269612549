import React, { useState } from "react";
import Typography from "../../../../component/ui/typography/Typography"; // Assuming Typography is imported from a custom component
import Modal from "../../../../component/ui/modal/Modal"; // Assuming Typography is imported from a custom component
import Button from "../../../../component/ui/button/Button"; // Assuming Typography is imported from a custom component
import { useNavigate } from "react-router-dom";
import { estimateCancelApi } from "../../../../api/auth";

const CancelBidModal = ({
  cancelBidOpen,
  openCancelBidModal,
  closeCancelBidModal,
  estimateDetailFnc2,
  bidNo,
}) => {
  const navigate = useNavigate();

  const [reasonComment, setReasonComment] = useState("");
  const handleCommentChange = (e) => {
    const value = e.target.value;

    if (value.length <= 300) {
      setReasonComment(value);
    } else {
      const truncatedText = value.substring(0, 300);
      setReasonComment(truncatedText);
    }
  };

  const [alertOpen, setAlertOpen] = useState(false);
  const openAlertModal = () => setAlertOpen(true);
  const closeAlertModal = () => setAlertOpen(false);

  const validateCancelBid = () => {
    if (reasonComment === "") {
      return openAlertModal();
    } else {
      bidCancelFnc();
    }
  };

  const [completeOpen, setCompleteOpen] = useState(false);
  const openCompleteModal = () => setCompleteOpen(true);
  const closeCompleteModal = () => setCompleteOpen(false);

  // 입찰 취소
  const bidCancelFnc = async () => {
    try {
      await estimateCancelApi(bidNo, reasonComment);
      // 얼럿띄우기
      openCompleteModal();
      estimateDetailFnc2();
    } catch (error) {
      console.error(error);
    }
  };

  const closeFnc = async () => {
    // 모달 끄기
    closeCancelBidModal();
    closeCompleteModal();
  };

  return (
    <>
      <Modal
        size="small"
        type="warning"
        open={cancelBidOpen}
        title={
          <Typography component="p" type="h4" color="var(--sub-yellow-10)">
            공고 취소
          </Typography>
        }
        closeIcon={false}
        onOk={validateCancelBid}
        onCancel={openCancelBidModal}
        footer={[
          <Button type="danger" onClick={closeCancelBidModal}>
            취소
          </Button>,
          <Button type="primary" onClick={validateCancelBid}>
            확인
          </Button>,
        ]}
      >
        <div className="re_bid_modal_container">
          <div className="re_bid_modal_sub_title">
            <Typography component="p" type="c2">
              일방적인 공고 취소 및 중단에 따른 패널티가 발생할 수 있습니다.
            </Typography>
            <Typography component="p" type="c2">
              공고 취소 및 중단 시 상호간의 협의 후 진행해주세요.
            </Typography>
            <Typography component="p" type="c2">
              공고를 취소하시려면 사유를 입력 후, 확인을 눌러주세요.
            </Typography>
          </div>
        </div>
        <div className="re_bid_modal_text_container">
          <Typography component="p" type="h5">
            사유입력
          </Typography>

          <div
            className="re_bid_modal_textarea_container textarea_div"
            spellCheck="false"
          >
            <textarea
              placeholder="추가 설명이 있으시다면 작성해주세요."
              onChange={handleCommentChange}
              value={reasonComment}
            ></textarea>
            <div className="add_text_alert_container">
              <span className="add_alert_desc">
                * 300자 이내로 작성해 주세요.
              </span>
              <span className="add_alert_typing">
                {reasonComment.length} / 300
              </span>
            </div>
          </div>
        </div>
      </Modal>
      {alertOpen && (
        <Modal
          size="xsmall"
          type="warning"
          open={alertOpen}
          closeIcon={false}
          title={
            <Typography component="p" type="h4" color="var(--sub-yellow-10)">
              취소 사유를 입력해주세요.
            </Typography>
          }
          onOk={closeAlertModal}
          onCancel={closeAlertModal}
          footer={[
            <Button type="primary" onClick={closeAlertModal}>
              확인
            </Button>,
          ]}
        >
          <Typography component="p" type="c2" color="var(--sub-gray-09)">
            취소 사유를 입력해주시기 바랍니다.
          </Typography>
        </Modal>
      )}

      {completeOpen && (
        <Modal
          size="xsmall"
          type="success"
          open={completeOpen}
          closeIcon={false}
          title={
            <Typography component="p" type="h2" color="var(--sub-blue-05)">
              취소 완료
            </Typography>
          }
          onOk={closeFnc}
          onCancel={closeCompleteModal}
          footer={[
            <Button type="primary" onClick={closeFnc}>
              확인
            </Button>,
          ]}
        >
          <Typography component="p" type="h4" color="var(--sub-gray-90)">
            본 입찰 건이 아래 사유로 취소되었습니다.
          </Typography>
          <br />
          <Typography component="p" type="c2" color="var(--sub-gray-09)">
            {reasonComment}
          </Typography>
        </Modal>
      )}
    </>
  );
};

export default CancelBidModal;
