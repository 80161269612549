import React, { useState, useEffect, useRef } from "react";
//library
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router";
import moment from "moment";

import SockJs from "sockjs-client";
import StompJs from "stompjs";

//redux
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../redux/action";

//component
import Mheader from "../../component/common/Mheader";
import ChatWhoPop from "../../component/common/Pop/ChatWhoPop";
import ChatPop from "../../component/common/Pop/ChatPop";
import ChatImgPop from "../../component/common/Pop/ChatImgPop";
import ChatFilePop from "../../component/common/Pop/ChatFilePop";
import ChatInvitaionPop from "../../component/common/Pop/ChatInvitaionPop";

//css
import "../../asset/css/qna.css";

//img
import i_close from "../../asset/images/w-cancle-bt.png";

//api
import {
  chatRoomList,
  chatRoomInfo,
  chatMessageList,
  messageSendApi,
} from "../../api/auth";

//etc
import i_menu from "../../asset/images/m-menu-icon.png";
import i_add from "../../asset/images/c-plus-icon.png";

//infinite scroll
import { useInView } from "react-intersection-observer";

//sweet alert
import swal from "sweetalert";

export default function QnA() {
  const [test, setTest] = useState(true);
  const CHAT_URL = process.env.REACT_APP_CHAT_URL;
  const userData = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [chatIsOn, setChatIsOn] = useState(false);

  //web
  const location = useLocation();

  //redux
  // const socket = useSelector((state) => state.chat);
  const dispatch = useDispatch();

  //flag
  // const [isFromBid, setFromBid] = useState(false);

  //socket
  // const [isSetup, setSetup] = useState(false);

  //data
  const [roomList, setRoomList] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(false);
  const [partInfo, setPartInfo] = useState([]);
  const [roomInfo, setRoomInfo] = useState({});

  const [messageList, setMessageList] = useState([]);
  const [messageState, setMessageState] = useState({
    payload: {},
    sender: {
      user_no: userData.u_no,
      type: "site",
      site_no: userData.site_num,
    },
  });
  //view
  const [openChatView, setOpenChatView] = useState(false);

  //index
  const [roomPageIndex, setRoomPageIndex] = useState(1);

  //popup 참여인원 팝업
  const [whoPop, setWhoPop] = useState(false);
  // add 팝업
  const [chatPop, setChatPop] = useState(false);
  // img 첨부
  const [imgPop, setImgPop] = useState(false);
  // 파일 첨부
  const [filePop, setFilePop] = useState(false);
  // 유저 초대
  const [userPop, setUserPop] = useState(false);

  //chatView
  const [fullImage, setFullImage] = useState("");
  const [imageOpen, setImageOpen] = useState(false);
  const [bottom, setBottom] = useState(true);

  const prevScrollY = useRef(0);
  const [goingUp, setGoingUp] = useState(false);
  const [currentScroll, setCurrentScroll] = useState(-1);
  const topMessageItem = useRef(0);

  const [ref, inView] = useInView();
  const [loading, setLoading] = useState(false);

  let stomp = useRef(); //useref를 통해 리렌더링이 되어도 값을 갱신하지 않게

  const connectServer = () => {
    // const test = new SockJs

    stomp.current.reconnect_delay = 10000;

    stomp.current.connect(
      {
        authorization: token,
      },
      function (frame) {},
      function (error) {}
    );
  };

  useEffect(() => {
    // let sock = new SockJs("http://192.168.238.79:9100/stomp");
    let sock = new SockJs(`${CHAT_URL}/stomp`);
    stomp.current = StompJs.over(sock);

    connectServer();
    return () => {
      if (stomp.current?.connected) {
        stomp.current.disconnect(function () {});
      }
    };
  }, []);

  const onScroll = (e) => {
    setBottom(false);
    const currentScrollY = e.target.scrollTop;
    if (prevScrollY.current < currentScrollY && goingUp) {
      setGoingUp(false);
    }
    if (prevScrollY.current > currentScrollY && !goingUp) {
      setGoingUp(true);
    }
    prevScrollY.current = currentScrollY;
    setCurrentScroll(currentScrollY);
  };

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => {
      if (bottom) {
        elementRef.current.scrollIntoView();
        window.scrollTo({ top: 200 });
      }
    }, [bottom]);
    return <div ref={elementRef} />;
  };

  // maxwidth 구분
  const isResponsive = useMediaQuery({ query: "(max-width : 1080px)" });

  //api
  const _chatRoomList = async (type) => {
    const data = {
      type: "site",
      typeNo: userData.site_num,
      page: roomPageIndex,
    };

    setLoading(true);
    const response = await chatRoomList(data);

    if (response.status === "success") {
      if (type === undefined || type !== "reload") {
        setRoomList([...roomList, ...response.data]);
      } else {
        setRoomList([...response.data]);
      }
    }

    setLoading(false);
  };
  //moon update
  useEffect(() => {
    // 사용자가 마지막 요소를 보고 있고, 로딩 중이 아니라면
    if (inView && !loading) {
      setRoomPageIndex(roomPageIndex + 1);
    }
  }, [inView]);

  const _chatPartInfo = async (roomNo) => {
    const response = await chatRoomInfo(roomNo);
    if (response.status === "success") {
      setPartInfo(response.data);
    }
  };

  const _refreshChatList = async () => {
    await _chatRoomList("reload");
  };

  const _chatMessageList = async (reset) => {
    const data = {
      roomNo: selectedRoom,
      // page: reset ? 1 : messagePageIndex,
    };

    const response = await chatMessageList(data);

    if (response.status === "success") {
      const topItem = topMessageItem;

      let currentList = messageList;
      // setMessageList(response.data.concat(currentList).reverse());
      setMessageList(response.data.reverse());

      // if (topItem.current) {
      //   topItem.current.scrollIntoView();
      // }
    }
  };

  //chat
  const sendFileMessage = (type, path, name) => {
    // if (isSetup) {
    const payload = { type: type, value: path, name: name ? name : "" };

    const data = {
      roomNo: selectedRoom,
      userNo: userData.u_no,
      senderType: "site",
      senderTypeNo: userData.site_num,
      type: type,
      value: payload.value,
      createdDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    };

    messageSendFnc(data);
    // stomp.current.send("/pub/chat/send", {}, JSON.stringify(data));

    setMessageState({
      ...messageState,
      payload: {},
    });
    _refreshChatList();
    setBottom(true);
    // } else {
    // }
  };

  const [typingMessage, setTypingMessage] = useState("");
  const sendMessageFnc = (event) => {
    // if (isSetup) {
    if (!stomp.current?.connected) {
      swal(
        "채팅 서버와의 연결이 원활하지 않습니다.",
        "서버를 다시 연결합니다.",
        "error"
      );
      window.location.reload();
    }
    stomp.current.debug = null;
    // var messageContent = messageInput.value.trim();

    let finalValue = typingMessage;
    if (finalValue === "" || finalValue === undefined) {
      swal("메세지를 입력해주세요.", "", "warning");
      return false;
    } else if (finalValue.includes("\n")) {
      finalValue = finalValue.replace(/\n/gi, " ");
    } else if (finalValue.includes('"') || finalValue.includes("'")) {
      finalValue = finalValue.replaceAll(/\"/gi, "‟");
      finalValue = finalValue.replaceAll(/\'/gi, "‛");
    }

    // const data = {
    //   roomNo: selectedRoom,
    //   userNo: userData.u_no,
    //   senderType: "site",
    //   senderTypeNo: userData.site_num,
    //   type: "message",
    //   value: finalValue,
    // };

    const data = {
      roomNo: selectedRoom,
      userNo: userData.u_no,
      senderType: "site",
      senderTypeNo: userData.site_num,
      type: "message",
      value: finalValue,
      createdDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    };

    // stomp.current.send("/pub/chat/send", {}, JSON.stringify(data));
    messageSendFnc(data);

    setMessageState({
      ...messageState,
      payload: {},
    });
    _refreshChatList();
    setBottom(true);
    // }
  };

  const messageSendFnc = async (data) => {
    const response = await messageSendApi(data);
    if (response.status === "success") {
      _chatMessageList();
      // 메세지 보내면, messagelist에 추가?
    }
  };

  //lifecycle
  // useEffect(() => {}, [messageList]);

  useEffect(() => {
    _chatRoomList();
  }, [roomPageIndex]);

  useEffect(() => {
    const action = async () => {
      // unsubscribe?
      if (stomp.current?.connected) {
        stomp.current.unsubscribe();
      }

      if (selectedRoom !== false) {
        // setSetup(false);
        if (!location?.state?.bidRoomNo) {
          setOpenChatView(false);
        }

        //1. 초기화
        setMessageList([]);
        setMessageState({
          ...messageState,
          room_no: selectedRoom,
          payload: {},
        });

        //2. 방 정보 불러오기
        await _chatPartInfo(selectedRoom);

        //3. 메세지 불러오기
        // 방고르기
        await _chatMessageList(true);
      }
    };

    action();
  }, [selectedRoom]);

  const [currentMessage, setCurrentMessage] = useState(false);

  var subscribeOnce = useRef(false);
  //

  // const subscribeFnc = async() =>{

  // }

  useEffect(() => {
    subscribeFnc();
  }, [messageList]);

  const subscribeFnc = () => {
    if (
      messageList.length > 0 &&
      stomp.current?.connected &&
      subscribeOnce.current === false
    ) {
      subscribeOnce.current = true;
      stomp.current.subscribe(
        `/sub/chat/room/${selectedRoom}`,

        function (data) {
          // setMessageList((messageList) => [
          //   ...messageList,
          //   JSON.parse(data.body),
          // ]);
          _chatMessageList();
        }
      );

      _refreshChatList();
      setBottom(true);
      // setSetup(true);
      setOpenChatView(true);
    }
  };

  useEffect(() => {}, [messageList]);

  useEffect(() => {
    connectServer();
  }, [selectedRoom]);

  useEffect(() => {
    if (
      // location.state !== undefined &&
      // location.state?.bidRoomNo !== undefined &&
      location?.state?.bidRoomNo
    ) {
      setSelectedRoom(location?.state?.bidRoomNo);
      setRoomInfo(location?.state?.selectedChatItem);
      // 질의응답 바로가기로 들어온 경우
      setOpenChatView(true);
      // setFromBid(true);
    }
  }, []);

  // const _updatePage = () => {
  //   setMessagePageIndex(messagePageIndex + 1);
  // };

  //엔터키 이벤트
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (messageState.payload.value !== "") {
        sendMessageFnc();
      }
    }
  };

  return (
    <div className="qna_wrap">
      <Mheader title="질의응답" />
      <div className="qna_container wd_s" style={{ paddingTop: "30px" }}>
        <div className="top_head">
          <h2>질의응답</h2>
          <div className="top_text">
            <p>
              *자유로운 대화를 통해 입찰/계약/주문까지 스마트하게 진행하세요.
            </p>
            <p>
              *유저초대를 통해 같은 현장 관리자를 초대하여 업무 효율을
              높여보세요
            </p>
            <p>*입찰이 완료된 채팅방은 대화가 더이상 불가능합니다. </p>
          </div>
        </div>
        <div className="chat_wrap">
          <ul className="chat_list">
            {roomList.map(function (data, i) {
              const dueTime = moment(data.bid.endate, "YYYY-MM-DD HH:mm:ss");
              const updateTime = moment(
                data.chatRoom.updatedDate,
                "YYYY-MM-DD HH:mm:ss"
              );
              const currentTime = moment();

              const duePeriod = {
                day: moment.duration(currentTime.diff(dueTime)).days(), //cuttenttime=현재, dueTime=생성시간 일/시/분/초 비교
                hour: moment.duration(currentTime.diff(dueTime)).hours(),
                minute: moment.duration(currentTime.diff(dueTime)).minutes(),
                second: moment.duration(currentTime.diff(dueTime)).seconds(),
              };

              let dueText = "";
              if (duePeriod.day === 0) {
                if (duePeriod.hour === 0) {
                  if (duePeriod.minute === 0) {
                    if (duePeriod.second > 0) {
                      dueText = duePeriod.second + "초 지남";
                    } else {
                      dueText = -1 * duePeriod.second + "초 남음";
                    }
                  } else {
                    if (duePeriod.minute > 0) {
                      dueText = duePeriod.minute + "분 지남";
                    } else {
                      dueText = -1 * duePeriod.minute + "분 남음";
                    }
                  }
                } else {
                  if (duePeriod.hour > 0) {
                    dueText =
                      duePeriod.hour +
                      "시간" +
                      -1 * duePeriod.minute +
                      " 분 지남";
                  } else {
                    dueText =
                      -1 * duePeriod.hour +
                      "시간" +
                      -1 * duePeriod.minute +
                      "분 남음";
                  }
                }
              } else {
                if (duePeriod.day > 0) {
                  dueText = duePeriod.day + "일 지남";
                } else {
                  dueText = -1 * duePeriod.day + "일 남음";
                }
              }

              const updatePeriod = {
                day: moment.duration(currentTime.diff(updateTime)).days(),
                hour: moment.duration(currentTime.diff(updateTime)).hours(),
                minute: moment.duration(currentTime.diff(updateTime)).minutes(),
                second: moment.duration(currentTime.diff(updateTime)).seconds(),
              };
              let updateText = "";
              if (updatePeriod.day == 0) {
                if (updatePeriod.hour == 0) {
                  if (updatePeriod.minute == 0) {
                    if (updatePeriod.second < 30) {
                      updateText = "방금 전";
                    } else {
                      updateText = updatePeriod.second + "초 전";
                    }
                  } else {
                    updateText = updatePeriod.minute + "분 전";
                  }
                } else {
                  updateText = updatePeriod.hour + "시간 전";
                }
              } else {
                updateText = updatePeriod.day + "일 전";
              }

              return roomList.length - 1 === i ? (
                <li
                  key={i}
                  className={data.chatRoom.no === selectedRoom ? "now" : ""}
                  onClick={() => {
                    // if (socket.connected && selectedRoom !== false) {
                    //   if(data.chatRoom.no === selectedRoom){
                    //     return false;
                    //   }
                    //   socket.emit('leaveRoom', { room_no: selectedRoom });
                    // }
                    connectServer();
                    setMessageList([]);
                    setPartInfo([]);
                    setRoomInfo({});
                    setSelectedRoom(data.chatRoom.no);
                    setRoomInfo(data);
                    setRoomPageIndex(1);
                    setBottom(true);
                    subscribeOnce.current = false;
                  }}
                  ref={ref}
                >
                  <div className="chat_top">
                    <div className="chat_box">
                      <div className="chat_img">
                        <img
                          src={data.constructionLogo}
                          alt={data.constructionName}
                        />
                      </div>
                      <div className="chat_text">
                        <h4>
                          [{data.constructionName}]{data.siteName}
                        </h4>
                        <p>
                          {data.bid.type === "S"
                            ? "단일건 계약"
                            : `단가계약 (${data.bid.term}개월)`}
                        </p>
                      </div>
                    </div>
                    <p className="chat_time">{updateText}</p>
                  </div>
                  <div className="chat_state">
                    <p>
                      상태 : {data.bid.state === "ALIVE" ? "진행중" : "마감"}
                    </p>
                    <p>입찰번호 : {data.bid.code}</p>
                    <p>입찰참여업체 : {data.offerCount}개사</p>
                    {data.bid.state === "ALIVE" && (
                      <p>남은 투찰시간 : {dueText}</p>
                    )}
                  </div>
                </li>
              ) : (
                <li
                  key={i}
                  className={data.chatRoom.no === selectedRoom ? "now" : ""}
                  onClick={() => {
                    // if (socket.connected && selectedRoom !== false) {
                    if (data.chatRoom.no === selectedRoom) {
                      return false;
                    }
                    //     socket.emit('leaveRoom', { room_no: selectedRoom });
                    // }
                    // stomp.current.unsubscribe();
                    connectServer();
                    setMessageList([]);
                    setPartInfo([]);
                    setRoomInfo({});
                    setSelectedRoom(data.chatRoom.no);
                    setRoomInfo(data);
                    setRoomPageIndex(1); //mesage무한 스크롤 관련
                    setBottom(true); //아래로 내리기
                    // subscribeFnc();

                    subscribeOnce.current = false;
                  }}
                >
                  <div className="chat_top">
                    <div className="chat_box">
                      <div className="chat_img">
                        <img
                          src={data.constructionLogo}
                          alt={data.constructionName}
                        />
                      </div>
                      <div className="chat_text">
                        <h4>
                          [{data.constructionName}]{data.siteName}
                        </h4>
                        <p>
                          {data.bid.type === "S"
                            ? "단일건 계약"
                            : `단가계약 (${data.bid.term}개월)`}
                        </p>
                      </div>
                    </div>
                    <p className="chat_time">{updateText}</p>
                  </div>
                  <div className="chat_state">
                    <p>
                      상태 : {data.bid.state === "ALIVE" ? "진행중" : "마감"}
                    </p>
                    <p>입찰번호 : {data.bid.code}</p>
                    <p>입찰참여업체 : {data.bid.offerCount}개사</p>
                    {data.bid.state === "ALIVE" && (
                      <p>남은 투찰시간 : {dueText}</p>
                    )}
                  </div>
                </li>
              );
            })}
            <span ref={ref}></span>
          </ul>
          {!isResponsive && !selectedRoom && <p>채팅 창을 선택해주세요</p>}
          {openChatView && (
            <div className="chatting">
              <Mheader
                isChat={true}
                title={`[${roomInfo.constructionName}]${roomInfo.siteName}`}
              />{" "}
              {partInfo && messageList && roomInfo ? (
                <>
                  {" "}
                  <div className="chat_area">
                    {partInfo.length > 0 &&
                      messageList.map((item, i) => {
                        let isTop = false;
                        if (i === 0) {
                          isTop = true;
                        }
                        const registered = moment(
                          item.createdDate,
                          "YYYY-MM-DD HH:mm:ss"
                        );
                        if (item.type === "system") {
                          return (
                            <div className="chat_date_line" key={i}>
                              <span>{item.value}</span>
                            </div>
                          );
                        } else if (item.senderType === "admin") {
                          return (
                            <>
                              <div
                                className="chat_box"
                                key={i}
                                ref={isTop ? topMessageItem : null}
                              >
                                <div className="chat_right">
                                  <div className="chat_text">
                                    <h4>{"공새로 관리자"}</h4>
                                    {/* <p>
                                                {partData.name} |{' '}
                                                {partData.role}
                                              </p> */}
                                  </div>
                                  <div className="chat_post_box">
                                    <div className="chat_post_text">
                                      {item.type === "image" && (
                                        <div
                                          className="chat_img"
                                          onClick={() => {
                                            setFullImage(item.value);
                                            setImageOpen(true);
                                          }}
                                        >
                                          <img src={item.value} />
                                        </div>
                                      )}
                                      {item.type === "message" && (
                                        <div
                                          className="chat_post"
                                          style={{ background: "#00286360" }}
                                        >
                                          <p>{item.value}</p>
                                        </div>
                                      )}

                                      {item.type === "file" && (
                                        <div
                                          className="chat_post"
                                          style={{ background: "#00286360" }}
                                        >
                                          <p>
                                            <a
                                              style={{
                                                color: "#002863",
                                              }}
                                              href={item.value}
                                              target="_blank"
                                            >
                                              <b>
                                                {item.name
                                                  ? item.name
                                                  : "파일 다운로드"}
                                              </b>
                                            </a>
                                          </p>
                                        </div>
                                      )}
                                      <span>
                                        {registered.format("YYYY.MM.DD")}
                                        <br />
                                        {registered.format("HH:mm")}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        } else {
                          if (item.userNo == userData.u_no) {
                            //mine
                            return (
                              <div
                                className="my_chat_box"
                                key={i}
                                ref={isTop ? topMessageItem : null}
                              >
                                <div className="chat_post_text">
                                  <span>
                                    {registered.format("YYYY.MM.DD")}
                                    <br /> {registered.format("HH:mm")}
                                  </span>
                                  {item.type === "image" && (
                                    <div
                                      className="chat_img"
                                      onClick={() => {
                                        setFullImage(item.value);
                                        setImageOpen(true);
                                      }}
                                    >
                                      <img src={item.value} />
                                    </div>
                                  )}
                                  {item.type === "message" && (
                                    <div className="chat_post">
                                      <p>{item.value}</p>
                                    </div>
                                  )}

                                  {item.type === "file" && (
                                    <div className="chat_post">
                                      <p>
                                        <a
                                          style={{
                                            color: "#002863",
                                          }}
                                          href={item.value}
                                          target="_blank"
                                        >
                                          <b>
                                            {item.name
                                              ? item.name
                                              : "파일 다운로드"}
                                          </b>
                                        </a>
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          } else {
                            //others
                            const partIndex = partInfo.findIndex(function (
                              user,
                              i
                            ) {
                              return user.userNo === item.userNo;
                            });
                            // if ((partIndex === -1 )) {
                            //   _chatPartInfo(selectedRoom);
                            // }
                            const partData = partInfo[partIndex];

                            return (
                              <>
                                {partIndex !== -1 && (
                                  <>
                                    <div
                                      className="chat_box"
                                      key={i}
                                      ref={isTop ? topMessageItem : null}
                                    >
                                      <div className="chat_img">
                                        <img
                                          src={partData.typeLogo}
                                          alt={
                                            partData.name + " " + partData.rank
                                          }
                                        />
                                      </div>
                                      <div className="chat_right">
                                        <div className="chat_text">
                                          <h4>{partData.typeName}</h4>
                                          <p>
                                            {partData.name}| {partData.rank}
                                          </p>
                                        </div>
                                        <div className="chat_post_box">
                                          <div className="chat_post_text">
                                            {item.type === "image" && (
                                              <div
                                                className="chat_img"
                                                onClick={() => {
                                                  setFullImage(item.value);
                                                  setImageOpen(true);
                                                }}
                                              >
                                                <img src={item.value} />
                                              </div>
                                            )}
                                            {item.type === "message" && (
                                              <div className="chat_post">
                                                <p>{item.value}</p>
                                              </div>
                                            )}

                                            {item.type === "file" && (
                                              <div className="chat_post">
                                                <p>
                                                  <a
                                                    style={{
                                                      color: "#002863",
                                                    }}
                                                    href={item.value}
                                                    target="_blank"
                                                  >
                                                    <b>
                                                      {item.name
                                                        ? item.name
                                                        : "파일 다운로드"}
                                                    </b>
                                                  </a>
                                                </p>
                                              </div>
                                            )}
                                            <span>
                                              {registered.format("YYYY.MM.DD")}
                                              <br />{" "}
                                              {registered.format("HH:mm")}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            );
                          }
                        }
                      })}
                    <AlwaysScrollToBottom />
                  </div>
                  {fullImage !== "" && imageOpen && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        zIndex: 999,
                        width: "100%",
                        height: "100%",
                        background: "#212121",
                      }}
                      onClick={() => {
                        setFullImage("");
                        setImageOpen(false);
                      }}
                    >
                      <div
                        className="close"
                        onClick={() => {
                          setFullImage("");
                          setImageOpen(false);
                        }}
                      >
                        <img src={i_close} alt="" />
                      </div>
                      <img
                        src={fullImage}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  )}{" "}
                </>
              ) : (
                <div>웹 페이지를 새로고침해주세요.</div>
              )}
              {roomInfo && roomInfo.bid.state === "ALIVE" ? (
                <div className="chat_input">
                  <div className="chat_menu" onClick={() => setWhoPop(true)}>
                    <img src={i_menu} alt="" />{" "}
                    {whoPop && isResponsive === false && (
                      <ChatWhoPop setWhoPop={setWhoPop} partInfo={partInfo} />
                    )}
                  </div>
                  <div
                    className="chat_add"
                    onClick={() => {
                      if (roomInfo && roomInfo.bid.state === "ALIVE") {
                        setChatPop(true);
                      }
                    }}
                  >
                    <img src={i_add} alt="" />
                  </div>
                  <textarea
                    type="text"
                    value={
                      messageState.payload.type === "message" &&
                      messageState.payload.value
                        ? messageState.payload.value
                        : ""
                    }
                    onKeyPress={onKeyPress}
                    disabled={roomInfo && roomInfo.bid.state !== "ALIVE"}
                    placeholder={
                      roomInfo && roomInfo.bid.state !== "ALIVE"
                        ? "마감된 입찰건은 더이상 대화를 나눌 수 없습니다."
                        : "메세지를 입력하세요 (Enter:전송)"
                    }
                    onChange={(e) => {
                      if (roomInfo && roomInfo.bid.state === "ALIVE") {
                        setTypingMessage(e.target.value);
                        setMessageState({
                          ...messageState,
                          payload: {
                            type: "message",
                            value: e.target.value,
                          },
                        });
                      }
                    }}
                    rows={1}
                    maxLength={1000}
                  />
                  <div
                    className="chat_send"
                    onClick={() => {
                      if (roomInfo && roomInfo.bid.state === "ALIVE") {
                        sendMessageFnc();
                        // setTest(!test);

                        // testFnc();
                      }
                    }}
                  >
                    <p>전송</p>
                  </div>
                </div>
              ) : (
                <div className="chat_input">
                  <div className="chat_menu" onClick={() => setWhoPop(true)}>
                    <img src={i_menu} alt="" />{" "}
                    {whoPop && isResponsive === false && (
                      <ChatWhoPop setWhoPop={setWhoPop} partInfo={partInfo} />
                    )}
                  </div>
                  <div className="is_disable">
                    마감된 입찰건은 더이상 대화를 나눌 수 없습니다.
                  </div>
                </div>
              )}
              {whoPop && isResponsive === false && (
                <div
                  className="pop_dim who_pop_dim"
                  onClick={() => setWhoPop(false)}
                ></div>
              )}
              {whoPop && isResponsive && (
                <ChatWhoPop setWhoPop={setWhoPop} partInfo={partInfo} />
              )}
              {chatPop && (
                <ChatPop
                  setChatPop={setChatPop}
                  setImgPop={setImgPop}
                  setFilePop={setFilePop}
                  setUserPop={setUserPop}
                />
              )}
              {imgPop && (
                <ChatImgPop
                  setImgPop={setImgPop}
                  roomNo={selectedRoom}
                  afterUpload={sendFileMessage}
                />
              )}
              {filePop && (
                <ChatFilePop
                  setFilePop={setFilePop}
                  roomNo={selectedRoom}
                  afterUpload={sendFileMessage}
                />
              )}
              {userPop && (
                <ChatInvitaionPop
                  updatePartInfo={_chatPartInfo}
                  setUserPop={setUserPop}
                  roomNo={selectedRoom}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
