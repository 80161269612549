import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Typography from "../ui/typography/Typography"; // Assuming Typography is from Material-UI
import { COLOR } from "../../constant/Palette"; // Assuming you have a color constant file

const EsgDashboardTabs = ({ activeMenu, page }) => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const mainTabs = [
    { key: "main", label: "통합 대시보드", path: "/" },
    { key: "ai", label: "AI 데이터 추천", path: "/ai_data_recommend" },
    { key: "esg", label: "ESG 대시보드", path: "/esg_dashboard" },
  ];

  const filteredMainTabs = user.showEsg
    ? mainTabs
    : mainTabs.filter((tab) => tab.key !== "esg");

  const managementTabs = [
    {
      key: "greenhouse",
      label: "온실가스",
      path: "/esg_management/greenhouse",
    },
    { key: "waste", label: "폐기물", path: "/esg_management/waste" },
    {
      key: "dashboard",
      label: "ESG 대시보드",
      path: "/esg_management/dashboard",
    },
  ];

  const TabButton = ({ isActive, label, path }) => {
    return (
      <button
        className={isActive ? "active_dashboard_tab" : "unactive_dashboard_tab"}
        onClick={() => navigate(path, { replace: true })}
      >
        <Typography
          component="p"
          type={isActive ? "c1" : "c2"}
          color={COLOR.PRIMARY.L08}
        >
          {label}
        </Typography>
      </button>
    );
  };

  return (
    <div className="dashboard_switch_tab">
      {/* 대시보드에서 esg 보여줄 때 */}
      {page === "esg_dashboard" &&
        filteredMainTabs.map((tab) => (
          <TabButton
            key={tab.path}
            isActive={activeMenu === tab.key}
            label={tab.label}
            path={tab.path}
          />
        ))}
      {/* 탭관리에서의 esg 보여줄 때 */}
      {page === "esg_management" &&
        managementTabs.map((tab) => (
          <TabButton
            key={tab.path}
            isActive={activeMenu === tab.key}
            label={tab.label}
            path={tab.path}
          />
        ))}
    </div>
  );
};

export default EsgDashboardTabs;
