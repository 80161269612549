import { useState } from "react";
import useLeaveSite from "../useMutation/useLeaveSite";
import useSearchSite from "../useQuery/useSearchSite";

const useLeaveSiteHandler = () => {
  const [keyword, setKeyword] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedSite, setSelectedSite] = useState("");
  const [siteLeaveConfirmModal, setSiteLeaveConfirmModal] = useState(false);

  const {
    data,
    isLoading,
    refetch: handleSearchSite,
  } = useSearchSite(true, keyword);
  let siteList = data?.data?.siteList || [];

  const leaveSuccessCb = ({ status }) => {
    if (status === "success") {
    }
  };

  const leaveErrorCb = (error) => {
    console.error("leave failed: ", error);
  };

  const { mutate: leaveSite } = useLeaveSite(leaveSuccessCb, leaveErrorCb);

  const handleAttendSite = () => {
    leaveSite({ siteNo: selectedSite.no });
  };

  const handleClickSite = (site) => {
    setSelectedSite(site);
  };

  const handleSearch = () => {
    setSearchKeyword(keyword);
    handleSearchSite();
  };

  return {
    keyword,
    setKeyword,
    searchKeyword,
    selectedSite,
    setSelectedSite,
    isLoading,
    siteList,
    handleAttendSite,
    handleClickSite,
    handleSearch,
    siteLeaveConfirmModal,
    setSiteLeaveConfirmModal,
  };
};

export default useLeaveSiteHandler;
