import React, { useState, useEffect, useCallback } from "react";
//library
import { useParams, useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
//component
import CompanyPop from "../../../component/common/Pop/CompanyPop";
import ContractPop from "../../../component/common/Pop/ContractPop";
import Mheader from "../../../component/common/Mheader";
import EstimateCancelPop from "../../../component/common/Pop/EstimateCancelPop";
import ExcelPreviewPop from "../../../component/common/Pop/ExcelPreviewPop";
import Loading from "../../../component/etc/Loading";
//css
import "../../../asset/css/estimatedetail.css";
import * as S from "../../../asset/css/estimateDetail.styles.js";
//etc
import i_refresh from "../../../asset/images/w-refresh-icon.png";
import i_cahat from "../../../asset/images/m-chat-icon.png";

//sweet alert
import swal from "sweetalert";

//redux
import { useSelector } from "react-redux";

import {
  estimateBidExtendApi,
  estimateComInfoApi,
  generateCompareExcel,
  attendChat,
  estimateDetail2Api,
  fetchBidStatusListApi,
  sendBidRequestMessageApi,
} from "../../../api/auth";
import moment from "moment";
import BidStatusPop from "../../../component/common/Pop/bidStatusPop.js";
import ReBidPop from "../../../component/common/Pop/ReBidPop.js";
import Button from "../../../component/ui/button/Button";
import Typography from "../../../component/ui/typography/Typography";
import useScrollFixed from "../../../hooks/useScrollFixed.js";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";
import ReBidModal from "./modal/ReBidModal";
import AliveReBidModal from "./modal/AliveReBidModal";
import CancelBidModal from "./modal/CancelBidModal";
import { Divider } from "antd";
import TypeChip from "./components/chip/TypeChip";
import StateChip from "./components/chip/StateChip";
import ExtraTypeChip from "./components/chip/ExtraTypeChip";
import DownloadSVG from "../../../asset/images/svg/DownloadSVG";

export default function EstimateDetail() {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });
  const pushToken = useSelector((state) => state.push);

  const [reBidOpen, setReBidOpen] = useState(false);
  const openReBidModal = () => setReBidOpen(true);
  const closeReBidModal = () => setReBidOpen(false);

  const [aliveReBidOpen, setAliveReBidOpen] = useState(false);
  const openAliveReBidModal = () => setAliveReBidOpen(true);
  const closeAliveReBidModal = () => setAliveReBidOpen(false);

  const [cancelBidOpen, setCancelBidOpen] = useState(false);
  const openCancelBidModal = () => setCancelBidOpen(true);
  const closeCancelBidModal = () => setCancelBidOpen(false);

  const params = useParams();
  const navigate = useNavigate();

  const [detailPop, setDetailPop] = useState(false);
  const [contractPop, setContractPop] = useState(false);
  const [comList, setComList] = useState([]);
  const [reason, setReason] = useState({
    re1: "",
    re2: "",
  });
  const [pList, setpList] = useState([]);
  const location = useLocation();
  // const [bidNo, setBidNo] = useState(location.state?.bidNo);
  const bidNo = params.id;
  const [detailData, setDetailData] = useState({});
  const [extendTime, setExtendTime] = useState();
  const [endDate, setEndDate] = useState();
  const [prsCompany, setPrsCompany] = useState(0);
  const [comDetail, setComDetail] = useState({});
  const [index, setIndex] = useState(0);
  const [lowestState, setLowestState] = useState([]);
  const [lowestIndex, setLowestIndex] = useState(0);
  const [cancelPop, setCancelPop] = useState(false);
  const [estimateNum, setEstimateNum] = useState(0);

  //doc
  const [generating, setGenerating] = useState(false);
  const [excelPop, setExcelPop] = useState(false);
  const [excelUrl, setExcelUrl] = useState(false);
  const [imgList, setImgList] = useState([]);

  const formatPhoneNumber = (phoneNumber) => {
    // Check if phoneNumber is defined and not null
    if (!phoneNumber) {
      return "";
    }

    // Remove all non-numeric characters
    phoneNumber = phoneNumber.replace(/\D/g, "");

    // Determine the format  on the length of the number
    if (phoneNumber.length === 10) {
      return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    } else if (phoneNumber.length === 11) {
      return phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    } else {
      // Return the original input if it doesn't match the expected lengths
      return phoneNumber;
    }
  };

  //estimate company info api
  const estimateComFnc = async (data) => {
    const response = await estimateComInfoApi(data);
    if (response.status === "success") {
      setComDetail(response.data.companyInfo);
    } else {
      swal("오류", "", "error");
    }
  };

  //estimate bid api
  const bidExtendFnc = async () => {
    const response = await estimateBidExtendApi(bidNo, endDate);

    if (response.status === "success") {
      setExtendTime(response.data.bidTime);
      setEndDate(response.data.bid_endate);
    }
  };

  //estimate bidDrop
  const bidDrop = (data) => {
    if (data === "L") {
      return "하차 역무 있음";
    } else if (data === "U") {
      return "하차 역무 없음";
    }
  };

  //금액
  const numberWithCommas = (x) => {
    if (x != undefined) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const estimateDetailFnc2 = async () => {
    const response = await estimateDetail2Api(bidNo);

    if (response.status === "success") {
      const endate = moment(response.data.bid?.endate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      setDetailData(response.data);
      // setpList(response.data.bidProductList);
      setpList(
        response.data?.bidProductList.map((item, index) => ({
          ...item,
          arr: response.data.offerDetailInfoList
            ? response.data?.offerDetailInfoList?.map(
                (oItem, subIndex) => oItem?.companyPriceInfoList[index]
              )
            : [],
        }))
      );
      setComList(response.data.offerDetailInfoList);
      const arr = response.data?.offerDetailInfoList?.filter(
        (item) => item.companyName === response.data?.lowestCompanyName
      );

      setLowestState(arr);
      const arr2 = response.data?.offerDetailInfoList?.map((item, index) =>
        item.companyName === response.data?.lowestCompanyName ? index : null
      );

      setLowestIndex(arr2);
      setExtendTime(response.data.bidTime);
      setEndDate(endate);
      setImgList(response.data?.bidImgInfoList);
    }
  };

  useEffect(() => {
    estimateDetailFnc2();
  }, []);

  const _generateCompareExcel = async (type) => {
    setGenerating(true);

    const data = {
      bid_no: bidNo,
    };

    const response = await generateCompareExcel(data);
    if (response.status === "success") {
      if (type === "download") {
        const link = document.createElement("a");
        link.href = response.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // window.open(response.data);
      } else {
        setExcelUrl(response.data);
        setExcelPop(true);
      }
    }
    setGenerating(false);
  };

  const _attendChat = async () => {
    const reqData = {
      bidNo: bidNo,
    };

    const response = await attendChat(reqData);

    if (response.status === "success") {
      navigate("/qna", {
        state: {
          bidRoomNo: response.data.chatRoom.no,
          selectedChatItem: response.data,
        },
      });
    }
  };

  // 투찰 현황 확인
  const [bidStatusPop, setBidStatusPop] = useState(false);
  const [bidStatusList, setBidStatusList] = useState();
  const openBidStatusPop = () => {
    setBidStatusPop(true);
  };

  const closeBidStatusPop = () => {
    setBidStatusPop(false);
  };

  const fetchBidStatusList = useCallback(async () => {
    try {
      const response = await fetchBidStatusListApi(detailData?.bid?.no);
      if (response.status === "success") {
        setBidStatusList(response.data.bidViewInfoList);
      }
    } catch (error) {
      console.error(error);
    }
  }, [detailData?.bid?.no]);

  useEffect(() => {
    if (bidStatusPop) {
      fetchBidStatusList();
    }
  }, [bidStatusPop, fetchBidStatusList]);

  const onClickSendBidRequestMessage = async (companyNoList) => {
    try {
      const response = await sendBidRequestMessageApi({
        bidNo: detailData?.bid?.no,
        companyNoList: companyNoList,
      });
      if (response.status === "success") {
        swal({
          title: "투찰 요청 알림이 발송되었습니다.",
          icon: "success",
        }).then((value) => {
          if (value) {
            fetchBidStatusList();
          }
        });
      } else if (response.status === "fail") {
        swal("알림 전송이 실패하였습니다.", "", "error");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const handleOkClick = () => {
  //   const value = document.querySelector(".swal-content__textarea").value;

  //   if (!value) {
  //     swal("유찰 사유를 입력해주세요.", "", "warning");
  //   } else {
  //     bidFail(detailData?.bid?.no, value);
  //     swal({
  //       title: "해당 입찰 건이 유찰되었습니다.",
  //       text: `${value}`,
  //       icon: "success",
  //     }).then((value) => {
  //       if (value) estimateDetailFnc2();
  //     });
  //   }
  // };

  // const handleCancelClick = () => {
  //   swal("입력이 취소되었습니다.", "", "info");
  // };

  // const onClickOpenBidFailModal = () => {
  //   try {
  //     swal({
  //       title: "유찰 사유 입력",
  //       icon: "info",
  //       buttons: {
  //         cancel: {
  //           text: "취소",
  //           value: null,
  //           visible: true,
  //         },
  //         confirm: {
  //           text: "확인",
  //           value: true,
  //           visible: true,
  //         },
  //       },
  //       closeOnClickOutside: false,
  //       content: {
  //         element: "textarea",
  //         attributes: {
  //           placeholder: "사유를 입력해주세요.",
  //           rows: 4,
  //           style: "resize:none",
  //         },
  //       },
  //     }).then((value) => {
  //       if (value) handleOkClick();
  //       if (!value) handleCancelClick();
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // 재입찰(re bid)
  const [reBidPop, setReBidPop] = useState(false);

  const comparativeDownload = () => {
    if (detailData?.offerCount === 0) {
      swal("현재 입찰에 참여한 업체가 없습니다.", "", "info");
    } else {
      //TODO
      if (pushToken && pushToken.type && pushToken.value) {
        swal(
          "비교견적서 다운로드 기능은 현재 모바일/PC 웹에서 가능합니다. 곧 업데이트 예정입니다.",
          "",
          "info"
        );
      } else {
        _generateCompareExcel("download");
      }
    }
  };

  const convertByte = (byte) => {
    if (!byte) return;

    const kb = 1024;
    const fraction = 2;
    const notations = ["Byte", "KB", "MB", "GB"];

    const exponent = Math.floor(Math.log(byte) / Math.log(kb));
    const size = parseFloat((byte / Math.pow(kb, exponent)).toFixed(fraction));
    const notation = notations[exponent];

    return `${size} ${notation}`;
  };

  const { isFixed } = useScrollFixed(isDesktop ? 200 : 300);

  return (
    <>
      <div className="page_container">
        <Mheader title="입찰 내역 확인" />
        <section className="header_section">
          <div className="header_desc">
            <p>입찰 내역 확인</p>
            <p>입찰 공고 내용 및 투찰 내역을 확인할 수 있습니다.</p>
          </div>
          <div className="header_button">
            <Button
              size="medium"
              type="secondary"
              onClick={_attendChat}
              width={isDesktop ? 180 : "100%"}
            >
              질의응답 바로가기
            </Button>
          </div>
        </section>

        <Divider className="header_section_divider" />

        <section className="card_section">
          <div className="card_order_no_container">
            <span>입찰번호 : {detailData.bid?.code || "-"}</span>
            <span>
              발행시간 : {detailData.bid?.createdDate.slice(0, 16) || "-"}
            </span>
          </div>

          <div className="card_order_contents_container">
            <div className="card_order_contents_chip_container">
              <TypeChip type={detailData.bid?.type} />
              <ExtraTypeChip type={detailData.bid?.extraType} />
              <StateChip type={detailData.bid?.state} />
            </div>

            <div className="card_order_header_container">
              <div className="card_order_contents_title_container">
                <span className="card_order_contents_title">
                  {"[" +
                    detailData?.constructionName +
                    "] " +
                    detailData?.siteName}
                </span>
                {isDesktop && (
                  <Divider
                    className="card_order_contents_title_divider"
                    type="vertical"
                  />
                )}
                <span className="card_order_contents_site_name">
                  참여업체 : {detailData?.offerDetailInfoList?.length}
                  개사
                </span>
              </div>
              {detailData.bid?.state === "ALIVE" && (
                <div className="card_order_current_container">
                  <p className="bidding_time">
                    <Typography component="p" type="c3">
                      남은 투찰 시간 : {extendTime}
                    </Typography>

                    <Button
                      size="xsmall"
                      type="secondary"
                      onClick={bidExtendFnc}
                    >
                      1시간 연장
                    </Button>
                  </p>
                  <Typography component="p" type="c3">
                    투찰 마감일 : {endDate}까지
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </section>

        <section className="order_section">
          <div className="order_info_container">
            <Typography component="p" type="h4">
              입찰자
            </Typography>
            <Divider className="order_section_divider" />
            <div className="order_info_detail">
              <div>
                <div>
                  <p>고객사명</p>
                  <p>{detailData?.constructionName || "-"}</p>
                </div>
                <div>
                  <p>현장명</p>
                  <p>{detailData?.siteName || "-"}</p>
                </div>
              </div>
              <div>
                <div>
                  <p>입찰자명</p>
                  <p>
                    {detailData?.userName
                      ? `${detailData?.userName} ${detailData.userRank}`
                      : "-"}
                  </p>
                </div>
                <div>
                  <p>입찰자 연락처</p>
                  <p>{formatPhoneNumber(detailData?.sitePhone) || "-"}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="order_info_container">
            <Typography component="p" type="h4">
              입찰 공고 정보
            </Typography>
            <Divider className="order_section_divider" />
            <div className="order_info_detail">
              <div>
                <div>
                  <p>주문 / 입찰 방식</p>
                  <p>{detailData.bid?.type === "C" ? "단가계약" : "일회성"}</p>
                </div>
                <div>
                  <p>투찰 기한</p>
                  <p>
                    {" "}
                    {moment(detailData.bid?.endate).format(
                      "YYYY-MM-DD HH:mm 까지"
                    )}
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <p>납품 기한</p>
                  <p>
                    {detailData.bid?.deldate
                      ? moment(detailData.bid?.deldate).format(
                          "YYYY-MM-DD HH:mm 까지"
                        )
                      : "-"}
                  </p>
                </div>
                <div>
                  <p>납품 주소</p>
                  <p>
                    {detailData.bid?.addr1}, {detailData.bid?.addr2}
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <p>선정 방식</p>
                  <p>최저단가</p>
                </div>
                <div>
                  <p>배송비</p>
                  <p>배송비 포함</p>
                </div>
              </div>
              <div>
                <div>
                  <p>하차 역무</p>
                  <p>{bidDrop(detailData.bid?.drop)}</p>
                </div>
                <div>
                  <p>비용 정산</p>
                  <p>공새로 위임 정산</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* 상세정보 */}
        {/* <div className="quote_content">
          <h3 className="s_tit">
            <p>상세정보</p>
          </h3>
          <div className="quote_list_box">
            <ul className="quote_list">
              <li>
                <p>고객사</p>
                <span>
                  {"[" +
                    detailData?.constructionName +
                    "] " +
                    detailData?.siteName}
                </span>
              </li>
              <li>
                <p>현장주소</p>
                <span>
                  {detailData.bid?.addr1}, {detailData.bid?.addr2}
                </span>
              </li>
              <li>
                <p>공급사</p>
                {detailData.bid?.state === "END" ? (
                  <span>
                    {detailData?.lowestCompanyName}
                  </span>
                ) : (
                  <span>미정</span>
                )}
              </li>

              {detailData.bid?.type === "C" ? (
                <li>
                  <p>계약기간</p>
                  <span>계약 체결일로부터 {detailData.bid?.term}개월</span>
                </li>
              ) : null}
            </ul>
            <ul className="quote_list">
              {detailData.bid?.type !== "C" ? (
                <li>
                  <p>납품기한</p>
                  <span>
                    {moment(detailData.bid?.deldate).format(
                      "YYYY-MM-DD HH:mm까지"
                    )}
                  </span>
                </li>
              ) : null}
              <li>
                <p>배송방법</p>
                <span>{bidDrop(detailData.bid?.drop)}</span>
              </li>
              <li>
                <p>결제방법</p>
                <span>{detailData.bid?.refund}</span>
              </li>
              {detailData.bid?.type === "C" ? (
                <li>
                  <p>결제주기</p>
                  <span>
                    납품완료일(세금계산서 발행일)로부터 {detailData.bid?.payday}
                    일
                  </span>
                </li>
              ) : null}
            </ul>
          </div>
        </div> */}

        <div className="bidding_content">
          <h3 className="flex">
            <Typography component="p" type="h4">
              투찰 내역
            </Typography>

            {detailData.bid?.state === "ALIVE" && (
              <Button size="small" onClick={openBidStatusPop}>
                투찰현황확인
              </Button>
            )}
            {detailData.bid?.state !== "ALIVE" &&
              detailData.bid?.state !== "CANCEL" && (
                <Button
                  size="small"
                  type="secondary"
                  onClick={comparativeDownload}
                >
                  비교견적서 다운로드
                </Button>
              )}
          </h3>
          <section className="order_section">
            <div className="bidding_table">
              <table className="bid_prd">
                <tbody>
                  <tr>
                    <th>
                      <p className="name">품목명</p>
                    </th>
                    <th>
                      <p className="standard">규격</p>
                    </th>
                    <th>
                      <p className="unit" style={{ lineHeight: "1.3" }}>
                        제조국
                        <br />
                        (브랜드)
                      </p>
                    </th>
                    <th>
                      <p className="unit">단위</p>
                    </th>
                    <th>
                      <p className="cnt">수량</p>
                    </th>
                    {comList?.map((item) => (
                      <th>
                        <p className="cnt">{item.companyName}</p>
                      </th>
                    ))}
                  </tr>
                  <tr>
                    <td colSpan="5"></td>
                    {comList?.map((item, i) => (
                      <td>
                        <div className="flex gap4 flex-col">
                          {detailData.bid?.state === "CHOOSE" &&
                            item.isLowPrice && (
                              <Button
                                size="small"
                                type="primary"
                                width={140}
                                onClick={() => {
                                  navigate(
                                    "/management/estimate/conclude_contract/" +
                                      item.offerNo,
                                    {
                                      state: {
                                        detailData: detailData,
                                        prsCompany: item.offerNo,
                                        index: i,
                                        selectCompany: item,
                                      },
                                    }
                                  );
                                }}
                              >
                                {detailData.bid?.type === "C"
                                  ? "계약하기"
                                  : "주문하기"}
                              </Button>
                            )}
                          <Button
                            size="small"
                            type="secondary"
                            width={140}
                            onClick={() => {
                              setDetailPop(true);
                              estimateComFnc(item.companyNo);
                            }}
                          >
                            업체정보
                          </Button>
                        </div>
                      </td>
                    ))}
                  </tr>
                  {pList.map((data, i) => (
                    <tr key={data.no}>
                      <td>{data.name}</td>
                      <td>
                        <pre className="p_list_standard">
                          {data.standard?.replace(/, /gi, "\n")}
                        </pre>
                      </td>
                      <td>{data.brand}</td>
                      <td>{data.unit}</td>
                      <td>{data.count.toLocaleString("ko")}</td>
                      {data?.arr?.map((item) =>
                        detailData.bid?.state !== "ALIVE" ? (
                          <td>{numberWithCommas(item?.price)}</td>
                        ) : (
                          <td>투찰 마감 후 공개</td>
                        )
                      )}
                    </tr>
                  ))}
                  <tr>
                    <td colSpan="5" className="total">
                      합계
                    </td>
                    {comList?.map((item, idx) =>
                      detailData.bid?.state !== "ALIVE" &&
                      detailData.bid?.state !== "CANCEL" ? (
                        <td className="total">
                          {numberWithCommas(item?.totalPrice)}
                          {idx === comList.length - 1 && !item.isLowPrice && (
                            <Typography
                              component="p"
                              type="c1"
                              color="var(--sub-red-10)"
                            >
                              최고가
                            </Typography>
                          )}
                          {item.isLowPrice && (
                            <Typography
                              component="p"
                              type="c1"
                              color="var(--sub-blue-10)"
                            >
                              최저가
                            </Typography>
                          )}
                          {/* <div
                              style={{ color: "#ff6600", fontWeight: "500" }}
                            >
                             
                            </div> */}

                          <Typography
                            component="p"
                            type="c1"
                            color="var(--sub-red-10)"
                          >
                            {`(최고가 대비 ${(
                              (item.totalPrice /
                                comList[comList.length - 1].totalPrice) *
                              100
                            ).toFixed()}%)`}
                          </Typography>
                        </td>
                      ) : detailData.bid?.state === "CANCEL" ? (
                        <td>취소된 공고</td>
                      ) : (
                        <td>투찰 마감 후 공개</td>
                      )
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </section>

          <section className="additional_section">
            <p className="additional_section_title">추가 정보</p>
            <Divider className="additional_section_divider" />
            <div className="additional_detail">
              {detailData.prevBid && (
                <>
                  <div>
                    <p>기존 공고</p>
                    <Typography
                      type="c4"
                      component="a"
                      href={`/management/estimate/document/${detailData.prevBid.no}`}
                    >
                      {detailData.prevBid.code}
                    </Typography>
                  </div>
                  <div>
                    <p>재공고 사유</p>
                    <p>{detailData.prevBid.selectReason}</p>
                  </div>
                </>
              )}
              {/* {detailData.nextBid && (
                <>
                  <div>
                    <p>next 공고</p>
                    <p>{detailData.nextBid.code}</p>
                  </div>
                  <div>
                    <p>재공고 사유</p>
                    <p>{detailData.bid.selectReason}</p>
                  </div>
                </>
              )} */}
              {detailData?.bid?.state === "CANCEL" && (
                <>
                  <div>
                    <p>취소 사유</p>
                    <p>{detailData.bid.selectReason}</p>
                  </div>
                </>
              )}
              {detailData?.bid?.state === "FAIL" && (
                <>
                  <div>
                    <p>유찰 사유</p>
                    <p>{detailData.bid.selectReason}</p>
                  </div>
                </>
              )}
              <div>
                <p>비고</p>
                <p>{detailData.bid?.comment || "-"}</p>
              </div>
              <div>
                <p>첨부파일</p>
                <div className="additional_file_container">
                  {imgList.length !== 0 ? (
                    imgList.map((img) => (
                      <a
                        className="additional_file_download_button"
                        key={img.fileName}
                        href={img.fileRoot}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="additional_file_name_container">
                          <p>{img.fileName}</p>
                          <p>{convertByte(img.fileLength)}</p>
                        </div>
                        <DownloadSVG size="small" stroke="#1B1D1F" />
                      </a>
                    ))
                  ) : (
                    <p className="additional_file_name_default">-</p>
                  )}
                </div>
              </div>

              {detailData?.offerDetailInfoList?.map((data) => (
                <>
                  <div>
                    <p>
                      <span>{data.companyName}</span> 비고
                    </p>
                    <p>{data.offerComment || "-"}</p>
                  </div>
                  <div>
                    <p>첨부파일</p>
                    <div className="additional_file_container">
                      {data?.offerFileUrl ? (
                        <a
                          className="additional_file_download_button"
                          key={data.fileName}
                          href={data?.offerFileUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="additional_file_name_container">
                            <p>{data.offerFileName}</p>
                            <p>{convertByte(data.fileLength)}</p>
                          </div>
                          <DownloadSVG size="small" stroke="#1B1D1F" />
                        </a>
                      ) : (
                        <p className="additional_file_name_default">-</p>
                      )}
                    </div>
                  </div>
                </>
              ))}
            </div>
          </section>

          {/* {detailData.bid?.state === "FAIL" && (
            <div className="fail_container">
              <h3 className="fail_title">
                <p>유찰 사유</p>
                {detailData.nextBidCode && (
                  <a
                    href={`/management/estimate/document/${detailData.nextBidNo}`}
                  >
                    ( {detailData.nextBidCode} {detailData.nextBidType} )
                  </a>
                )}
              </h3>

              <div className="fail_box">
                <div className="fail_left_box">
                  <p>
                    <span>사유</span>
                  </p>
                </div>
                <div className="fail_content">
                  <p>{detailData?.bid?.selectReason}</p>
                </div>
              </div>
            </div>
          )} */}
        </div>

        {detailData.bid?.state === "CHOOSE" && (
          <div
            className={classNames("re_estimate_btn_group", {
              desktop_fixed: isDesktop && isFixed,
              mobile_fixed: isMobile && isFixed,
            })}
          >
            <Button
              size="medium"
              type="secondary"
              onClick={() => {
                navigate(-1);
              }}
            >
              목록으로
            </Button>
            <Button size="medium" type="primary" onClick={openReBidModal}>
              유찰 및 재입찰
            </Button>
          </div>
        )}
        {detailData.bid?.state === "ALIVE" &&
          detailData.bid.extraType === null && (
            <div
              className={classNames("re_estimate_btn_group", {
                desktop_fixed: isDesktop && isFixed,
                mobile_fixed: isMobile && isFixed,
              })}
            >
              <Button
                size="medium"
                type="secondary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                목록으로
              </Button>
              <Button size="medium" type="danger" onClick={openAliveReBidModal}>
                취소 및 재공고
              </Button>
            </div>
          )}
        {detailData.bid?.state === "ALIVE" &&
          (detailData.bid?.extraType === "REESTIMATE" ||
            detailData.bid?.extraType === "REBID") && (
            <div
              className={classNames("re_estimate_btn_group", {
                desktop_fixed: isDesktop && isFixed,
                mobile_fixed: isMobile && isFixed,
              })}
            >
              <Button
                size="medium"
                type="secondary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                목록으로
              </Button>
              <Button size="medium" type="danger" onClick={openCancelBidModal}>
                공고 취소
              </Button>
            </div>
          )}
        {detailPop && (
          <CompanyPop setDetailPop={setDetailPop} comDetail={comDetail} />
        )}
        {contractPop && (
          <ContractPop
            setContractPop={setContractPop}
            setReason={setReason}
            reason={reason}
            detailData={detailData}
            detailData2={detailData}
            prsCompany={prsCompany}
            index={index}
          />
        )}
        {cancelPop && (
          <EstimateCancelPop
            setCancelPop={setCancelPop}
            estimateNum={estimateNum}
          />
        )}
        {excelPop && (
          <ExcelPreviewPop setExcelPop={setExcelPop} url={excelUrl} />
        )}
        {bidStatusPop && (
          <BidStatusPop
            bidStatusList={bidStatusList}
            onClickSendBidRequestMessage={onClickSendBidRequestMessage}
            closeBidStatusPop={closeBidStatusPop}
          />
        )}
        {reBidPop && (
          <ReBidPop
            detailData={detailData}
            bidNo={bidNo}
            setReBidPop={setReBidPop}
            estimateDetailFnc2={estimateDetailFnc2}
          />
        )}
      </div>

      {generating && <Loading msg={"비교견적서 생성중입니다."} />}

      {/* 유찰 - 재입찰 재공고 */}
      {reBidOpen && (
        <ReBidModal
          reBidOpen={reBidOpen}
          openReBidModal={openReBidModal}
          closeReBidModal={closeReBidModal}
          detailData={detailData}
          pList={pList}
          estimateDetailFnc2={estimateDetailFnc2}
          bidNo={bidNo}
        />
      )}

      {/* 입찰 중 - 취소 및 재공고 */}
      {aliveReBidOpen && (
        <AliveReBidModal
          aliveReBidOpen={aliveReBidOpen}
          openAliveReBidModal={openAliveReBidModal}
          closeAliveReBidModal={closeAliveReBidModal}
          detailData={detailData}
          estimateDetailFnc2={estimateDetailFnc2}
          pList={pList}
          bidNo={bidNo}
        />
      )}

      {/* 재입찰 - 취소 */}
      {cancelBidOpen && (
        <CancelBidModal
          cancelBidOpen={cancelBidOpen}
          openCancelBidModal={openCancelBidModal}
          closeCancelBidModal={closeCancelBidModal}
          estimateDetailFnc2={estimateDetailFnc2}
          bidNo={bidNo}
        />
      )}
    </>
  );
}
