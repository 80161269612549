import { useEffect, useRef, useState } from "react";
import Mheader from "../../../component/common/Mheader";
import DashboardTabs from "../../../component/common/EsgDashboardTabs";
import s from "../../../asset/css/esgManagement.module.css";
import Select from "../../../component/ui/select/Select";
import Button from "../../../component/ui/button/Button";
import { useMediaQuery } from "react-responsive";
import Typography from "../../../component/ui/typography/Typography";
import { COLOR } from "../../../constant/Palette";
import Chip from "../../../component/ui/chip/Chip";
import {
  esgDownloadSheet,
  esgEmptyDownloadSheet,
  esgUploadSheet,
  esgWasteTableApi,
} from "../../../api/auth";
import { Empty, Spin } from "antd";
import Modal from "../../../component/ui/modal/Modal";
import { checkValidationExcel } from "../../../util/uploadValidation";
import { useLocation } from "react-router-dom";

export default function EsgWaste() {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });

  const location = useLocation();
  const isSelectYear = location.state?.selectYear;
  console.log(isSelectYear);

  const [year, setYear] = useState(isSelectYear ? isSelectYear : "2024");
  const [wasteData, setWasteData] = useState([]);

  const handleYearSelect = (e) => setYear(e);

  const options = [
    { value: "2024", label: "2024년" },
    { value: "2023", label: "2023년" },
    { value: "2022", label: "2022년" },
    { value: "2021", label: "2021년" },
  ];

  const esgWasteTableFnc = async (year) => {
    const response = await esgWasteTableApi(year);
    if (response.status === "success") {
      setWasteData(response.data);
    }
  };

  useEffect(() => {
    esgWasteTableFnc(year);
  }, [year]);

  // 업로드 실패 모달
  const [errorOpen, setErrorOpen] = useState(false);
  const openErrorModal = () => setErrorOpen(true);
  const closeErrorModal = () => setErrorOpen(false);
  // 업로드 성공 모달
  const [successOpen, setSuccessOpen] = useState(false);
  const openSuccessModal = () => setSuccessOpen(true);
  const closeSuccessModal = () => setSuccessOpen(false);

  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);

  // 양식다운로드
  const handleClickDownloadBtn = async () => {
    setIsLoading1(true);
    const response = await esgEmptyDownloadSheet();
    if (response.status === "success") {
      // 파일 다운로드를 위한 링크 생성 및 트리거
      const link = document.createElement("a");
      link.href = response.data.root;
      link.download = response.data.origname;
      link.click();
      setIsLoading1(false);
    } else {
      setIsLoading1(false);
    }
  };
  // 엑셀다운로드
  const handleClickExcelDownBtn = async () => {
    setIsLoading2(true);
    const response = await esgDownloadSheet(year);
    if (response.status === "success") {
      // 파일 다운로드를 위한 링크 생성 및 트리거
      const link = document.createElement("a");
      link.href = response.data.root;
      link.download = response.data.origname;
      link.click();
      setIsLoading2(false);
    } else {
      setIsLoading2(false);
    }
  };

  const fileInputRef = useRef(null);

  // 파일 선택 대화상자 열기
  const handleClickUploadBtn = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // 파일이 선택되었을 때 업로드 처리
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const checkFile = checkValidationExcel(file);
    if (!checkFile) return false;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("year", year);

    // try {
    setIsLoading3(true);
    const response = await esgUploadSheet(formData);

    if (response.status === "success") {
      setIsLoading3(false);
      openSuccessModal();
    } else {
      setIsLoading3(false);
      openErrorModal();
    }
  };

  return (
    <div className="site_wrap">
      <div className="site_container">
        <Mheader title="ESG 관리" />

        <div className={s.tab_container}>
          <DashboardTabs activeMenu="waste" page="esg_management" />
        </div>
        <div className={s.filter_container}>
          <div className={s.date_box}>
            <Typography component="p" type="h5">
              연도
            </Typography>
            <Select
              size="large"
              width={isDesktop ? 200 : "100%"}
              defaultValue={isSelectYear ? isSelectYear : "2024"}
              options={options}
              onChange={handleYearSelect}
            />
          </div>

          <div className={s.btn_box}>
            <Button
              type="secondary"
              size="small"
              onClick={handleClickDownloadBtn}
              disabled={isLoading1}
            >
              <Typography component="p" type="c3" color={COLOR.PRIMARY.L08}>
                양식 다운로드
              </Typography>
              {isLoading1 && <Spin />}
            </Button>
            <Button
              type="secondary"
              size="small"
              onClick={handleClickExcelDownBtn}
            >
              <Typography component="p" type="c3" color={COLOR.PRIMARY.L08}>
                엑셀 다운로드
              </Typography>
              {isLoading2 && <Spin />}
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={handleClickUploadBtn}
              disabled={isLoading3}
            >
              <Typography component="p" type="c3" color={COLOR.GRAY.L01}>
                엑셀 업로드
              </Typography>
              {isLoading3 && <Spin />}
            </Button>

            <input
              type="file"
              accept=".xlsx"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
        </div>
        <div className={s.table_container}>
          {wasteData?.length >= 1 && (
            <table className={s.table}>
              <thead>
                <tr>
                  <th className={`${s.sticky_col} ${s.divider_th}`} rowSpan={2}>
                    <Typography component="span" type="c1">
                      구분
                    </Typography>
                  </th>
                  <th className={`${s.sticky_col} ${s.product_th}`} rowSpan={2}>
                    <Typography component="span" type="c1">
                      재료/에너지
                    </Typography>
                  </th>
                  <th
                    className={`${s.sticky_col} ${s.product_detail_th}`}
                    rowSpan={2}
                  >
                    <Typography component="span" type="c1">
                      단위
                    </Typography>
                  </th>

                  <th colSpan={12}>
                    <Typography component="span" type="c1">
                      입력데이터
                    </Typography>
                  </th>
                </tr>
                <tr>
                  {Array.from({ length: 12 }, (_, idx) => {
                    return (
                      <th key={idx} className={s.month_th}>
                        <Typography component="span" type="c1">
                          {idx + 1}월
                        </Typography>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {wasteData?.map((elem, idx) => (
                  <tr>
                    <td className={s.sticky_col}>
                      <Typography component="span" type="c2">
                        {elem.label}
                      </Typography>
                    </td>
                    <td className={s.sticky_col}>
                      <Typography component="span" type="c2">
                        {elem.material}
                      </Typography>
                    </td>

                    <td className={s.sticky_col}>
                      <Typography component="span" type="c2">
                        {elem.unit}
                      </Typography>
                    </td>
                    <td>
                      <Typography component="span" type="c2">
                        {elem.value1}
                      </Typography>
                    </td>
                    <td>
                      <Typography component="span" type="c2">
                        {elem.value2}
                      </Typography>
                    </td>
                    <td>
                      <Typography component="span" type="c2">
                        {elem.value3}
                      </Typography>
                    </td>
                    <td>
                      <Typography component="span" type="c2">
                        {elem.value4}
                      </Typography>
                    </td>
                    <td>
                      <Typography component="span" type="c2">
                        {elem.value5}
                      </Typography>
                    </td>
                    <td>
                      <Typography component="span" type="c2">
                        {elem.value6}
                      </Typography>
                    </td>
                    <td>
                      <Typography component="span" type="c2">
                        {elem.value7}
                      </Typography>
                    </td>
                    <td>
                      <Typography component="span" type="c2">
                        {elem.value8}
                      </Typography>
                    </td>
                    <td>
                      <Typography component="span" type="c2">
                        {elem.value9}
                      </Typography>
                    </td>
                    <td>
                      <Typography component="span" type="c2">
                        {elem.value10}
                      </Typography>
                    </td>
                    <td>
                      <Typography component="span" type="c2">
                        {elem.value11}
                      </Typography>
                    </td>
                    <td>
                      <Typography component="span" type="c2">
                        {elem.value12}
                      </Typography>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {wasteData?.length === 0 && (
            <div className={s.dashboard_empty_container}>
              <Empty description={<span>내역이 존재하지 않습니다.</span>} />
            </div>
          )}
        </div>
      </div>

      <Modal
        size="xsmall"
        type="error"
        open={errorOpen}
        title={
          <Typography component="p" type="h4" color="var(--sub-red-10)">
            업로드에 실패하였습니다.
          </Typography>
        }
        closeIcon={false}
        onOk={closeErrorModal}
        onCancel={closeErrorModal}
        footer={[
          <Button type="primary" onClick={closeErrorModal}>
            확인
          </Button>,
        ]}
      >
        <Typography component="p" type="c1">
          아래 사유로 업로드에 실패하였습니다.
        </Typography>
      </Modal>
      <Modal
        size="xsmall"
        type="success"
        open={successOpen}
        title={
          <Typography component="p" type="h4" color="var(--sub-blue-05)">
            업로드가 완료되었습니다.
          </Typography>
        }
        closeIcon={false}
        onOk={closeSuccessModal}
        onCancel={closeSuccessModal}
        footer={[
          <Button type="primary" onClick={closeSuccessModal}>
            확인
          </Button>,
        ]}
      >
        <Typography component="p" type="c1">
          업로드가 정상적으로 완료되었습니다.
        </Typography>
      </Modal>
    </div>
  );
}
