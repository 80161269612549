import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/action";
import useLogin from "../useMutation/useLogin";
import { useNavigate } from "react-router-dom";
import { fetchSiteInfoApi } from "../../api/auth";

const useLoginHandler = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);

  const [loginParams, setLoginParams] = useState({ email: "", password: "" });
  const [loginFailModal, setLoginFailModal] = useState(false);
  const [loginPartnerModal, setLoginPartnerModal] = useState(false);

  const onLoginSuccessCb = async ({ status, message, data }) => {
    if (status === "fail") {
      if (message === "NoEmail" || message === "NoPassword") {
        setLoginFailModal(true);
        return;
      }
    }
    if (status === "success") {
      if (data.userType === "C") {
        setLoginPartnerModal(true);
        return;
      }
      if (data.userType === "S") {
        dispatch(actions.setToken(data.token));

        const response = await fetchSiteInfoApi(data.lastOrganizationNo);

        const userData = {
          u_no: data.userNo,
          u_name: data.name,
          u_phone: data.phone,
          u_rank: data.rank,
          u_type: data.userType,
          identityVerification: data.identityVerification,
          lastOrganizationNo: data.lastOrganizationNo,
          organizationState: data.organizationState,
          showEsg: data.showEsg,

          con_num: response?.data?.[0]?.constructionNo,
          rep_con: response?.data?.[0]?.constructionName,
          constructionType: response?.data?.[0]?.constructionType,
          site_num: response?.data?.[0]?.site?.no,
          rep_site: response?.data?.[0]?.site?.name,
          isDemo: response?.data?.[0]?.site?.isDemo,
          isAuthenticated: true,
        };

        dispatch(actions.setUser(userData));

        navigate("/", { state: { isFromLoginPage: true } });
      }
    }
  };

  const onLoginErrorCb = (error) => {
    console.error("Login failed: ", error);
  };

  const { mutate: login } = useLogin(onLoginSuccessCb, onLoginErrorCb);

  const handleLogin = () => {
    login(loginParams);
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") handleLogin();
  };

  useEffect(() => {
    if (token && user.isAuthenticated) {
      navigate("/", { replace: true });
    }
  }, [navigate, token, user.isAuthenticated]);

  return {
    navigate,
    loginParams,
    setLoginParams,
    loginFailModal,
    setLoginFailModal,
    loginPartnerModal,
    setLoginPartnerModal,
    handleLogin,
    onKeyDown,
  };
};

export default useLoginHandler;
