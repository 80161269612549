import React, { useState, useEffect } from "react";

//library
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import ko from "date-fns/locale/ko";
import "moment/locale/ko";
import DaumPostcodeEmbed from "react-daum-postcode";
import { motion } from "framer-motion";
//component
import Mheader from "../../component/common/Mheader";

//css
import "../../asset/css/request.css";
//etc
import { contractAddrApi, orderUserInfoApi } from "../../api/auth";

//img
import question_img from "../../asset/images/question-icon.png";
import i_cancel from "../../asset/images/w-cancle-bt.png";

//redux
import { useDispatch, useSelector } from "react-redux";

//sweet alert
import swal from "sweetalert";
import classNames from "classnames";
import OrderCompanySelectPop from "../../component/common/Pop/OrderCompanySelectPop";
import OrderBreadCrumb from "../../component/common/OrderBreadCrumb";
import Button from "../../component/ui/button/Button";
import Typography from "../../component/ui/typography/Typography";
import { actions } from "../../redux/action";
import Modal from "../../component/ui/modal/Modal";
import useScrollFixed from "../../hooks/useScrollFixed.js";
import { useMediaQuery } from "react-responsive";

//redux

registerLocale("ko", ko);

export default function RequestShort2() {
  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const method = useSelector((state) => state.attachExcel?.method);

  const navigate = useNavigate();
  const location = useLocation();
  const comment = location.state.comment;
  const bidImgList = location.state.bidImgList;
  const bidProductList = location.state.bidProductList;
  const selectedMethod = method ? method : location.state.choiceOrderMethod;
  const extraType = location.state?.extraType;
  const previousBidNo = location.state?.previousBidNo;
  const reBidComment = location.state?.reBidComment;

  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });
  const { isFixed } = useScrollFixed(isDesktop ? 200 : 300);

  const [proMenu, setProMenu] = useState([
    { processNo: 1, processName: "토목 공사" },
    { processNo: 2, processName: "기초 공사" },
    { processNo: 3, processName: "골조 공사" },
    { processNo: 4, processName: "설비 공사" },
    { processNo: 5, processName: "마감 공사" },
    { processNo: 6, processName: "부대 공사" },
    { processNo: 7, processName: "공통 공사" },
  ]);
  const reduxMethod = useSelector((state) => state.attachExcel.method);

  useEffect(() => {
    setChoiceOrderMethod(
      location.state.choiceOrderMethod
        ? location.state.choiceOrderMethod
        : reduxMethod
    );
  }, []);

  const [choiceOrderMethod, setChoiceOrderMethod] = useState("");
  const [selectDatePeriod, setSelectDatePeriod] = useState(false);
  const [selectDateSingle, setSelectDateSingle] = useState(false);
  const [selectDateSingle2, setSelectDateSingle2] = useState(false);
  const [selectDateCompanySelect, setSelectDateCompanySelect] = useState(false);
  const now = new Date();

  const [orderCompanyPop, setOrderCompanyPop] = useState(false);
  const [selectCompany, setSelectCompany] = useState("");

  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

  // single - 일회성입찰공고
  const [estimateInfo, setEstimateInfo] = useState({
    siteNo: userData.site_num,
    processNo: "", //진행 공정
    type: "S", //견적 분류 (일회성입찰공고)
    deldate: "", //납품 기한
    fee: "Y", //배송비 포함 여부
    drop: "L", //하자 역무 여부
    refund: "공새로 위임 정산", //정산 방법
    comment: comment,
    addr1: "", //현장 기본 주소
    addr2: "", //현장 상세 주소
    order: "EASY",
    budget: 0, //견적 예산
    endate: "", //투찰 기한
    priority: "최저 단가", //우선 사항
    pastNo: "", //견적요청 다시하기 구분
    selectReason: reBidComment, // 재공고입찰, 재입찰시 적었던 내용
  });

  // period - 단가계약입찰공고
  const [estimateInfoPeriod, setEstimateInfoPeriod] = useState({
    siteNo: userData.site_num, // 대표현장 번호
    processNo: "", //진행 공정
    type: "T", //견적 분류 (단가계약입찰공고)
    fee: "Y", //배송비 여부
    drop: "L", //하자 역무 여부
    refund: "공새로 위임 정산", //정산 방법, 임시 고정
    comment: comment,
    addr1: "", //현장 기본 주소
    addr2: "", //현장 기본 주소 ---추가
    order: "EASY",
    budget: 0, //견적 예산
    endate: "", //투찰 기한
    priority: "최저 단가", //우선 사항
    term: "", //계약 기간
    payday: "30", //결제 주기
    pastNo: "", //견적요청 다시하기 구분
    selectReason: reBidComment, //재공고입찰, 재입찰시 적었던 내용
  });

  // 업체지정주문
  const [estimateInfoSelect, setEstimateInfoSelect] = useState({
    siteNo: userData.site_num, //고객사 번호
    phone: userData?.u_phone,
    recipient: userData?.u_name,
    companyNo: "", //공급사 번호
    type: "C", // 업체지정주문
    fee: "Y", //배송비 포함 여부
    drop: "L", //하차 역무 포함 여부
    order: "EASY",
    refund: "공새로 위임 정산", //비용 정산 방법 --당분간 공새로 위임 정산으로
    edate: "", //납품일시
    addr: "", //납품 주소 - 백엔드가 이렇게 받아서 바꾸면 안 됨
    detail: "", //상세 주소 - 백엔드가 이렇게 받아서 바꾸면 안 됨
    uniqueness: comment, // 업체지정주문은 comment가 uniqueness
  });

  useEffect(() => {
    if (selectCompany !== "")
      setEstimateInfoSelect({
        ...estimateInfoSelect,
        companyNo: selectCompany.no,
      });
  }, [selectCompany]);

  const [postOpen, setPostOpen] = useState(false);
  const openPostModal = () => setPostOpen(true);
  const closePostModal = () => setPostOpen(false);

  //주소 확인
  const onCompletePost = (data, isWhat) => {
    if (isWhat === "일회성입찰공고") {
      let copy = estimateInfo;
      copy.addr1 = data.address;
      setEstimateInfo(copy);
      setEstimateInfo({ ...estimateInfo, addr1: data.address, addr2: "" });
    } else if (isWhat === "단가계약입찰공고") {
      let copy = estimateInfoPeriod;
      copy.addr1 = data.address;
      setEstimateInfoPeriod(copy);
      setEstimateInfoPeriod({
        ...estimateInfoPeriod,
        addr1: data.address,
        addr2: "",
      });
    } else if (isWhat === "업체지정주문") {
      let copy = estimateInfoSelect;
      copy.addr = data.address;
      setEstimateInfoSelect(copy);
      setEstimateInfoSelect({
        ...estimateInfoSelect,
        addr: data.address,
        detail: "",
      });
    }
    setPostOpen(false);
  };

  const getDiffDate = (selectDate) => {
    let diff = moment(selectDate).fromNow();
    let currentDate = moment();
    // selectDate로부터 현재까지의 시간 차이를 계산합니다.
    const timeDifference = moment(selectDate).diff(currentDate, "hours");

    // 6시간을 초과하는지 여부를 확인합니다.
    const isOver6Hours = timeDifference >= 6;

    if (isOver6Hours) {
      return <span className="allow">약 {diff}</span>;
    } else {
      return (
        <span className="not_allow">
          최소 working hour 6시간 이후부터 선택 가능합니다.
        </span>
      );
    }
  };
  const getDiffDate2 = (selectDate) => {
    const date1 = moment(selectDate);
    const date2 = moment(selectDateSingle);
    let diff = moment(selectDate).fromNow();

    const filterDate2 = date2.add(6, "hours");

    if (date1.isBefore(filterDate2)) {
      return (
        <span className="not_allow">
          최소 투찰기한의 6시간 이후부터 선택 가능합니다.
        </span>
      );
    } else {
      return <span className="allow">약 {diff}</span>;
    }
  };

  const [refundInfoPopup, setRefundInfoPopup] = useState(false);

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const onNextValidation = async (kind) => {
    //일회성입찰공고
    if (kind === "일회성입찰공고") {
      if (
        estimateInfo.endate === "" ||
        estimateInfo.endate === "Invalid date" ||
        selectDateSingle === false
      ) {
        swal("투찰기한을 선택해주세요.", "", "warning");
      } else if (moment(selectDateSingle).fromNow().includes("전")) {
        swal("투찰기한을 확인해주세요.", "", "warning");
      } else if (moment(selectDateSingle).diff(moment(), "hours") < 6) {
        swal("투찰기한을 확인해주세요.", "", "warning");
      } else if (
        new Date(selectDateSingle).getHours() < 7 ||
        new Date(selectDateSingle).getHours() > 18
      ) {
        swal("투찰기한은 07:00 ~ 18:00 선택 가능합니다.", "", "warning");
      } else if (
        moment(selectDateSingle2).diff(moment(selectDateSingle), "hours") <
          20 &&
        new Date(selectDateSingle).getHours() > 12
      ) {
        swal(
          "납품 기한은 최소 working hour 6시간 이후부터 선택 가능합니다.",
          "",
          "warning"
        );
      } else if (moment(selectDateSingle2).fromNow().includes("전")) {
        swal("납품 기한을 확인해주세요.", "", "warning");
      } else if (
        new Date(selectDateSingle2).getHours() < 7 ||
        new Date(selectDateSingle2).getHours() > 18
      ) {
        swal("납품 기한은 07:00 ~ 18:00 선택 가능합니다.", "", "warning");
      } else if (
        estimateInfo.deldate === "" ||
        estimateInfo.deldate === "Invalid date" ||
        selectDateSingle2 === false
      ) {
        swal("납품 기한을 선택해주세요.", "", "warning");
      } else if (estimateInfo.deldate === "not_available") {
        swal("납품 기한을 확인해주세요.", "", "warning");
      } else if (estimateInfo.fee === "") {
        swal("배송비 포함 여부를 선택해주세요.", "", "warning");
      } else if (estimateInfo.drop === "") {
        swal("하차역무 포함 여부를 선택해주세요", "", "warning");
      } else if (estimateInfo.priority === "") {
        swal("우선사항을 선택해주세요.", "", "warning");
      } else if (estimateInfo.refund === "") {
        swal("비용 정산 방법을 선택해주세요.", "", "warning");
      } else if (estimateInfo.term === "" || estimateInfo.term === "0") {
        swal("계약기간을 선택해주세요.", "", "warning");
      } else if (estimateInfo.addr2 === "") {
        swal("상세주소를 입력해주세요.", "", "warning");
      } else {
        const enDate = moment(estimateInfo.endate);
        const delDate = moment(estimateInfo.deldate);
        if (enDate.isAfter(delDate)) {
          swal("납품 기한이 투찰마감보다 빠를 수 없습니다.", "", "error");
          return;
        }

        const allData = {
          bid: estimateInfo,
          bidProductList: bidProductList,
          bidImgList: bidImgList || [],
          ...(previousBidNo && { previousBidNo: previousBidNo }),
          ...(previousBidNo && { extraType: extraType }),
        };

        bidNextFnc(allData, "일회성입찰공고");
      }
    }

    //단가계약입찰공고
    if (kind === "단가계약입찰공고") {
      if (
        estimateInfoPeriod.endate === "" ||
        estimateInfoPeriod.endate === "Invalid date" ||
        selectDatePeriod === false
      ) {
        swal("투찰기한을 선택해주세요.", "", "warning");
      } else if (
        new Date(selectDatePeriod).getHours() < 7 ||
        new Date(selectDatePeriod).getHours() > 18
      ) {
        swal("투찰기한은 07:00 ~ 18:00 선택 가능합니다.", "", "warning");
      } else if (
        estimateInfoPeriod.term === "" ||
        estimateInfoPeriod.term === "0"
      ) {
        swal("계약기간을 입력해주세요.", "", "warning");
      } else if (estimateInfoPeriod.addr2 === "") {
        swal("상세주소를 입력해주세요.", "", "warning");
      } else {
        const allData = {
          bid: estimateInfoPeriod,
          bidProductList: bidProductList,
          bidImgList: bidImgList || [],
          ...(previousBidNo && { previousBidNo: previousBidNo }),
          ...(previousBidNo && { extraType: extraType }),
        };

        bidNextFnc(allData, "단가계약입찰공고");
      }
    }

    //업체지정주문
    if (kind === "업체지정주문") {
      if (estimateInfoSelect.companyNo === "") {
        swal("업체를 선택해주세요", "", "warning");
      } else if (
        estimateInfoSelect.edate === "" ||
        estimateInfoSelect.edate === "Invalid date"
      ) {
        swal("납품 기한을 선택해주세요", "", "warning");
      } else if (estimateInfoSelect.recipient === "") {
        swal("수령인을 입력해주세요.", "", "warning");
      } else if (estimateInfoSelect.phone === "") {
        swal("수령인 연락처를 입력해주세요.", "", "warning");
      } else if (moment(estimateInfoSelect.edate).fromNow().includes("전")) {
        swal("납품 기한을 확인해주세요", "", "warning");
      } else if (estimateInfoSelect.detail === "") {
        swal("상세주소를 입력해주세요.", "", "warning");
      } else {
        const allData = {
          ...estimateInfoSelect,
          bidImgList: bidImgList || [],
          bidProductList: bidProductList,
        };

        // overloading
        bidNextFnc(allData, "업체지정주문");
      }
    }
  };

  const bidNextFnc = async (data, type) => {
    navigate("/management/bid/request_input_info/request_confirm", {
      state: {
        data: data,
        type: type,
        selectCompany: selectCompany.name,
        ...(previousBidNo && { previousBidNo: previousBidNo }),
        ...(previousBidNo && { extraType: extraType }),
      },
    });
  };

  const nextStepFnc = (type) => {
    userData.constructionType !== "SUBCONTRACTOR"
      ? onNextValidation(type)
      : swal(
          "건설사 전용 기능입니다.",
          "이 기능을 사용하기 위해서는 건설사 회원으로 전환이 필요합니다.",
          "warning"
        );
  };

  const prevStepFnc = () => {
    navigate(-1);
  };

  const setFirstDataFnc = async () => {
    const resp = await contractAddrApi(userData.site_num);

    if (resp.status === "success") {
      console.log(resp);
      setEstimateInfoSelect({
        ...estimateInfoSelect,
        addr: resp.data.addr1,
        detail: resp.data.addr2,
      });
      setEstimateInfo({
        ...estimateInfo,
        addr1: resp.data.addr1,
        addr2: resp.data.addr2,
      });
      setEstimateInfoPeriod({
        ...estimateInfoPeriod,
        addr1: resp.data.addr1,
        addr2: resp.data.addr2,
      });
    }
  };

  useEffect(() => {
    setFirstDataFnc();
  }, [userData.site_num]);

  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (userData.u_phone) {
      handlePhoneNumberChange(userData.u_phone);
    } else {
      getUserData();
    }
  }, [userData.u_phone]);

  // setphone & parameter value 담기
  const handlePhoneNumberChange = (value) => {
    let input = value ? value.replace(/\D/g, "") : "";

    if (input.length > 3 && input.length <= 6) {
      input = `${input.slice(0, 3)}-${input.slice(3)}`;
    } else if (input.length > 6 && input.length <= 10) {
      input = `${input.slice(0, 3)}-${input.slice(3, 6)}-${input.slice(6)}`;
    } else if (input.length > 10) {
      input = `${input.slice(0, 3)}-${input.slice(3, 7)}-${input.slice(7)}`;
    }

    setEstimateInfoSelect((prevState) => ({
      ...prevState,
      phone: value || "",
    }));
    setPhone(input);
  };

  // api를 통해 유저휴대폰 번호 받아옴.
  const getUserData = async () => {
    const response = await orderUserInfoApi(userData.site_num);
    if (response.status === "success") {
      // Create a new user data object, preserving existing values and updating u_name and u_phone
      let newUserData = {
        ...userData,
        u_name: response.data.name,
        u_phone: response.data.phone,
      }; // Dispatch the action to update the user data
      dispatch(actions.setUser(newUserData));
      handlePhoneNumberChange(response.data.phone);
    }
  };

  // const location = useLocation();
  const currentPath = location.pathname; // 현재 경로
  const keyword = "request_confirm"; // 확인할 단어

  // URL에 특정 단어가 포함되어 있는지 확인

  const isConfirmPage = currentPath.includes(keyword);

  return (
    <>
      {!isConfirmPage ? (
        <div className="request_input_container">
          <Mheader title="상세정보 입력" />
          <div className="input_top_box">
            <div className="title">상세정보 입력</div>

            <OrderBreadCrumb
              step1={extraType === "BID" ? null : "내역 작성"}
              step2="상세정보 입력"
              step3="상세정보 확인"
              step4={previousBidNo ? "재공고 완료" : "주문/입찰 완료"}
              isActive={2}
            />
          </div>
          <div className="top_request_sub_header">
            <div className="top_request_sub_header_wrap">
              <p>정확한 납품을 위하여 정확한 정보를 입력해주시기 바랍니다.</p>
            </div>
          </div>

          {choiceOrderMethod === "단가계약입찰공고" && (
            <div className="input_info_direction">
              <div className="input_info_container">
                <span className="input_info_title">주문/입찰 방식</span>
                <div className="input_info_contents input_info_desc">
                  단가 계약 입찰 공고
                </div>
              </div>
              <div className="input_info_container">
                <span className="input_info_title">투찰 기한</span>

                {/* 투찰기한은 최소 6시간, 납품일시는 최대  */}
                <div className="input_info_contents">
                  <DatePicker
                    style={{ transform: "scale(1.3)" }}
                    locale="ko"
                    selected={selectDatePeriod ? selectDatePeriod : null} //이렇게 안 해주면 x버튼이 계속 떠있음
                    // closeOnScroll={true} //when scroll close
                    minDate={
                      // 오전 12시(정오) 이후면 다음 날 부터 선택 가능
                      new Date().getHours() >= 12
                        ? new Date(now.getTime() + oneDayInMilliseconds)
                        : // 오늘 날짜라면 오늘이 최소 선택일자
                          new Date()
                    }
                    minTime={
                      //formatDate가 같다면 10분전까지만 select

                      // ❌지정단❌
                      formatDate(selectDatePeriod) === formatDate(new Date())
                        ? new Date().setMinutes(new Date().getMinutes() + 360)
                        : new Date().getHours() >= 12 &&
                          moment(selectDatePeriod).diff(moment(), "days") < 1
                        ? new Date().setHours(new Date().getHours() - 3, 0, 0)
                        : now.setHours(7, 0, 0)
                    }
                    maxTime={now.setHours(18, 0, 0)}
                    showTimeSelect // 시간 나오게 하기
                    timeFormat="HH:mm" //시간 포맷
                    timeIntervals={30} // 한시간 단위로 선택 가능한 box가 나옴
                    timeCaption="time"
                    dateFormat="yyyy/MM/dd HH:mm 까지"
                    isClearable={true}
                    peekNextMonth
                    withPortal
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={(date) => {
                      if (date && date?.getHours() === 0) {
                        const plus18hours = date.setHours(date.getHours() + 18);
                        setSelectDatePeriod(plus18hours);
                      }
                      setSelectDatePeriod(date);
                      setEstimateInfoPeriod({
                        ...estimateInfoPeriod,
                        endate: moment(date).format("yyyy-MM-DD HH:mm:ss"),
                      });
                    }}
                  >
                    <motion.span
                      className="select_time_alert"
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{
                        duration: 0.8,
                        delay: 0.2,
                        ease: [0, 0.71, 0.2, 1.01],
                      }}
                    >
                      {new Date(selectDatePeriod).getFullYear() === 1970 ? (
                        <span>
                          투찰 마감{" "}
                          <span style={{ fontWeight: 700 }}>날짜</span>를
                          선택해주세요.
                        </span>
                      ) : (
                        <span>
                          투찰 마감{" "}
                          <span style={{ fontWeight: 700 }}>시간</span>을
                          선택해주세요.
                        </span>
                      )}
                    </motion.span>
                  </DatePicker>
                </div>
              </div>

              {selectDatePeriod ? (
                <span className="input_bid_time_info">
                  {getDiffDate(selectDatePeriod)}
                </span>
              ) : (
                <div
                  className="input_budget_info"
                  style={{ marginTop: "5px", flexDirection: "column" }}
                >
                  <span className="request_short_info">
                    * 최소 working hour 6시간 이후부터 선택 가능합니다.
                  </span>
                </div>
              )}

              <div className="input_info_container">
                <span className="input_info_title">계약 기간</span>
                <div className="input_info_contents">
                  <input
                    type="text"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^\d]/g, "");
                      if (!/^\d{0,3}$/.test(e.target.value)) {
                        e.target.value = e.target.value.slice(0, -1);
                      }
                    }}
                    placeholder="계약기간을 입력해주세요."
                    className="write_the_budget"
                    onChange={(e) => {
                      setEstimateInfoPeriod({
                        ...estimateInfoPeriod,
                        term: e.target.value,
                      });
                    }}
                    value={estimateInfoPeriod.term}
                  />

                  <span className="ten_thousand">개월</span>
                </div>
              </div>

              <div className="input_info_container">
                <span className="input_info_title">납품 주소</span>
                <div className="my_addr_container input_info_contents">
                  <div className="input_info_btn_box">
                    <input
                      type="text"
                      value={estimateInfoPeriod?.addr1}
                      // id="my_address"
                      readOnly
                    />
                    <Button
                      size="small"
                      onClick={() => {
                        openPostModal();
                      }}
                    >
                      주소찾기
                    </Button>
                  </div>

                  <Modal
                    size="xsmall"
                    open={postOpen}
                    title={
                      <Typography component="p" type="h4">
                        납품 주소 입력
                      </Typography>
                    }
                    closeIcon={true}
                    onOk={closePostModal}
                    onCancel={closePostModal}
                  >
                    <DaumPostcodeEmbed
                      onComplete={(e) => onCompletePost(e, "단가계약입찰공고")}
                    />
                  </Modal>

                  <input
                    className="direct_input"
                    placeholder="상세주소를 입력해주세요."
                    onChange={(e) => {
                      setEstimateInfoPeriod({
                        ...estimateInfoPeriod,
                        addr2: e.target.value,
                      });
                    }}
                    defaultValue={estimateInfoPeriod?.addr2 || ""}
                  />
                </div>
              </div>

              <div className="input_info_container">
                <span className="input_info_title">배송비</span>
                <div className="input_info_contents input_info_desc">포함</div>
              </div>
              <div className="input_info_container">
                <span className="input_info_title">하차 역무</span>
                <div className="input_info_contents input_info_desc">
                  하차 역무 있음
                </div>
              </div>
              <div className="input_info_container">
                <span className="input_info_title">우선사항</span>
                <div className="input_info_contents input_info_desc">
                  최저단가
                </div>
              </div>
              <div className="input_info_container">
                <span
                  className="input_info_title"
                  style={{ position: "relative" }}
                >
                  비용 정산
                  <img
                    className="question_img"
                    src={question_img}
                    onMouseOver={() => {
                      setRefundInfoPopup(!refundInfoPopup);
                    }}
                    onMouseLeave={() => {
                      setRefundInfoPopup(false);
                    }}
                  ></img>
                  {refundInfoPopup ? (
                    <p className="question_box_refund">
                      "공새로 위임정산건"입니다. <br></br>
                      아래 번호로 문의 바랍니다.<br></br>
                      고객센터 (T.032-205-0479)
                    </p>
                  ) : null}
                </span>
                <div className="input_info_contents input_info_desc">
                  공새로 위임 정산
                </div>
              </div>

              <section
                className={classNames("bid_bottom_button_section", {
                  desktop_fixed: isDesktop && isFixed,
                  mobile_fixed: isMobile && isFixed,
                })}
              >
                <div className="space_between_btn_group">
                  <Button size="medium" type="secondary" onClick={prevStepFnc}>
                    <Typography component="p" type="c1">
                      이전으로
                    </Typography>
                  </Button>
                  <Button
                    size="medium"
                    type="primary"
                    onClick={() => nextStepFnc("단가계약입찰공고")}
                  >
                    <Typography component="p" type="c1" color="var(--white)">
                      입력 완료
                    </Typography>
                  </Button>
                </div>
              </section>
            </div>
          )}
          {choiceOrderMethod === "일회성입찰공고" && (
            <div className="input_info_direction">
              <div className="input_info_container">
                <span className="input_info_title">주문/입찰 방식</span>
                <div className="input_info_contents input_info_desc">
                  일회성 입찰 공고
                </div>
              </div>
              <div className="input_info_container">
                <span className="input_info_title">투찰 기한</span>
                <div className="input_info_contents">
                  <DatePicker
                    locale="ko"
                    selected={selectDateSingle ? selectDateSingle : null}
                    minDate={
                      // 오전 12시(정오) 이후면 다음 날 부터 선택 가능
                      new Date().getHours() >= 12
                        ? new Date(now.getTime() + oneDayInMilliseconds)
                        : // 오늘 날짜라면 오늘이 최소 선택일자
                          new Date()
                    }
                    minTime={
                      formatDate(selectDateSingle) === formatDate(new Date())
                        ? new Date().setMinutes(new Date().getMinutes() + 360)
                        : new Date().getHours() >= 12 &&
                          moment(selectDateSingle).diff(moment(), "days") < 1
                        ? new Date().setHours(new Date().getHours() - 3, 0, 0)
                        : // : new Date().setMinutes(new Date().getMinutes() + 360)
                          now.setHours(7, 0, 0)
                    }
                    maxTime={now.setHours(18, 0, 0)}
                    showTimeSelect // 시간 나오게 하기
                    timeFormat="HH:mm" //시간 포맷
                    timeIntervals={30} // 한시간 단위로 선택 가능한 box가 나옴
                    timeCaption="time"
                    dateFormat="yyyy/MM/dd HH:mm 까지"
                    isClearable={true}
                    peekNextMonth
                    withPortal
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={(date) => {
                      if (date && date?.getHours() === 0) {
                        const plus18hours = date.setHours(date.getHours() + 18);
                        setSelectDatePeriod(plus18hours);
                      }
                      setSelectDateSingle(date);
                      setEstimateInfo({
                        ...estimateInfo,
                        endate: moment(date).format("yyyy-MM-DD HH:mm:ss"),
                      });
                    }}
                    // 날짜만 선택하고 닫으면
                    // 시간이 오전 12시로 pick되는 issue 발생
                    // 마지막에 validation으로 formatdate이용해서 체크하는거 추천
                  >
                    <motion.span
                      className="select_time_alert"
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{
                        duration: 0.8,
                        delay: 0.2,
                        ease: [0, 0.71, 0.2, 1.01],
                      }}
                    >
                      {new Date(selectDateSingle).getFullYear() === 1970 ? (
                        <span>
                          투찰 마감{" "}
                          <span style={{ fontWeight: 700 }}>날짜</span>를
                          선택해주세요.
                        </span>
                      ) : (
                        <span>
                          투찰 마감{" "}
                          <span style={{ fontWeight: 700 }}>시간</span>을
                          선택해주세요.
                        </span>
                      )}
                    </motion.span>
                  </DatePicker>
                </div>
              </div>

              {selectDateSingle ? (
                <span className="input_bid_time_info">
                  {getDiffDate(selectDateSingle)}
                </span>
              ) : (
                <div
                  className="input_budget_info"
                  style={{ marginTop: "5px", flexDirection: "column" }}
                >
                  <span className="request_short_info">
                    * 최소 working hour 6시간 이후부터 선택 가능합니다.
                  </span>
                </div>
              )}
              {/* </div> */}
              <div className="input_info_container">
                <span className="input_info_title">납품 기한</span>
                <div className="input_info_contents">
                  <DatePicker
                    locale="ko"
                    selected={selectDateSingle2 ? selectDateSingle2 : null}
                    minDate={
                      // 투찰기한이 12시간이후라면 오늘 선택할 수 없게 만들기
                      new Date(selectDateSingle).getHours() >= 12 &&
                      selectDateSingle
                        ? new Date(
                            selectDateSingle.getTime() + oneDayInMilliseconds
                          )
                        : new Date(selectDateSingle)
                    }
                    minTime={
                      //투찰기한과 같다면 6시간 후까지만 select
                      // 11시 이후는 이미 date에서 filter함
                      formatDate(selectDateSingle2) ===
                      formatDate(selectDateSingle)
                        ? new Date(selectDateSingle).setHours(
                            new Date(selectDateSingle).getHours() + 6
                          )
                        : moment(selectDateSingle2).diff(
                            moment(selectDateSingle),
                            "days"
                          ) < 1
                        ? new Date(selectDateSingle2).setHours(
                            new Date(selectDateSingle).getHours() - 3,
                            0,
                            0
                          )
                        : now.setHours(7, 0, 0)
                    }
                    maxTime={now.setHours(18, 0, 0)}
                    showTimeSelect // 시간 나오게 하기
                    timeFormat="HH:mm" //시간 포맷
                    timeIntervals={30} // 한시간 단위로 선택 가능한 box가 나옴
                    timeCaption="time"
                    dateFormat="yyyy/MM/dd HH:mm 까지"
                    isClearable={true}
                    peekNextMonth
                    withPortal
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={(date) => {
                      if (date && date?.getHours() === 0) {
                        const plus18hours = date.setHours(date.getHours() + 18);
                        setSelectDatePeriod(plus18hours);
                      }
                      setSelectDateSingle2(date);
                      setEstimateInfo({
                        ...estimateInfo,
                        deldate: moment(date).format("yyyy-MM-DD HH:mm:ss"),
                      });
                    }}
                    // 날짜만 선택하고 닫으면
                    // 시간이 오전 12시로 pick되는 issue 발생
                    // 마지막에 validation으로 formatdate이용해서 체크하는거 추천
                  >
                    <motion.span
                      className="select_time_alert"
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{
                        duration: 0.8,
                        delay: 0.2,
                        ease: [0, 0.71, 0.2, 1.01],
                      }}
                    >
                      {new Date(selectDateSingle2).getFullYear() === 1970 ? (
                        <span>
                          원하는 납품{" "}
                          <span style={{ fontWeight: 700 }}>날짜</span>를
                          선택해주세요.
                        </span>
                      ) : (
                        <span>
                          원하는 납품{" "}
                          <span style={{ fontWeight: 700 }}>시간</span>을
                          선택해주세요.
                        </span>
                      )}
                    </motion.span>
                  </DatePicker>
                </div>
              </div>
              {selectDateSingle2 && (
                <span className="input_bid_time_info">
                  {getDiffDate2(selectDateSingle2)}
                </span>
              )}
              <div className="input_info_container">
                <span className="input_info_title">납품 주소</span>
                <div className="input_info_contents my_addr_container ">
                  <div className="input_info_btn_box">
                    <input type="text" value={estimateInfo?.addr1} />

                    <Button
                      size="small"
                      onClick={() => {
                        setPostOpen(true);
                      }}
                    >
                      주소찾기
                    </Button>
                  </div>
                  <Modal
                    size="xsmall"
                    open={postOpen}
                    title={
                      <Typography component="p" type="h4">
                        납품 주소 입력
                      </Typography>
                    }
                    closeIcon={true}
                    onOk={closePostModal}
                    onCancel={closePostModal}
                  >
                    <DaumPostcodeEmbed
                      onComplete={(e) => onCompletePost(e, "일회성입찰공고")}
                    />
                  </Modal>
                  <input
                    className="direct_input"
                    placeholder="상세주소를 입력해주세요."
                    onChange={(e) => {
                      setEstimateInfo({
                        ...estimateInfo,
                        addr2: e.target.value,
                      });
                    }}
                    defaultValue={estimateInfo?.addr2}
                  />
                </div>
              </div>
              <div className="input_info_container">
                <span className="input_info_title">배송비</span>
                <div className="input_info_contents input_info_desc">포함</div>
              </div>
              <div className="input_info_container">
                <span className="input_info_title">하차 역무</span>
                <div className="input_info_contents input_info_desc">
                  하차 역무 있음
                </div>
              </div>
              <div className="input_info_container">
                <span className="input_info_title">우선사항</span>
                <div className="input_info_contents input_info_desc">
                  최저단가
                </div>
              </div>
              <div className="input_info_container">
                <span
                  className="input_info_title"
                  style={{ position: "relative" }}
                >
                  비용 정산
                  <img
                    className="question_img"
                    src={question_img}
                    onMouseOver={() => {
                      setRefundInfoPopup(!refundInfoPopup);
                    }}
                    onMouseLeave={() => {
                      setRefundInfoPopup(false);
                    }}
                  ></img>
                  {refundInfoPopup ? (
                    <p className="question_box_refund">
                      "공새로 위임정산건"입니다. <br></br>
                      아래 번호로 문의 바랍니다.<br></br>
                      고객센터 (T.032-205-0479)
                    </p>
                  ) : null}
                </span>
                <div className="input_info_contents input_info_desc">
                  공새로 위임 정산
                </div>
              </div>
              <section
                className={classNames("bid_bottom_button_section", {
                  desktop_fixed: isDesktop && isFixed,
                  mobile_fixed: isMobile && isFixed,
                })}
              >
                <div className="space_between_btn_group">
                  <Button size="medium" type="secondary" onClick={prevStepFnc}>
                    <Typography component="p" type="c1" color="var(--navy-08)">
                      이전으로
                    </Typography>
                  </Button>
                  <Button
                    size="medium"
                    type="primary"
                    onClick={() => nextStepFnc("일회성입찰공고")}
                  >
                    <Typography component="p" type="c1" color="var(--white)">
                      입력 완료
                    </Typography>
                  </Button>
                </div>
              </section>
            </div>
          )}
          {choiceOrderMethod === "업체지정주문" && (
            <div className="input_info_direction">
              <div className="input_info_container">
                <span className="input_info_title">주문/입찰 방식</span>

                <div className="input_info_contents input_info_desc">
                  업체 지정 주문
                </div>
              </div>
              <div className="input_info_container">
                <span className="input_info_title">지정 업체</span>
                <div className="input_info_contents input_info_desc">
                  <div className="input_info_btn_box">
                    {/* <span>{}</span> */}
                    <input
                      value={selectCompany.name || "업체를 선택해주세요."}
                      readOnly
                    />
                    <Button
                      size="small"
                      onClick={() => setOrderCompanyPop(true)}
                    >
                      업체선택
                    </Button>
                  </div>
                </div>
              </div>
              <div className="input_info_container">
                <span className="input_info_title">수령인</span>
                <div className="input_info_contents">
                  <input
                    defaultValue={userData.u_name}
                    onChange={(e) => {
                      setEstimateInfoSelect({
                        ...estimateInfoSelect,
                        recipient: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="input_info_container">
                <span className="input_info_title">수령인 연락처</span>
                <div className="input_info_contents">
                  <input
                    defaultValue={phone}
                    value={phone}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^\d]/g, "");
                      if (!/^\d{0,11}$/.test(e.target.value)) {
                        e.target.value = e.target.value.slice(0, -1);
                      }
                    }}
                    onChange={(e) => handlePhoneNumberChange(e.target.value)}
                  />
                </div>
              </div>
              <div className="input_info_container">
                <span className="input_info_title">납품 기한</span>
                <div className="input_info_contents">
                  <DatePicker
                    locale="ko"
                    selected={
                      selectDateCompanySelect ? selectDateCompanySelect : null
                    }
                    minDate={
                      // 오전 12시(정오) 이후면 다음 날 부터 선택 가능
                      new Date().getHours() >= 12
                        ? new Date(now.getTime() + oneDayInMilliseconds)
                        : // 오늘 날짜라면 오늘이 최소 선택일자
                          new Date()
                    }
                    minTime={
                      formatDate(selectDatePeriod) === formatDate(new Date())
                        ? new Date().setMinutes(new Date().getMinutes() + 360)
                        : new Date().getHours() >= 12 &&
                          moment(selectDatePeriod).diff(moment(), "days") < 1
                        ? new Date().setHours(new Date().getHours() - 3, 0, 0)
                        : now.setHours(7, 0, 0)
                    }
                    maxTime={now.setHours(18, 0, 0)}
                    showTimeSelect // 시간 나오게 하기
                    timeFormat="HH:mm" //시간 포맷
                    timeIntervals={30} // 한시간 단위로 선택 가능한 box가 나옴
                    timeCaption="time"
                    dateFormat="yyyy/MM/dd HH:mm 까지"
                    isClearable={true}
                    peekNextMonth
                    withPortal
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={(date) => {
                      if (date && date?.getHours() === 0) {
                        const plus18hours = date.setHours(date.getHours() + 18);
                        setSelectDatePeriod(plus18hours);
                      }
                      setSelectDateCompanySelect(date);
                      setEstimateInfoSelect({
                        ...estimateInfoSelect,
                        edate: moment(date).format("yyyy-MM-DD HH:mm:ss"),
                      });
                    }}
                    // 날짜만 선택하고 닫으면
                    // 시간이 오전 12시로 pick되는 issue 발생
                    // 마지막에 validation으로 formatdate이용해서 체크하는거 추천
                  >
                    <motion.span
                      className="select_time_alert"
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{
                        duration: 0.8,
                        delay: 0.2,
                        ease: [0, 0.71, 0.2, 1.01],
                      }}
                    >
                      {new Date(selectDateSingle2).getFullYear() === 1970 ? (
                        <span>
                          원하는 납품{" "}
                          <span style={{ fontWeight: 700 }}>날짜</span>를
                          선택해주세요.
                        </span>
                      ) : (
                        <span>
                          원하는 납품{" "}
                          <span style={{ fontWeight: 700 }}>시간</span>을
                          선택해주세요.
                        </span>
                      )}
                    </motion.span>
                  </DatePicker>
                </div>
              </div>
              {selectDateCompanySelect ? (
                <span className="input_bid_time_info">
                  {getDiffDate(selectDateCompanySelect)}
                </span>
              ) : (
                <div
                  className="input_budget_info"
                  style={{ marginTop: "5px", flexDirection: "column" }}
                >
                  <span className="request_short_info">
                    * 최소 working hour 6시간 이후부터 선택 가능합니다.
                  </span>
                </div>
              )}

              <div className="input_info_container">
                <span className="input_info_title">납품 주소</span>
                <div className="input_info_contents my_addr_container ">
                  <div className="input_info_btn_box">
                    <input type="text" value={estimateInfoSelect.addr} />
                    <Button
                      size="small"
                      onClick={() => {
                        setPostOpen(true);
                      }}
                    >
                      주소찾기
                    </Button>
                  </div>

                  <Modal
                    size="xsmall"
                    open={postOpen}
                    title={
                      <Typography component="p" type="h4">
                        납품 주소 입력
                      </Typography>
                    }
                    closeIcon={true}
                    onOk={closePostModal}
                    onCancel={closePostModal}
                  >
                    <DaumPostcodeEmbed
                      onComplete={(e) => onCompletePost(e, "업체지정주문")}
                    />
                  </Modal>
                  <input
                    className="direct_input"
                    placeholder="상세주소를 입력해주세요."
                    onChange={(e) => {
                      setEstimateInfoSelect({
                        ...estimateInfoSelect,
                        detail: e.target.value,
                      });
                    }}
                    defaultValue={estimateInfoSelect.detail}
                  />
                </div>
              </div>
              <div className="input_info_container">
                <span className="input_info_title">배송비</span>
                <div className="input_info_contents input_info_desc">포함</div>
              </div>
              <div className="input_info_container">
                <span className="input_info_title">하차 역무</span>
                <div className="input_info_contents input_info_desc">
                  하차 역무 있음
                </div>
              </div>
              <div className="input_info_container">
                <span
                  className="input_info_title"
                  style={{ position: "relative" }}
                >
                  비용 정산
                  <img
                    className="question_img"
                    src={question_img}
                    onMouseOver={() => {
                      setRefundInfoPopup(!refundInfoPopup);
                    }}
                    onMouseLeave={() => {
                      setRefundInfoPopup(false);
                    }}
                  ></img>
                  {refundInfoPopup ? (
                    <p className="question_box_refund">
                      "공새로 위임정산건"입니다. <br></br>
                      아래 번호로 문의 바랍니다.<br></br>
                      고객센터 (T.032-205-0479)
                    </p>
                  ) : null}
                </span>
                <div className="input_info_contents input_info_desc">
                  공새로 위임 정산
                </div>
              </div>

              <section
                className={classNames("bid_bottom_button_section", {
                  desktop_fixed: isDesktop && isFixed,
                  mobile_fixed: isMobile && isFixed,
                })}
              >
                <div className="space_between_btn_group">
                  <Button size="medium" type="secondary" onClick={prevStepFnc}>
                    <Typography component="p" type="c1">
                      이전으로
                    </Typography>
                  </Button>
                  <Button
                    size="medium"
                    type="primary"
                    onClick={() => nextStepFnc("업체지정주문")}
                  >
                    <Typography component="p" type="c1" color="var(--white)">
                      입력 완료
                    </Typography>
                  </Button>
                </div>
              </section>
            </div>
          )}
          {orderCompanyPop && (
            <OrderCompanySelectPop
              setOrderCompanyPop={setOrderCompanyPop}
              setSelectCompany={setSelectCompany}
              setChoiceOrderMethod={setChoiceOrderMethod}
            />
          )}
        </div>
      ) : (
        // confirm page 일 때
        <Outlet />
      )}
    </>
  );
}
