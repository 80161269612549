import { Divider } from "antd";
import Typography from "../../../../../../../component/ui/typography/Typography";
import SiteAttendButton from "../../button/SiteAttendButton";
import NewSiteRegButton from "../../button/NewSiteRegButton";
import SiteSwitchButton from "../../button/SiteSwitchButton";
import SiteInfoChangeRequestButton from "../../button/SiteInfoChangeRequestButton";
import SiteLeaveButton from "../../button/SiteLeaveButton";
import Chip from "../../../../../../../component/ui/chip/Chip";

import formatPhoneNumber from "../../../../../../../util/formatPhoneNumber";

import { COLOR } from "../../../../../../../constant/Palette";
import s from "./siteInfo.module.css";

const SiteInfo = ({
  site,
  isPending,
  setSiteLeaveModal,
  setSiteAttendModal,
  setSiteSwitchModal,
  isDemo,
}) => {
  return (
    <div className={s.container}>
      <div className={s.title}>
        <Typography component="p" type="h4" color={COLOR.GRAY.L09}>
          현장 정보
        </Typography>
        {!isDemo && (
          <div className={s.button_group}>
            <SiteLeaveButton setSiteLeaveModal={setSiteLeaveModal} />
            <SiteAttendButton setSiteAttendModal={setSiteAttendModal} />
            <NewSiteRegButton />
            <SiteSwitchButton setSiteSwitchModal={setSiteSwitchModal} />
          </div>
        )}
      </div>

      <Divider className={s.divider} />

      <div className={s.help_text}>
        <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
          *현장이 다중으로 있을 시 대표현장을 선택하여야 해당 현장정보로 입찰 /
          계약 / 주문 등의 활동이 가능합니다.
        </Typography>
        <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
          *공새로에 참여 중이지 않은 현장 관계자가 있으시다면 현장 초대를 통해
          초대하세요.
        </Typography>
      </div>

      <div className={s.info_box}>
        <div className={s.info_title}>
          <div className={s.info_title_chip_box}>
            <Typography component="p" type="h5" color={COLOR.GRAY.L10}>
              {site?.name}
            </Typography>
            {isPending && (
              <Chip type="default" size="medium" color="yellow">
                승인대기중
              </Chip>
            )}
          </div>

          {!isDemo && <SiteInfoChangeRequestButton />}
        </div>

        <Divider className={s.divider} />

        <div className={s.info_container}>
          <div>
            <div>
              <Typography component="p" type="c1" color={COLOR.GRAY.L07}>
                분류
              </Typography>
              <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
                {site?.type || "-"}
              </Typography>
            </div>
            <div>
              <Typography component="p" type="c1" color={COLOR.GRAY.L07}>
                주소
              </Typography>
              <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
                {site?.addr1}, {site?.addr2}
              </Typography>
            </div>
          </div>

          <div>
            <div>
              <Typography component="p" type="c1" color={COLOR.GRAY.L07}>
                대표번호
              </Typography>
              <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
                {formatPhoneNumber(site?.phone) || "-"}
              </Typography>
            </div>
            <div>
              <Typography component="p" type="c1" color={COLOR.GRAY.L07}>
                현장규모
              </Typography>
              <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
                {site?.scale || "-"}
              </Typography>
            </div>
          </div>

          <div>
            <div>
              <Typography component="p" type="c1" color={COLOR.GRAY.L07}>
                공사 시작일
              </Typography>
              <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
                {site?.sdate || "-"}
              </Typography>
            </div>
            <div>
              <Typography component="p" type="c1" color={COLOR.GRAY.L07}>
                준공 예정일
              </Typography>
              <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
                {site?.edate || "-"}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteInfo;
