import React, { useCallback, useEffect, useState } from "react";
import Mheader from "../../component/common/Mheader";
import { useLocation, useNavigate } from "react-router-dom";
import s from "../../asset/css/esgDashboard.module.css";

import EsgDashboardTabs from "../../component/common/EsgDashboardTabs";
import Typography from "../../component/ui/typography/Typography";
// import { Pie, G2, measureTextWidth, Column } from "@ant-design/plots";

import leaf from "../../asset/images/icon_leaf.png";
import pie from "../../asset/images/icon_chart_pie.png";
import polar from "../../asset/images/icon_chart_polar.png";
import { Pie, Column, DualAxes } from "@ant-design/plots";
import { each, groupBy } from "@antv/util";
import { fetchDashboardInfoApi, esgDashboardApi } from "../../api/auth";
import Chip from "../../component/ui/chip/Chip";
import Select from "../../component/ui/select/Select";
import { Empty } from "antd";
import { useMediaQuery } from "react-responsive";

export default function MainRecommend() {
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });

  const navigate = useNavigate();

  const navigateGreenHouse = () => {
    navigate("/esg_management/greenHouse", {
      state: {
        selectYear: year,
      },
    });
  };
  const navigateWaste = () => {
    navigate("/esg_management/waste", {
      state: {
        selectYear: year,
      },
    });
  };

  const [dashboard, setDashboard] = useState({
    greenhouseGasBarList: [],
    greenhouseGasList: [],
    invoiceYearInfoList: [],
    scopeTotalList: [],
    scopeDetailList: [],
    scopeDetailPieList: [],
    wasteInfoList: [],
    wastePieList: [],
  });
  const [year, setYear] = useState(2024);
  const [scope, setScope] = useState("Scope1");

  const options = [
    { value: "2024", label: "2024년" },
    { value: "2023", label: "2023년" },
    { value: "2022", label: "2022년" },
    { value: "2021", label: "2021년" },
  ];
  const scopeOptions = [
    { value: "Scope1", label: "Scope1" },
    { value: "Scope2", label: "Scope2" },
    { value: "Scope3", label: "Scope3" },
  ];

  const handleYearSelect = (e) => setYear(e);
  const handleScopeSelect = (e) => setScope(e);

  const fetchEsgDashboardInfo = useCallback(async (year, scope) => {
    try {
      const response = await esgDashboardApi(year, scope);
      setDashboard(response.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchEsgDashboardInfo(year, scope);
  }, [year, scope]);

  // 온실가스 배출량 - 막대 완
  const DemoColumn = () => {
    const config = {
      data: dashboard?.greenhouseGasBarList,
      xField: "month",
      yField: "value",
      isStack: true,
      percent: true,
      seriesField: "scope",
      colorField: "scope",
      tooltip: {
        formatter: (datum) => ({
          name: datum.scope,
          value: `${datum.value.toFixed(2)}`,
        }),
      },
    };
    return <Column {...config} />;
  };

  // Scope 별 온실가스 배출량 - 막대 그래프
  // scope마다 변경되게
  const DemoDualAxes = () => {
    const config = {
      data: [dashboard?.scopeTotalList, dashboard?.scopeDetailList], // 각각의 Y축에 해당하는 데이터를 배열로 전달합니다.
      xField: "month", // 가로 축
      yField: ["value", "value"], // 두 Y축 필드를 지정합니다.
      geometryOptions: [
        {
          geometry: "column",
          color: "#dddddd",
          columnWidthRatio: 0.4,
        },
        {
          geometry: "line",
          seriesField: "detail",
          smooth: true,
        },
      ],
      meta: {
        value: {
          alias: scope, // yField 값의 레이블을 "Scope"로 변경
        },
      },
    };

    return <DualAxes {...config} />;
  };

  // scope별 온실가스 배출량 - 파이 그래프
  const DemoPie = () => {
    const config = {
      data: dashboard?.scopeDetailPieList,
      angleField: "value",
      colorField: "detail",
      label: {
        type: "inner",
        offset: "-10%",
        content: ({ value }) => `${value.toFixed(2)}%`,
      },
      tooltip: {
        formatter: (datum) => ({
          name: datum.detail,
          value: `${datum.value.toFixed(2)}%`,
        }),
      },

      legend: {
        color: {
          title: false,
          position: "right",
          rowPadding: 5,
        },
      },
    };
    return <Pie {...config} />;
  };

  const DemoVariableColumn = () => {
    // 데이터가 존재하는지 확인 후 처리

    const config = {
      data: dashboard?.wasteInfoList,
      xField: "month",
      yField: "value",
      seriesField: "label",
      isGroup: true,
      legend: {
        color: {
          position: "top",
          layout: {
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          },
        },
      },
      axis: {
        y: { labelFormatter: (datum) => `${datum.toLocaleString("ko")}건` },
      },
      tooltip: {
        field: "value",
        valueFormatter: (datum) => `${datum.toLocaleString("ko")}건`,
      },
    };
    return <Column {...config} />;
  };

  // 폐기물 배출량 - 파이 그래프
  const DemoPie2 = () => {
    const config = {
      data: dashboard?.wastePieList,
      angleField: "value",
      colorField: "label",
      // 차트 안에 적혀있는 값
      label: {
        content: (datum) => `${Number(datum.value).toFixed(2)}%`,
        type: "inner",
        offset: "-10%",
        style: {
          fontWeight: "bold",
        },
      },
      // hover 했을 때,
      tooltip: {
        customContent: (title, items) => {
          return (
            <>
              <ul style={{ paddingLeft: 0 }}>
                {items?.map((item, index) => {
                  const { name, value, color, data } = item;
                  return (
                    <li
                      key={item.year}
                      className="g2-tooltip-list-item"
                      data-index={index}
                      style={{
                        marginBottom: 4,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        className="g2-tooltip-marker"
                        style={{ backgroundColor: color }}
                      ></span>
                      <span
                        style={{
                          display: "inline-flex",
                          flex: 1,
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ marginRight: 16 }}>{name}:</span>
                        <span className="g2-tooltip-list-item-value">
                          {Number(value)}%
                        </span>
                      </span>
                    </li>
                  );
                })}
              </ul>
            </>
          );
        },
      },
      legend: {
        color: {
          title: true,
          position: "right",
          rowPadding: 5,
        },
      },
    };
    return <Pie {...config} />;
  };

  // 1. 그래프 깂 다 매핑해서 다 띄우기
  // 2. 그래프 오류 해결하기
  // 3. 그래프 편의성 개선하기

  const location = useLocation();
  const isEsgTab = location.pathname.includes("esg_management");

  return (
    <div className="site_wrap">
      <div className="site_container">
        <Mheader title="ESG 대시보드" />
        <div className={s.tab_container}>
          {/* esg 탭에서 들어왔다면, 위의 탭을 아니라면 아래 탭을 보여줌 */}
          {isEsgTab ? (
            <EsgDashboardTabs activeMenu="dashboard" page="esg_management" />
          ) : (
            <EsgDashboardTabs activeMenu="esg" page="esg_dashboard" />
          )}

          {!isEsgTab && (
            <Select
              size="large"
              width={isMobile ? "100%" : 150}
              defaultValue={"2024"}
              options={options}
              onChange={handleYearSelect}
            />
          )}
        </div>
        {isEsgTab && (
          <div className={s.filter_container}>
            <div className={s.date_box}>
              <Typography component="p" type="h5">
                연도
              </Typography>
              <Select
                size="large"
                width={isMobile ? "100%" : 200}
                defaultValue={"2024"}
                options={options}
                onChange={handleYearSelect}
              />
            </div>
          </div>
        )}
        <div className={s.main_container}>
          <div className={s.card_container}>
            <div className={s.card_inner}>
              <div className={s.card_title}>
                <Typography component="p" type="c1">
                  {year}년 총 배출 (tCO2-eq)
                </Typography>
                <Typography component="p" type="h1">
                  {dashboard.totalValue}
                </Typography>
              </div>
              <div className={s.card_img}>
                <img src={leaf} />
              </div>
            </div>
            <div className={s.card_inner}>
              <div className={s.card_title}>
                <Typography component="p" type="c1">
                  {year}년 배출 목표 (tCO2-eq)
                </Typography>
                <Typography component="p" type="h1">
                  {dashboard.targetValue}
                </Typography>
              </div>
              <div className={s.card_img}>
                <img src={polar} />
              </div>
            </div>
            <div className={s.card_inner}>
              <div className={s.card_title}>
                <Typography component="p" type="c1">
                  목표 대비 배출률 (%)
                </Typography>
                <Typography component="p" type="h1">
                  {isFinite(
                    (dashboard.totalValue / dashboard.targetValue) * 100
                  )
                    ? (
                        (dashboard.totalValue / dashboard.targetValue) *
                        100
                      ).toFixed(2)
                    : "100"}
                </Typography>
              </div>
              <div className={s.card_img}>
                <img src={pie} />
              </div>
            </div>
          </div>

          <div className={s.chart_container}>
            <div className={s.chart_inner}>
              <div className={s.chart_title} onClick={navigateGreenHouse}>
                <Typography component="p" type="h4">
                  온실가스 배출량 [{year}]
                </Typography>
              </div>
              {dashboard?.greenhouseGasBarList?.length >= 1 && (
                <div className={s.chart}>
                  <span className={s.vertical_text}>tCO2-eq</span>
                  <DemoColumn />
                </div>
              )}
              {dashboard?.greenhouseGasBarList?.length === 0 && (
                <div className={s.dashboard_empty_container}>
                  <Empty description={<span>내역이 존재하지 않습니다.</span>} />
                </div>
              )}
            </div>
            <div className={s.index_inner}>
              <div className={s.chart_title} onClick={navigateGreenHouse}>
                <Typography component="p" type="h4">
                  주요 지표
                </Typography>
              </div>
              <div className={s.table_box}>
                {dashboard?.greenhouseGasList.length >= 1 && (
                  <table className={s.index_table}>
                    <tr>
                      <th className={s.sort_th}>
                        <Typography component="span" type="c1">
                          구분
                        </Typography>
                      </th>
                      <th className={s.category_th}>
                        {" "}
                        <Typography component="span" type="c1">
                          항목
                        </Typography>
                      </th>
                      <th className={s.category_detail_th}>
                        <Typography component="span" type="c1">
                          항목 세부
                        </Typography>
                      </th>
                      <th className={s.input_unit_th}>
                        <Typography component="span" type="c1">
                          입력 단위
                        </Typography>
                      </th>
                      <th className={s.carbon_th}>
                        <Typography component="span" type="c1">
                          탄소 배출 계수
                          <br />
                          (tCO2-eq/단위)
                        </Typography>
                      </th>
                    </tr>
                    {dashboard?.greenhouseGasList?.map((elem, idx) => {
                      return (
                        <tr>
                          <td>
                            <Chip
                              size="medium"
                              type="border"
                              color={
                                elem.scope === "Scope1"
                                  ? "green"
                                  : elem.scope === "Scope2"
                                  ? "yellow"
                                  : "secondary"
                              }
                            >
                              {elem.scope}
                            </Chip>
                          </td>
                          <td>
                            <Typography component="span" type="c2">
                              {elem.label}
                            </Typography>
                          </td>
                          <td>
                            <Typography component="span" type="c2">
                              {elem.detail}
                            </Typography>
                          </td>
                          <td>
                            <Typography component="span" type="c2">
                              {elem.unit}
                            </Typography>
                          </td>
                          <td>
                            <Typography component="span" type="c2">
                              {elem.unit}
                            </Typography>
                          </td>
                        </tr>
                      );
                    })}

                    {/* <Chip size="medium" type="border" color="yellow">
                      Scope 2
                    </Chip>

                    <Chip size="medium" type="border" color="secondary">
                      Scope 3
                    </Chip> */}
                  </table>
                )}
                {dashboard?.greenhouseGasList?.length === 0 && (
                  <div className={s.dashboard_empty_container}>
                    <Empty
                      description={<span>내역이 존재하지 않습니다.</span>}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={s.chart_container}>
            <div className={s.full_chart_inner}>
              <div className={s.scope_tab_container}>
                <div className={s.chart_title} onClick={navigateGreenHouse}>
                  <Typography component="p" type="h4">
                    Scope 별 온실가스 배출량 [{year}]
                  </Typography>
                </div>
                <Select
                  size="medium"
                  width={isMobile ? "100%" : 150}
                  defaultValue={"Scope1"}
                  options={scopeOptions}
                  onChange={handleScopeSelect}
                />
              </div>
              <div className={s.double_container}>
                <div className={s.column_chart_box}>
                  {dashboard?.scopeTotalList?.length >= 1 && (
                    <div className={s.left_chart}>
                      <span className={s.vertical_text}>tCO2-eq</span>
                      <DemoDualAxes />
                    </div>
                  )}

                  {/* } */}
                  {dashboard?.scopeTotalList?.length === 0 && (
                    <div className={s.dashboard_empty_container}>
                      <Empty
                        description={<span>내역이 존재하지 않습니다.</span>}
                      />
                    </div>
                  )}
                </div>

                <div className={s.pie_chart_box}>
                  {dashboard?.scopeDetailList?.length >= 1 && (
                    <>
                      <div className={s.pie_chart_title}>
                        <Typography component="p" type="h5">
                          전체 배출 비율
                        </Typography>
                      </div>
                      <DemoPie />
                    </>
                  )}
                  {dashboard?.scopeDetailList?.length === 0 && (
                    <div className={s.dashboard_empty_container}>
                      <Empty
                        description={<span>내역이 존재하지 않습니다.</span>}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={s.chart_container}>
            <div className={s.full_chart_inner}>
              <div className={s.chart_title} onClick={navigateWaste}>
                <Typography component="p" type="h4">
                  폐기물 배출량 [{year}]
                </Typography>
              </div>
              <div className={s.double_container}>
                <div className={s.column_chart_box}>
                  {/* {dashboard?.wasteInfoList?.length >= 1 && (
                    <DemoVariableColumn />
                  )} */}

                  {dashboard?.wasteInfoList?.length >= 1 && (
                    <div className={s.left_chart}>
                      <span className={s.vertical_text}>폐기물 양</span>
                      <DemoVariableColumn />
                    </div>
                  )}

                  {dashboard?.wasteInfoList?.length === 0 && (
                    <div className={s.dashboard_empty_container}>
                      <Empty
                        description={<span>내역이 존재하지 않습니다.</span>}
                      />
                    </div>
                  )}
                </div>

                <div className={s.pie_chart_box}>
                  {dashboard?.wastePieList?.length >= 1 && (
                    <>
                      <div className={s.pie_chart_title}>
                        <Typography component="p" type="h5">
                          전체 배출 비율
                        </Typography>
                      </div>
                      <DemoPie2 />
                    </>
                  )}
                  {dashboard?.wastePieList?.length === 0 && (
                    <div className={s.dashboard_empty_container}>
                      <Empty
                        description={<span>내역이 존재하지 않습니다.</span>}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
