import Chip from "../../../../../component/ui/chip/Chip";

const typeConfig = {
  ALIVE: { color: "green", label: "입찰진행중" },
  CHOOSE: { color: "yellow", label: "투찰마감" },
  END: { color: "blue", label: "선정완료" },
  CANCEL: { color: "gray", label: "입찰취소" },
  FAIL: { color: "red", label: "유찰" },
};

// estimate type chip
export default function StateChip({ type }) {
  const chipConfig = typeConfig[type];

  return chipConfig ? (
    <Chip size="medium" type="default" color={chipConfig.color}>
      {chipConfig.label}
    </Chip>
  ) : null;
}
