import Modal from "../../../../../../component/ui/modal/Modal";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";
import Button from "../../../../../../component/ui/button/Button";

const SiteLeaveConfirmModal = ({
  siteLeaveConfirmModal,
  setSiteLeaveConfirmModal,
  setSiteLeaveModal,
  selectedSite,
  handleAttendSite,
}) => {
  const onClickCancel = () => {
    setSiteLeaveConfirmModal(false);
  };

  const onClickOk = () => {
    handleAttendSite();
    setSiteLeaveModal(false);
    setSiteLeaveConfirmModal(false);
  };

  return (
    <Modal
      size="xsmall"
      type="error"
      open={siteLeaveConfirmModal}
      title={
        <Typography component="p" type="h4" color={COLOR.SUB.RED.L10}>
          정말로 현장을 나가시겠습니까?
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={[
        <Button type="danger" size="small" onClick={onClickCancel}>
          취소
        </Button>,
        <Button type="primary" size="small" onClick={onClickOk}>
          나가기
        </Button>,
      ]}
    >
      <Typography component="span" type="c2">
        나가기를 누르면{" "}
      </Typography>
      <Typography component="span" type="h5">
        {selectedSite?.name}
      </Typography>
      <Typography component="span" type="c2">
        에서 제외됩니다.
      </Typography>
      <Typography component="p" type="c2">
        기존 현장 참여 및 현장 초대를 통해 다시 들어올 수 있습니다.
      </Typography>
    </Modal>
  );
};

export default SiteLeaveConfirmModal;
